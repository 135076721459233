import React from 'react';
import AppRoutes from './routes';
import { Provider } from "react-redux";
import { store } from "./store";
import 'antd/dist/antd.css';
import '../src/styles/_main.scss';
import "./App.css"
import 'react-phone-number-input/style.css'

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <AppRoutes />
      </div>
    </Provider>
  );
}

export default App;
