import React, { FC } from 'react'
import { Profile } from '../../../models/profile.model';
import TableComponent from '../../../shared/components/TableComponent'

interface UsersListProps {
    loading: boolean
    data: Profile[]
    editModeHandler: (profile: Profile) => void
    deleteModeHandler: (profile: Profile) => void
}

const UsersList: FC<UsersListProps> = (props) => {

    const {
        data,
        loading,
        editModeHandler,
        deleteModeHandler,
    } = props


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: "firstName",
            width: 120,
            ellipsis: true,
            sorter: true,
            render: (text: string, record: Profile) => (
                <span>{`${record.firstName || ''} ${record.lastName || ""}`}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: "email",
            width: 130,
            ellipsis: true,
        },
        {
            title: 'Phone Number',
            dataIndex: "mobile",
            key: "mobile",
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text: boolean) => (
                <span className={`status ${text ? 'active' : 'inactive'}`}>{text ? "Active" : "Inactive"}</span>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            width: 50,
            render: (text: string, record: Profile) => (
                <div className="table-actions">
                    <i className="icon-edit" onClick={(e) => {
                        e.stopPropagation();
                        editModeHandler(record)
                    }} />
                    <i className="icon-delete" onClick={(e) => {
                        e.stopPropagation()
                        deleteModeHandler(record)
                    }} />
                </div>
            )
        },
    ]

    return (
        <div className="user-management-list__container">
            <TableComponent
                loading={loading}
                columns={columns}
                data={data}
            // onChange={handleTableChange}
            // pagination={{
            //     pageSize: 20,
            //     current: repairListMeta.current || 1,
            //     total: repairListMeta.totalCount || 0,
            //     showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
            // }}
            />
        </div>
    )
}

export default UsersList
