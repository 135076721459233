import * as React from "react";
import { useRef } from "react";
import "./attachmentComponent.scss";
import MetaService from "../../../services/MetaService/meta.service";
import { AttachmentModel } from "../../../models/meta.model";
import { Skeleton } from "antd";
import AddIcon from "./../../../assets/Multicolor icons - SVG/add-photo.svg"

interface AttachmentComponentProps {
  images?: AttachmentModel[];
  title?: string;
  onUpload?: (value: AttachmentModel[] | undefined) => void;
  onRemove?: (image: AttachmentModel) => void;
  accept: string;
  disableClose?: boolean;
  multiple?: boolean;
  className?: string
  onClick?: () => void
}
export const AttachmentComponent = ({
  images = [],
  onUpload,
  onRemove,
  title,
  multiple,
  accept,
  disableClose,
  className = "",
  onClick = () => { }
}: AttachmentComponentProps) => {

  const attachmentRef = useRef<HTMLInputElement>(null);

  const {
    uploading,
    uploadAttachment,
  } = MetaService()

  console.log(images);


  const handleUpload = async () => {
    const response: AttachmentModel[] = []
    const files = Object.values((attachmentRef?.current?.files || {}))
    for (let i = 0; i < files.length; i++) {
      const fileResponse = await uploadAttachment(files[i])
      if (fileResponse) {
        fileResponse.name = files[i].name
        response.push(fileResponse)
      }
    }
    onUpload && onUpload(response);
  };

  const removeAttachment = (image: AttachmentModel) => {
    onUpload && onUpload(undefined);
    onRemove && onRemove(image);
  };

  const inputClickHandler = () => {
    if (attachmentRef.current)
      attachmentRef.current.click()
    onClick()
  }

  const imagesList = !accept?.includes("image")
    ? images.map((image, i) => <div className="attachment__pdf--container selected" key={image.id}>
      {!disableClose && (
        <span
          className="attachment__pdf--remove icon-close"
          onClick={() => image && removeAttachment(image)}
        >
        </span>
      )}
      {image.name && <span>{image.name}</span>}
    </div>)
    : images.map(image => <div
      className="attachment__image__container"
      key={image.id}
    >
      <i className="icon-delete" onClick={() => image && removeAttachment(image)}></i>
      <img
        src={image.attachmentUrl}
        alt={image.name || "M Containers"}
        className="attachment__image"
        onClick={inputClickHandler}
      />
    </div>)

  if (!multiple && images.length)
    return (
      <div className={`attachment-container ${!accept?.includes("image") ? 'document-container' : 'image-container'} ${className}`}>
        {imagesList}
      </div>
    )


  return (
    <div className={`attachment-container ${!accept?.includes("image") ? 'document-container' : 'image-container'} ${className}`}>
      {imagesList}
      <input
        ref={attachmentRef}
        accept={accept}
        type="file"
        multiple={multiple}
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      {uploading
        ? <Skeleton.Button
          active={true}
          size="large"
          shape="round" />
        : !accept?.includes("image")
          ? <div className="attachment-document__uploader" onClick={inputClickHandler}>
            <i className="icon-upload-photo"></i>
            <h3>Drag and drop to upload
              <br />
              <span className="browse-link">
                or browse file
              </span>
            </h3>
          </div>
          : <div className="attachment-image__uploader" onClick={inputClickHandler}>
            <img src={AddIcon} alt="" />
            Add Photo
          </div>}
    </div>
  );
};
