import { ErrorMessage, Field } from 'formik'
import React, { FC } from 'react'
import PhoneInput from 'react-phone-number-input'
import "./phoneNumberInput.scss"

interface PhoneNumberInputProps {
    name: string
    value: string | number | undefined
    onChange: (value: string) => void
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = (props) => {

    const {
        name,
        value,
        onChange,
    } = props
    return (
        <div className='phone-number-input'>
            <Field name={name}>
                {({ field, meta, form }: any) => <>
                    <PhoneInput
                        placeholder="Enter"
                        {...field}
                        {...meta}
                        countrySelectProps={{ unicodeFlags: true }}
                        countryCallingCodeEditable={false}
                        international
                        defaultCountry="CA"
                        value={value as any}
                        onChange={onChange} />
                    <span className='input__error'>

                        <ErrorMessage className='input__error' name={name}></ErrorMessage>
                    </span>
                </>}
            </Field>
        </div>
    )
}

export default PhoneNumberInput
