import React, { FC } from 'react'
import "./confirmationModal.scss"
import { Modal } from "antd";
import ButtonComponent from "../ButtonComponent";

interface ConfirmationModalProps {
    icon: string | React.ReactChild
    visible: boolean;
    title: string;
    description?: string
    name?: string;
    closeHandler: () => void
    proceedHandler: () => void;
    buttonLoading: boolean;
}

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {

    const { title, visible, icon, name, proceedHandler, description, buttonLoading, closeHandler } = props;

    return (
        <Modal title={null}
            footer={null}
            visible={visible}
            closeIcon={false}
            closable={false}
            onCancel={closeHandler}
            className="confirmation-modal"
        >
            <div className="confirmation-modal--img">
                {typeof icon === "string"
                    ? <img src={icon} alt="" />
                    : icon}
            </div>
            <div className="confirmation__content-modal">{title} {name ? <>- <span>{name}</span>?</> : <span></span>}</div>
            {description && <p className="confirmation-modal--hint">{description}</p>}
            <div className="confirmation__actions-modal">
                <ButtonComponent className='confirmation__action-modal'
                    type="text"
                    text="Cancel"
                    onClick={closeHandler}
                />
                <ButtonComponent loading={buttonLoading}
                    className='confirmation__action-modal'
                    type="primary"
                    text="Confirm"
                    onClick={proceedHandler}
                />
            </div>
        </Modal>
    )
}

export default ConfirmationModal;