import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import { AUTHENTICATED, UNAUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import LocalStorage from "../../shared/LocalStorage";

const DashboardService = () => {
	const history = useHistory();

	const [loading, setLoading] = useState<boolean>(false)

	const [containerTable, setContainerTable] = useState<any[]>([])

	const [date, setDate] = useState<string>('')
	const [rate, setRate] = useState<string>('')

	const [employeeTable, setEmployeeTable] = useState<any[]>([])

	const [statusTable, setStatusTable] = useState<any[]>([])

	const keyToArrayElementMapper = (object: any) => {
		const objectData = []
		for (const key in object) {
			if (Object.prototype.hasOwnProperty.call(object, key))
				objectData.push(Object.assign({}, [key, ...(object[key] || [])]))
		}
		return objectData
	}

	const fetchAdminDashboard = async () => {
		setLoading(true)
		try {
			const API_URL = generatePath(ApiRoutes.DASHBOARD, { userType: 'admin' })
			const response = await axiosInstance.get(API_URL)
			const containerTable = response?.data['container_table']
			const statusTable = response?.data['status_table']
			const employeeTable = response?.data['employee_table']
			setContainerTable(keyToArrayElementMapper(containerTable));
			setStatusTable(keyToArrayElementMapper(statusTable));
			setEmployeeTable(employeeTable?.map((item: string[]) => Object.assign({}, [...(item || [])])))
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	const fetchCustomerDashboard = async () => {
		setLoading(true)
		try {
			const API_URL = generatePath(ApiRoutes.DASHBOARD, { userType: 'customer' })
			const response = await axiosInstance.get(API_URL)
			const containerTable = response?.data['container_table']
			const statusTable = response?.data['status_table']
			setContainerTable(keyToArrayElementMapper(containerTable));
			setStatusTable(keyToArrayElementMapper(statusTable));
			setDate(response?.data['date'])
			setRate(response?.data['current_rate'])
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	return {
		rate,
		date,
		loading,
		statusTable,
		containerTable,
		employeeTable,
		fetchAdminDashboard,
		fetchCustomerDashboard,
	}
};

export default DashboardService;
