export const ApiRoutes = {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
    STUB_URL: " https://private-anon-eb462700d3-mlcanapi.apiary-mock.com/api/v1",
    USER_LOGIN: "/user_management/shared/auth/login",
    USER_LOGOUT: "/user_management/shared/auth/logout",
    RESET_PASSWORD: "/user_management/admin/auth/reset_password",
    FORGOT_PASSWORD: "/user_management/admin/auth/forgot_password",
    VERIFY_RESET_PASSWORD: "/user_management/admin/auth/validate_reset_password_token",

    /* Attachment */
    ATTACHMENT: "file_management/uploaded_files",

    /* Meta */
    COMPONENTS: "/meta/components",
    COMPS: "/meta/comps",
    DAMS: "/meta/dams",
    REPS: "/meta/reps",
    EVENTS: "/meta/events",
    UNITS: "/meta/units",
    MODE_NUMBERS: "/meta/mode_numbers",
    REPAIR_MODES: "/meta/repair_modes",
    REPAIR_AREAS: "/meta/container_repair_areas",
    DAMAGED_AREAS: "/meta/container_damaged_areas",
    REPAIR_TYPES: "/meta/repair_types",
    PROVINCES: "/meta/provinces",
    CITY: "/meta/cities?province_id=:provinceId",
    YARDS: "/meta/yards",
    LENGTH: "/meta/lengths",
    WIDTH: "/meta/widths",
    CONTAINER_LENGTH: "/meta/container_lengths",
    CONTAINER_WIDTH: "/meta/container_heights",
    CONTAINER_TYPES: "/meta/container_types",
    ALL_CUSTOMERS: "/user_management/admin/all_customers",
    ALL_ACTIVE_CUSTOMERS: "/user_management/admin/all_active_customers",
    ACTIVITY_STATUSES: "/activity_management/admin/activity_statuses",
    CONTAINER_ACTIVITY_ITEM_DAMAGED_AREA: "/activity_management/admin/activities/auto_populate_damage_area",
    CONTAINER_ACTIVITY_ITEM_REPAIR_TYPE: "/activity_management/admin/activities/auto_populate_repair_type",
    CONTAINER_ACTIVITY_ITEM_LENGTH: "/activity_management/admin/activities/auto_populate_length",
    CONTAINER_ACTIVITY_ITEM_WIDTH: "/activity_management/admin/activities/auto_populate_width",
    CONTAINER_ACTIVITY_ITEM_UNIT: "/activity_management/admin/activities/auto_populate_unit",
    CONTAINER_ACTIVITY_ITEM_ALL: "/activity_management/admin/activities/auto_populate_all",
    /* Repairs */
    REPAIRS_LIST: '/repair_list_management/admin/repair_list',
    REPAIRS_LIST_ITEMS: '/repair_list_management/admin/repair_list/:repair_list_id/items',
    REPAIRS_LIST_ITEMS_UID: '/repair_list_management/admin/repair_list/:repair_list_id/items/get_uid',
    REPAIRS_LIST_IMPORT: '/repair_list_management/admin/repair_list/:repair_list_id/items/upload',
    REPAIRS_LIST_DOWNLOAD: '/repair_list_management/admin/repair_list/:repair_list_id/items/export',
    REPAIRS_LIST_CUSOTMERS_META: "/repair_list_management/shared/all_customer_lists",
    REPAIRS_LIST_CUSTOMER: 'repair_list_management/admin/customer_repair_list',
    REPAIRS_LIST_CUSTOMER_ITEMS: "/repair_list_management/admin/customer_repair_list/:customerRepairListId/items",
    REPAIRS_LIST_CUSTOMER_EXPORT: "/repair_list_management/admin/customer_repair_list/:customerRepairListId/items/export",
    REPAIRS_LIST_CUSTOMER_IMPORT: "/repair_list_management/admin/customer_repair_list/:customerRepairListId/items/upload",
    REPAIRS_LIST_CUSTOMER_DELETE_ITEMS: "/repair_list_management/admin/customer_repair_list/items/:id",
    REPAIRS_LIST_DEFAULT_VERSION: "repair_list_management/admin/repair_list/version_activation/:repairListId",
    /*Customers*/
    CUSTOMERS: "/user_management/admin/customers",
    CUSTOMERS_SPECIFIC: "/user_management/admin/customers/:customerId",
    CUSTOMERS_REPAIRS_LIST_DEFAULT_VERSION: "repair_list_management/admin/customer_repair_list/version_activation/:repairListId",

    /*Containers */
    CONTAINERS: "/container_management/admin/containers",
    CONTAINERS_SPECIFIC: "/container_management/:userType/containers/:containerId",
    CONTAINER_NUMBER_VALIDATION: "/container_management/admin/containers/validate_container",
    CONTAINERS_SPECIFIC_IMAGES_DOWNLOAD: "container_management/admin/containers/:containerId/download",
    CONTAINERS_ACTIVITIES: "/activity_management/:userType/activities",
    CONTAINERS_ACTIVITIES_DATE_UPDATE: "activity_management/admin/activities/update_date",
    CONTAINERS_ACTIVITIES_SPECIFIC: "/activity_management/:userType/activities/:activityId",
    CONTAINERS_ACTIVITIES_SPECIFIC_DOWNLOAD: "/activity_management/:userType/activities/:activityId/download",
    CONTAINERS_ACTIVITIES_SPECIFIC_DOWNLOAD_ALL: "/activity_management/admin/activities/:activityId/download_all",
    CONTAINERS_ACTIVITIES_FORM_AUTOPOPULATE: "/activity_management/admin/activities/auto_populate",
    CONTAINERS_ACTIVITIES_SPECIFIC_ITEMS: "/activity_management/:userType/activities/:activityId/activity_items",
    CONTAINERS_ACTIVITIES_SPECIFIC_ITEM: "/activity_management/admin/activities/:activityId/activity_items/:activityItemId",
    CONTAINERS_ACTIVITIES_SPECIFIC_EXPORT: "/activity_management/admin/activities_export/:activityId",
    CONTAINER_ACTIVITIES: "/activity_management/:userType/container_activities",
    CONTAINER_ACTIVITIES_EXPORT: "/activity_management/admin/activities_export",
    CONTAINER_ACTIVITIES_APPROVAL: "/activity_management/admin/activities/update_status",
    CONTAINER_COMMENTS: "/container_management/admin/comments",
    CONTAINER_LOGS: "/container_management/admin/container_logs",
    /*Invoices */
    INVOICES: "/invoice_management/admin/invoices",
    INVOICE_HISTORY: "/invoice_management/:userType/invoice_history",
    INVOICE_IMAGE_EXPORT: "/invoice_management/:userType/invoices/bulk_image_export",
    INVOICES_EXPORT: "/invoice_management/:userType/invoices/export",
    INVOICES_SPECIFIC: "/invoice_management/admin/invoices/:invoiceId",
    INVOICES_VOID: "/invoice_management/admin/invoices/update",
    INVOICES_MAIL: "invoice_management/admin/mail_invoice/:invoiceId",
    INVOICES_PRINT: "invoice_management/admin/print_invoice/:invoiceId",

    /*User Management */
    PROFILES: "/user_management/admin/profiles",
    PROFILES_SPECIFIC: "/user_management/admin/profiles/:profileId",
    CUSTOMER_PROFILES_SPECIFIC: "/user_management/customer/profiles/:profileId",

    /*Reports */
    REPORTS: "/report_management/admin/export",

    /*Dashboard */
    DASHBOARD: "/dashboard_management/:userType/dashboard",
}
