import { Row, Col } from "antd";
import { FormikHelpers } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Customer } from "../../../models/customer.model";
import CustomerService from "../../../services/CustomerService/customer.service";
import AppHeader from "../../../shared/components/AppHeader";
import Loader from "../../../shared/components/Loader";
import TableSearchFilterComponent from "../../../shared/components/TableSearchFilterComponent";
import { getIdFromUrl } from "../../../shared/utils/getIdFromUrl";
import CustomerForm from "../CustomerForm";
import { Tabs } from 'antd';
import "./customerDetails.scss"
import CustomerCard from "../../../shared/components/CustomerCard";
import CustomerRepairList from "./CustomerRepairList";
const { TabPane } = Tabs;
interface CustomerDetailsProps { }

const CustomerDetails = (props: CustomerDetailsProps) => {

    const { location } = useHistory()
    const customerId = getIdFromUrl(location.pathname)

    const [customer, setCustomer] = useState<Customer>()

    const [formVisible, setFormVisible] = useState<boolean>(false)

    const {
        loading,
        submitting,
        updateCustomer,
        fetchSingleCustomer,
    } = CustomerService()

    useEffect(() => {
        fetchCustomer()
    }, [])

    const fetchCustomer = async () => {
        const data = await fetchSingleCustomer(customerId)
        setCustomer(data);
    }

    const formOpenHandler = () => {
        setFormVisible(true)
    }

    const formCloseHandler = () => {
        setFormVisible(false)
    }

    const formSubmissionHandler = async (values: Customer, helpers: FormikHelpers<any>) => {
        if (!values?.id) return
        const customer = await updateCustomer(values)
        if (customer) {
            setCustomer(customer)
            formCloseHandler()
        }
    }

    return loading
        ? <Loader />
        : <div className="customer-list__container">
            <AppHeader
                className="bg-white"
                goBackText={'Customers'}
                title={customer?.fullName || ""}
                editButton={true}
                editButtonClickHandler={formOpenHandler}
            />
            <div className="customer-list--tabs">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Details" key="1">
                        <CustomerCard customer={customer} />
                    </TabPane>
                    <TabPane tab="Repair List" key="2">
                        <CustomerRepairList customer={customer} customerId={customerId} />
                    </TabPane>
                </Tabs>
            </div>
            <CustomerForm
                visible={formVisible}
                loading={submitting}
                data={customer}
                closeHandler={formCloseHandler}
                submitHandler={formSubmissionHandler}
            />
        </div>
}

export default CustomerDetails