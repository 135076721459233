import { fileExtensionGenerator } from './../../shared/utils/fileDownloader';
import { REGEX } from './../../shared/utils/regex';
import { Pagination } from './../../models/pagination.model';
import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Repair, RepairVerison } from "../../models/repair.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { fileDownloader } from "../../shared/utils/fileDownloader";
import { generatePath } from 'react-router';

const RepairService = () => {

    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [repairList, setRepairList] = useState<Repair[]>([])
    const [repairListMeta, setRepairListMeta] = useState<Pagination>({})
    const [versionList, setVersionList] = useState<RepairVerison[]>([])

    const fetchRepairVersions = () => {
        setLoading(true);
        setVersionList([])
        return axiosInstance
            .get(ApiRoutes.REPAIRS_LIST)
            .then((response) => {
                const data = deserialize(RepairVerison, response.data['repair_lists']) as RepairVerison[]
                setVersionList(data.map(item => ({ ...item, name: item.name?.replace(REGEX.DATE_MATCH_TEMP, "") })))
            })
            .catch((error) => {
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const fetchCustomerRepairListMeta = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.REPAIRS_LIST_CUSOTMERS_META)
            .then((response) => {
                const data = deserialize(RepairVerison, response.data['customer_repair_lists']) as RepairVerison[]
                return data
            })
            .catch((error) => {
                console.log(error);

            })
            .finally(() => {
                setLoading(false)
            });
    }

    const fetchAdminRepairList = (versionId: string, params?: {
        page?: number,
        sort?: any,
        filter?: any,
    }) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.REPAIRS_LIST_ITEMS.replace(":repair_list_id", String(versionId)), { params })
            .then((response) => {
                const data = deserialize(Repair, response.data['repair_list_items']) as Repair[]
                const meta = deserialize(Pagination, response.data['meta'])
                setRepairList(data)
                setRepairListMeta(meta)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };


    const createNewVersion = () => {
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.REPAIRS_LIST)
            .then((response) => {
                fetchRepairVersions()
            })
            .catch((error) => {
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const uploadRepairList = (uploaded_file_id: number, versionId: number) => {
        setSubmitting(true)
        const payload = {
            uploaded_file_id
        }
        return axiosInstance
            .post(ApiRoutes.REPAIRS_LIST_IMPORT.replace(":repair_list_id", String(versionId)), payload)
            .then((response) => {
            })
            .catch((error) => {
                throw error;
            }).finally(() => {
                setSubmitting(false)
            })
    }

    const downloadRepairList = (versionId: number | string) => {
        setSubmitting(true)
        return axiosInstance
            .get(ApiRoutes.REPAIRS_LIST_DOWNLOAD.replace(":repair_list_id", String(versionId)), { responseType: "blob" })
            .then((response) => {
                fileDownloader(response?.data, `Repair-list-version-${versionId}.${fileExtensionGenerator(response.data.type)}`);
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                setSubmitting(false)
            });
    }

    const fetchRepairUid = (versionId: string | number) => {
        return axiosInstance.get(ApiRoutes.REPAIRS_LIST_ITEMS_UID.replace(":repair_list_id", String(versionId)))
            .then(response => {
                return response.data['uid']
            })
            .catch(err => { })
    }

    const createRepairItem = (repairItem: Repair, versionId: number) => {
        setSubmitting(true)
        const payload = {
            repair_list_item: serialize(Repair, repairItem)
        }
        return axiosInstance
            .post(ApiRoutes.REPAIRS_LIST_ITEMS.replace(":repair_list_id", String(versionId)), payload)
            .then((response) => {
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                setSubmitting(false)
            });
    }

    const editRepairItem = (repairItem: Repair, versionId: number) => {
        setSubmitting(true)
        const payload = {
            repair_list_item: serialize(Repair, repairItem)
        }
        return axiosInstance
            .put(ApiRoutes.REPAIRS_LIST + "/items/" + repairItem?.id,
                payload,
            )
            .then((response) => {
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                setSubmitting(false)
            });
    }


    const deleteRepairItem = (repairItemId: string) => {
        setSubmitting(true)
        return axiosInstance
            .delete(ApiRoutes.REPAIRS_LIST + "/items/" + repairItemId)
            .then((response) => {
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                setSubmitting(false)
            });
    }

    const setVersionAsDefault = async (repairListId: string | number) => {
        setLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.REPAIRS_LIST_DEFAULT_VERSION, { repairListId })
            const { data } = await axiosInstance.put(API_URL)
            return data
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        repairList,
        submitting,
        versionList,
        repairListMeta,
        editRepairItem,
        fetchRepairUid,
        uploadRepairList,
        createRepairItem,
        deleteRepairItem,
        createNewVersion,
        downloadRepairList,
        fetchRepairVersions,
        setVersionAsDefault,
        fetchAdminRepairList,
        fetchCustomerRepairListMeta,
    }
}

export default RepairService;