import { Col, Row } from 'antd'
import { Formik, Form, FormikHelpers } from 'formik'
import React, { FC, useEffect } from 'react'
import ButtonComponent from '../../../../shared/components/ButtonComponent'
import InputField from '../../../../shared/components/InputField'
import "./containerCommentList.scss"
import EmptyComments from "./../../../../assets/single color icons - SVG/chatbox.svg"
import ContainerService from '../../../../services/ContainerService/container.service'
import { ContainerComment } from '../../../../models/containerComment.model'
import AuthContainer from '../../../../store/container/AuthContainer'
import ContainerCommentCard from './ContainerCommentCard'
import Loader from '../../../../shared/components/Loader'

interface ContainerCommentListProps {
    containerId: string
}

const colors = ["#356AE6", "#773195", "#F99F1C"]

const ContainerCommentList: FC<ContainerCommentListProps> = (props) => {

    const {
        containerId,
    } = props

    const {
        loading,
        submitting,
        containerComments,
        fetchContainerComments,
        createContainerComments,
    } = ContainerService()

    useEffect(() => {
        fetchComments()
    }, [])

    const commentSubmitHandler = async (values: ContainerComment, helpers: FormikHelpers<ContainerComment>) => {
        if (!values.comment) return
        const response = await createContainerComments(containerId, values.comment)
        if (!response) return
        fetchComments()
        helpers.resetForm()
    }

    const fetchComments = () => containerId && fetchContainerComments(containerId)

    if (loading)
        return <Loader />

    return (
        <div className='container-comment-list'>
            <div className="container-comment-list__actions">
                <Formik initialValues={{ ...new ContainerComment() }}
                    onSubmit={commentSubmitHandler}>
                    <Form>
                        <Row gutter={16}>
                            <Col span={16}>
                                <InputField
                                    placeholder="Type your comment "
                                    name="comment"
                                    type="text" />
                            </Col>
                            <Col>
                                <ButtonComponent
                                    htmlType='submit'
                                    type="primary"
                                    loading={submitting}
                                    text="Add Comment" />
                            </Col>
                        </Row>
                    </Form>
                </Formik>
                {containerComments.length
                    ? <div className="container-comment-list__comments">
                        {containerComments.map((comment, i) => <ContainerCommentCard color={colors[!(i % 3) ? 2 : !(i % 2) ? 1 : 0]} data={comment} />)}
                    </div>
                    : <div className="container-comment-list__comments-empty">
                        No comments found
                    </div>}
            </div>
        </div>
    )
}

export default ContainerCommentList
