import { serializable, alias, primitive, object } from 'serializr';
import { User } from './user.model';

export class ContainerLog {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('log_type', primitive()))
    logType?: string;

    @serializable(alias('invoice_number', primitive()))
    invoiceNumber?: string;

    @serializable(alias('activity_uid', primitive()))
    activityUid?: string;

    @serializable(alias('activity_type', primitive()))
    activityType?: string;

    @serializable(alias('event_logged', primitive()))
    eventLogged?: string;

    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('user', object(User)))
    loggedBy?: User;
}

