import { Row, Col } from 'antd'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as AppRoutes from '../../../routes/routeConstants/appRoutes'
import DashboardService from '../../../services/DashboardService/dashboard.service'
import AppHeader from '../../../shared/components/AppHeader'
import Loader from '../../../shared/components/Loader'
import TableComponent from '../../../shared/components/TableComponent'
import "./adminDashboard.scss"

interface AdminDashboardProps { }

const AdminDashboard: FC<AdminDashboardProps> = (props) => {

    const history = useHistory()

    const {
        loading,
        statusTable,
        containerTable,
        employeeTable,
        fetchAdminDashboard,
    } = DashboardService()

    useEffect(() => {
        fetchAdminDashboard()
    }, [])


    const columns = [
        {
            title: "",
            dataIndex: "0",
            key: "0",
            width: 200,
            render: (text: string) => <span className='text-capitalize'>{text?.replaceAll("_", " ")}</span>
        },
        {
            title: <p className='mb-0'>{moment(new Date()).format("DD, MMM")} <small style={{ fontWeight: 'lighter' }}>  (Today)</small></p>,
            dataIndex: "1",
            key: "1",
            // align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: moment(new Date().setDate(new Date().getDate() - 1)).format("DD, MMM"),
            dataIndex: "2",
            key: "2",
            // align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: moment(new Date().setDate(new Date().getDate() - 2)).format("DD, MMM"),
            dataIndex: "3",
            key: "3",
            // align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: moment(new Date().setDate(new Date().getDate() - 3)).format("DD, MMM"),
            dataIndex: "4",
            key: "4",
            // align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: moment(new Date().setDate(new Date().getDate() - 4)).format("DD, MMM"),
            dataIndex: "5",
            key: "5",
            // align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
    ]

    const statusColumns = [
        {
            title: "",
            dataIndex: "0",
            key: "0",
            width: 200,
            render: (text: string) => <span className='text-capitalize'>{text?.replaceAll("_", " ")}</span>
        },
        {
            title: "Count",
            dataIndex: "1",
            key: "1",
            width: 100,
            className: "amount-column",
            align: 'right' as 'right',
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Value",
            dataIndex: "2",
            key: "2",
            width: 100,
            className: "amount-column",
            align: 'right' as 'right',
            render: (text: number) => (text || 0)?.toFixed(2)
            // sorter: true,
        },
    ]

    const employeeColumns = [
        {
            title: "Technician/Employee",
            dataIndex: "0",
            key: "0",
            width: 100,
            // sorter: true,
        },
        {
            title: <p className='mb-0'>Hours <br /><small style={{ fontWeight: 'lighter' }}>Last 7 days</small> </p>,
            dataIndex: "1",
            key: "1",
            width: 100,
            // sorter: true,
            className: "amount-column",
            align: 'right' as 'right',
            render: (text: number) => (text || 0)
        },
        {
            title: <p className='mb-0'>Containers <br /><small style={{ fontWeight: 'lighter' }}>Last 7 days</small> </p>,
            dataIndex: "2",
            key: "2",
            width: 100,
            className: "amount-column",
            align: 'right' as 'right',
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Hrs / container",
            dataIndex: "3",
            key: "3",
            width: 100,
            // sorter: true,
            className: "amount-column",
            align: 'right' as 'right',
            render: (text: number) => (text || 0)?.toFixed(2)
        },
    ]



    if (loading)
        return <Loader />

    return (
        <div className='admin-dashboard__container'>
            <AppHeader
                title={"Dashboard"}
                addButton={false}
            />
            <Row className='admin-dashboard__table-wrapper' gutter={[32, 32]}>
                <Col span={24}>
                    <TableComponent
                        columns={columns}
                        data={containerTable}
                        loading={false}
                        pagination={false}
                    />
                </Col>
                <Col span={12}>
                    <TableComponent
                        className='cursor-pointer'
                        columns={statusColumns}
                        data={statusTable}
                        handleRowClick={(row: string[]) => {
                            const status = row[0]
                            history.push({
                                pathname: status.includes('billing') ? AppRoutes.INVOICES : AppRoutes.CONTAINERS,
                                hash: status.replaceAll("_", "-")
                            })
                        }}
                        loading={false}
                        scroll={{ x: 400 }}
                        pagination={false}
                    />
                </Col>
                {/* <Col span={14}>
                    <TableComponent
                        columns={employeeColumns}
                        data={employeeTable}
                        loading={false}
                        scroll={{ x: 400 }}
                        pagination={false}
                    />
                </Col> */}
            </Row>
        </div>
    )
}

export default AdminDashboard