import { Row, Col } from 'antd';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react'
import { UserTypes } from '../../../enums/userTypes.enum';
import { Profile } from '../../../models/profile.model';
import UserService from '../../../services/UserService/user.service';
import DropdownField from '../../../shared/components/DropdownField';
import InputField from '../../../shared/components/InputField';
import PhoneNumberInput from '../../../shared/components/PhoneNumberInput';
import StatusField from '../../../shared/components/StatusField';
import UIModal from '../../../shared/components/UIModal';
import "./userForm.scss"
import { userFormValidationSchema } from './userForm.validation';
import { parsePhoneNumber } from 'react-phone-number-input/input'

interface UserFormProps {
    visible: boolean
    data?: Profile
    closeHandler: (response?: UserTypes) => void
}

const roles = [
    {
        label: "Admin",
        value: "administrator"
    }, {
        label: "Employee",
        value: "employee"
    }
]

const UserForm: FC<UserFormProps> = (props) => {

    const {
        visible,
        closeHandler,
        data,
    } = props

    const {
        submitting,
        createAdminUser,
        updateAdminUser,
    } = UserService()

    const [initialValues, setInitialValues] = useState<Profile>(new Profile())

    const formikRef = useRef<FormikProps<Profile>>(null)

    useEffect(() => {

        setInitialValues(data ? { ...data } : new Profile())
    }, [data])


    const submitHandler = async (values: Profile, helpers: FormikHelpers<Profile>) => {
        const mobile = parsePhoneNumber(values.mobile || '', { defaultCountry: "CA" })
        // const formattedValues = {
        //     ...values,
        //     mobile: mobile?.nationalNumber as string,
        //     isdCode: mobile?.countryCallingCode as string
        // }
        const response = values.id
            ? await updateAdminUser(values)
            : await createAdminUser(values)
        if (!response) return
        helpers.resetForm()
        closeHandler(values?.role)
    }

    return <UIModal
        visible={visible}
        title={`${initialValues.id ? 'Edit' : "Add New"} User`}
        okText="Proceed"
        onOk={() => formikRef.current?.submitForm()}
        onCancel={() => closeHandler()}
        loading={submitting}
    >
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={userFormValidationSchema}
            enableReinitialize
        >
            {({ setFieldValue, values, errors }) => {
                return (
                    <Form>
                        <Row gutter={[20, 20]}>
                            <Col span={24} className="user-form__field">
                                <DropdownField
                                    title={'User Type'}
                                    name='role'
                                    options={roles}
                                    value={values.role}
                                    onChange={(value) => {
                                        setFieldValue("role", value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="user-form__field">
                                <div className="form-label">First Name</div>
                                <InputField
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter"
                                    value={values.firstName}
                                />
                            </Col>
                            <Col span={24} className="user-form__field">
                                <div className="form-label">Last Name</div>
                                <InputField
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter"
                                    value={values.lastName}
                                />
                            </Col>
                            <Col span={24} className="user-form__field">
                                <div className="form-label">Email</div>
                                <InputField
                                    type="text"
                                    name="email"
                                    placeholder="Enter"
                                    value={values.email}
                                    prefix={<i className="icon-mail" />}
                                />
                            </Col>
                            <Col span={24} className="user-form__field">
                                <div className="form-label">Phone Number</div>
                                <PhoneNumberInput
                                    name={'mobile'}
                                    value={values.mobile}
                                    onChange={(value) => setFieldValue("mobile", value)}
                                />
                            </Col>
                            <Col span={24} className="user-form__field">
                                <div className="form-label">Password</div>
                                <InputField
                                    type="text"
                                    name="password"
                                    placeholder="Enter"
                                />
                            </Col>
                            <Col span={24} className="user-form__field">
                                <div className="form-label">Status</div>
                                <StatusField
                                    checked={!!values.status}
                                    onChange={(value: boolean) => {
                                        setFieldValue("status", value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    </UIModal>
}

export default UserForm
