import { Activity, ActivityItems } from './activity.model';
import { serializable, alias, primitive, list, object } from 'serializr';
import { Customer } from './customer.model';

export class Invoice {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('invoice_number', primitive()))
    invoiceNumber?: string;

    @serializable(alias('activity_ids', list(primitive())))
    activityIds?: number[];

}

export class ActivityInvoice {
    @serializable(alias('activity_id', primitive()))
    activityId?: number;

    @serializable(alias('invoice_id', primitive()))
    invoiceId?: number;

    @serializable(alias('invoice_number', primitive()))
    invoiceNumber?: string;

    @serializable(alias('created_at', primitive()))
    invoiceDate?: string;

    @serializable(alias('status', primitive()))
    status?: string;

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('total_labour_hours', primitive()))
    totalLabourHours?: number;

    @serializable(alias('parts_cost', primitive()))
    partsCost?: number;

    @serializable(alias('total_labour_cost', primitive()))
    totalLabourCost?: number;

    @serializable(alias('subtotal_repair_cost', primitive()))
    subtotalRepairCost?: number;

    @serializable(alias('invoice_total', primitive()))
    invoiceTotal?: number;

    @serializable(alias('activity', object(Activity)))
    activity?: Activity;

    @serializable(alias('customer', object(Customer)))
    customer?: Customer;

    @serializable(alias('activities', list(object(Activity))))
    activities?: Activity[];

    @serializable(alias('invoice_activity_items', list(object(ActivityItems))))
    activityItems?: ActivityItems[];

    @serializable(alias('invoice', object(Invoice)))
    invoice?: Invoice;
}
