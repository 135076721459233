import { UserTypes } from './../../enums/userTypes.enum';
import { ActivityInvoice } from './../../models/invoice.model';
import { generatePath } from 'react-router-dom';
import { useState } from 'react';
import { deserialize } from 'serializr';
import { ActivityEnum } from '../../enums/activity.enum';
import axiosInstance from '../../interceptor/axiosInstance';
import { Activity } from '../../models/activity.model';
import { Pagination } from '../../models/pagination.model';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { InvoiceEnum } from '../../enums/invoice.enum';

const InvoiceService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const [invoicesSubmitting, setInvoicesSubmitting] = useState<boolean>(false)

    const [invoicesSecondarySubmitting, setInvoicesSecondarySubmitting] = useState<boolean>(false)

    const [billableInvoices, setBillableInvoices] = useState<Activity[]>([])

    const [billedInvoices, setBilledInvoices] = useState<ActivityInvoice[]>([])

    const [invoiceActivity, setInvoiceActivity] = useState<ActivityInvoice>()

    const [billableInvoiceMeta, setBillableInvoiceMeta] = useState<Pagination>({})

    const [billedInvoiceMeta, setBilledInvoiceMeta] = useState<Pagination>({})

    const fetchBillableInvoices = async (params: {
        status: ActivityEnum,
        container_id?: number,
        search_text?: string,
        page?: number,
        yard_id?: string,
    }) => {
        setLoading(true)
        setBilledInvoices([])
        setBillableInvoices([])
        try {
            const response = await axiosInstance.get(ApiRoutes.INVOICES, { params })
            const data = deserialize(Activity, response.data['activities']) as Activity[]
            const meta = deserialize(Pagination, response.data['meta'])
            setBillableInvoices(data)
            setBillableInvoiceMeta(meta)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const fetchBilledInvoices = async (params: {
        status: ActivityEnum,
        container_id?: number,
        search_text?: string,
        page?: number,
        yard_id?: string,
    }, userType: UserTypes | string) => {
        setLoading(true)
        setBillableInvoices([])
        setBilledInvoices([])
        try {
            const API_URL = generatePath(ApiRoutes.INVOICE_HISTORY, { userType })
            const response = await axiosInstance.get(API_URL, { params })
            const data = deserialize(ActivityInvoice, response.data['activities_invoices']) as ActivityInvoice[]
            const meta = deserialize(Pagination, response.data['meta'])
            setBilledInvoices(data)
            setBilledInvoiceMeta(meta)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const createInvoices = async (data: {
        activity_ids: number[]
    }, export_option: InvoiceEnum) => {
        setInvoicesSubmitting(true)
        const payload = { ...data, export_option } as any
        try {
            const response = await axiosInstance.post(ApiRoutes.INVOICES, payload)
            // Notification({
            //     message: "",
            //     description: "Invoices created succesfully",
            //     type: NotificationTypes.SUCCESS,
            // });
            return response
        } catch (error) {

        } finally {
            setInvoicesSubmitting(false)
        }
    }

    const fetchInvoice = async (invoiceId: string) => {
        setLoading(true)
        try {
            const API_URL = generatePath(ApiRoutes.INVOICES_SPECIFIC, { invoiceId })
            const response = await axiosInstance.get(API_URL)
            const data = deserialize(ActivityInvoice, response.data['invoice'])
            setInvoiceActivity(data);
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const markInvoiceVoid = async (invoice_ids: number[]) => {
        setInvoicesSubmitting(true)
        try {
            const payload = { invoice_ids, status: "void" }
            await axiosInstance.post(ApiRoutes.INVOICES_VOID, payload)
            return true
        } catch (error) {

        } finally {
            setInvoicesSubmitting(false)
        }
    }

    const mailInvoice = async (invoiceId: number) => {
        setInvoicesSubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.INVOICES_MAIL, { invoiceId })
            const response = await axiosInstance.post(API_URL)
            Notification({
                type: NotificationTypes.SUCCESS,
                message: "",
                description: response.data.success,
            });
        } catch (error) {

        } finally {
            setInvoicesSubmitting(false)
        }
    }

    const printInvoice = async (invoiceId: number | string) => {
        setInvoicesSecondarySubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.INVOICES_PRINT, { invoiceId })
            const response = await axiosInstance.get(API_URL)
            if (response?.data?.pdf_url)
                window.open(response?.data?.pdf_url);
            else
                Notification({
                    message: "",
                    description: "Unable to print invoice",
                    type: NotificationTypes.ERROR,
                });

        } catch (error) {

        } finally {
            setInvoicesSecondarySubmitting(false)
        }
    }

    return {
        loading,
        billableInvoices,
        billedInvoices,
        fetchBillableInvoices,
        fetchBilledInvoices,
        billableInvoiceMeta,
        invoiceActivity,
        fetchInvoice,
        createInvoices,
        mailInvoice,
        printInvoice,
        markInvoiceVoid,
        billedInvoiceMeta,
        invoicesSubmitting,
        invoicesSecondarySubmitting,
    }
}

export default InvoiceService