import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { Customer } from "../../../../models/customer.model";
import { MetaModel } from "../../../../models/meta.model";
import { Repair, RepairVerison } from "../../../../models/repair.model";
import { ApiRoutes } from "../../../../routes/routeConstants/apiRoutes";
import CustomerService from "../../../../services/CustomerService/customer.service";
import MetaService from "../../../../services/MetaService/meta.service";
import DeleteConfirmation from "../../../../shared/components/DeleteConfirmation";
import RepairListTableActions from "../../../../shared/components/RepairListTableActions";
import TableComponent from "../../../../shared/components/TableComponent";
import useFilters from "../../../../shared/hooks/useFilters";
import RepairDetails from "../../../RepairList/RepairDetails";
import RepairForm from "../../../RepairList/RepairForm";
import RepairImportForm from "../../../RepairList/RepairImportForm";
import { CustomerBillingTypes } from "../../CustomerForm/customer.definition";
import "./customerRepairList.scss"

interface CustomerRepairListProps {
    customerId: string
    customer?: Customer
}

const sortFields: any = {
    "repairType": "repair_type",
    "repairUid": "uid",
    "containerRepairArea": "container_repair_area",
    "containerDamagedArea": "container_damaged_area"
}

const commonColumnKeys = ["nonMearskHours", "nonMearskMaterialCostDollars"]
const merskColumnKeys = ["mesrskHoursPerUnit", "mearskUnitMaterialCostDollar"]

const CustomerRepairList = (props: CustomerRepairListProps) => {

    const {
        customer,
        customerId,
    } = props

    const {
        loading,
        submitting,
        customerVersions,
        customerRepairItems,
        customerRepairItemsMeta,
        createCustomerRepairList,
        fetchCustomerRepairList,
        deleteCustomerRepairItem,
        fetchCustomerRepairListItems,
        downloadCustomerRepairList,
        setVersionAsDefault,
    } = CustomerService()

    const {
        fetchMeta,
    } = MetaService()

    const {
        getParams,
        setParams,
    } = useFilters()

    const [repairAreas, setRepairAreas] = useState<MetaModel[]>([])
    const [damagedAreas, setDamagedAreas] = useState<MetaModel[]>([])
    const [repairTypes, setRepairTypes] = useState<MetaModel[]>([])
    const [importVisible, setImportVisible] = useState<boolean>(false)
    const [formVisible, setFormVisible] = useState<boolean>(false)
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false)
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false)
    const [selectedVersion, setSelectedVersion] = useState<RepairVerison>()
    const [selectedRepairItem, setSelectedRepairItem] = useState<Repair>()
    const [selectedColumns, setSelectedColumns] = useState<any[]>([])

    useEffect(() => {
        fetchCustomerRepairList(customerId)
        fetchMeta(ApiRoutes.REPAIR_AREAS, setRepairAreas, "container_repair_areas")
        fetchMeta(ApiRoutes.DAMAGED_AREAS, setDamagedAreas, "container_damaged_areas")
        fetchMeta(ApiRoutes.REPAIR_TYPES, setRepairTypes, "repair_types")
    }, [])

    useEffect(() => {
        if (customerVersions.length)
            setSelectedVersion(customerVersions.find(version => version.isActive))
    }, [customerVersions])

    useEffect(() => {
        if (selectedVersion?.id)
            fetchCustomerRepairListItems(selectedVersion?.id?.toString(), getCustomerRepairListFilterParams())
    }, [selectedVersion])

    useEffect(() => {
        setSelectedColumns(columns.filter(column => {
            let restrictedColumnKeys = []
            if (customer?.billingType === CustomerBillingTypes[0].value)
                restrictedColumnKeys = commonColumnKeys
            else
                restrictedColumnKeys = merskColumnKeys
            return !restrictedColumnKeys.includes(column.key)
        }))
    }, [customer])

    const availableFilters = [
        {
            title: "Repair Area",
            name: 'container_repair_area_id',
            options: repairAreas
        },
        {
            title: "Damaged Area",
            name: 'container_damaged_area_id',
            options: damagedAreas
        },
        {
            title: "Type",
            name: 'repair_type_id',
            options: repairTypes
        },
    ]

    const columns = [
        {
            title: 'Repair ID',
            dataIndex: 'repairUid',
            key: 'repairUid',
            width: 100,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Repair Area',
            dataIndex: ["containerRepairArea", 'name'],
            key: "containerRepairArea.name",
            width: 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Damaged Area',
            dataIndex: ["containerDamagedArea", 'name'],
            key: "containerDamagedArea.name",
            width: 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: ["repairType", 'name'],
            key: "repairType.name",
            width: 150,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Common hours',
            dataIndex: 'nonMearskHours',
            key: 'nonMearskHours',
            width: 100,
        },
        {
            title: 'Common mat. cost',
            dataIndex: 'nonMearskMaterialCostDollars',
            key: 'nonMearskMaterialCostDollars',
            width: 100,
        },
        {
            title: 'Merc+ hours/unit',
            dataIndex: 'mesrskHoursPerUnit',
            key: 'mesrskHoursPerUnit',
            width: 100,
        },
        {
            title: 'Merc+ mat. cost/unit',
            dataIndex: 'mearskUnitMaterialCostDollar',
            key: 'mearskUnitMaterialCostDollar',
            width: 100,
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            render: (text: string, record: any) => (
                <div className="table-actions">
                    <i className="icon-edit" onClick={(e) => {
                        e.stopPropagation();
                        editModeHandler(record)
                    }} />
                    <i className="icon-delete" onClick={(e) => {
                        e.stopPropagation()
                        deleteModeHandler(record)
                    }} />
                </div>
            )
        },
    ]

    const getCustomerRepairListFilterParams = () => {
        const filterNames = [
            'search_text',
            'page',
            ...availableFilters.map(filter => filter.name),
        ]
        return getParams(filterNames)
    }

    const editModeHandler = (record: any) => {
        setSelectedRepairItem(record)
        setFormVisible(true)
    }
    const deleteModeHandler = (record: any) => {
        setSelectedRepairItem(record)
        setDeleteVisible(true)
    }
    const deleteHandler = async () => {
        try {
            await deleteCustomerRepairItem(selectedRepairItem?.id || '')
            fetchCustomerRepairListItems(String(selectedVersion?.id) || '', getCustomerRepairListFilterParams())
            setSelectedRepairItem(undefined)
            setDeleteVisible(false)
        } catch (error) {

        }
    }
    const deleteCloseHandler = () => {
        setDeleteVisible(false)
        setSelectedRepairItem(undefined)
    }


    const exportHandler = () => {
        downloadCustomerRepairList(selectedVersion?.id || "")
    }
    const bulkUploadClickHandler = () => {
        setImportVisible(true)
    }

    const versionSelectHandler = (version: RepairVerison) => {
        setSelectedVersion(version)
    }

    const detailsModalCloseHandler = () => {
        setSelectedRepairItem(undefined)
        setDetailsVisible(false)
    }

    const detailsModalHandler = (record: Repair) => {
        setSelectedRepairItem(record)
        setDetailsVisible(true)
    }

    const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any; }) => {
        const sortOrders = [undefined, 'ascend']
        const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field[0] : undefined
        const params: any = {}
        params[sortFields[field]] = sortOrders.indexOf(sorter.order)
        params.page = pagination.current
        const repairListFilters = getCustomerRepairListFilterParams()
        const updatedFilters = { ...repairListFilters, page: params.page }
        setParams(updatedFilters)
        fetchCustomerRepairListItems(String(selectedVersion?.id) || '', { ...params, ...updatedFilters })
    };

    const filtersApplyHandler = (values: FormikValues) => {
        setParams(values)
        fetchCustomerRepairListItems(String(selectedVersion?.id) || "", {
            ...values,
        })
    }

    const setVersionAsDefaulHandler = async (closeModal: () => void) => {
        const data = await setVersionAsDefault(selectedVersion?.id + '', customerId)
        if (!data) return
        fetchCustomerRepairList(customerId)
        setSelectedVersion(undefined)
        closeModal()
    }

    return (
        <div className="customer-repair-list__container">
            <RepairListTableActions
                exportLoading={submitting}
                filters={availableFilters}
                filterValues={getCustomerRepairListFilterParams()}
                name="customer-repair-list-filters"
                versionList={customerVersions}
                activeVersion={selectedVersion}
                filtersApplyHandler={filtersApplyHandler}
                versionSelectHandler={versionSelectHandler}
                createNewVersionHandler={() => createCustomerRepairList(customerId)}
                exportHandler={exportHandler}
                confirmationModalLoading={loading}
                bulkUploadClickHandler={bulkUploadClickHandler}
                setVersionAsDefaulHandler={setVersionAsDefaulHandler}
            />

            <div className="customer-repair-list__table">
                <TableComponent
                    className="cursor-pointer"
                    handleRowClick={detailsModalHandler}
                    loading={loading}
                    columns={selectedColumns}
                    data={customerRepairItems}
                    onChange={handleTableChange}
                    pagination={{
                        pageSize: 20,
                        current: customerRepairItemsMeta.current || 1,
                        total: customerRepairItemsMeta.totalCount || 0,
                        showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                    }}
                />
            </div>
            <RepairImportForm
                isCustomerForm={true}
                visible={importVisible}
                versionId={String(selectedVersion?.id) || ""}
                closeHandler={(response?: true) => {
                    if (response)
                        fetchCustomerRepairListItems(String(selectedVersion?.id) || "", getCustomerRepairListFilterParams())
                    setImportVisible(false)
                }} />
            <RepairForm
                isCustomerForm={true}
                visible={formVisible}
                data={selectedRepairItem}
                versionId={String(selectedVersion?.id) || ""}
                closeHandler={(response?: true) => {
                    if (response) fetchCustomerRepairListItems(String(selectedVersion?.id) || "", getCustomerRepairListFilterParams())
                    setSelectedRepairItem(undefined)
                    setFormVisible(false)
                }} />
            <DeleteConfirmation
                visible={deleteVisible}
                title="Are you sure want to delete the repair"
                name={String(selectedRepairItem?.repairUid)}
                deleteHandler={deleteHandler}
                closeHandler={deleteCloseHandler}
                buttonLoading={submitting}
            />
            <RepairDetails
                billingType={customer?.billingType}
                visible={detailsVisible}
                repair={selectedRepairItem || {}}
                closeHandler={detailsModalCloseHandler}
            />
        </div>
    )
}

export default CustomerRepairList