import React, { FC } from 'react'
import "./tableComponent.scss"
import { Table, TablePaginationConfig } from "antd";
import TableLoader from '../TableLoader';
import { TableRowSelection } from 'antd/lib/table/interface';

interface TableComponentProps {
    columns: Array<any>;
    data: Array<object>;
    loading: boolean;
    handleRowClick?: Function;
    rowKey?: string | ((record: any) => string)
    rowClassName?: (record?: any) => "" | "selection-disabled"
    scroll?: any
    className?: string
    onChange?: (...data: any) => void
    pagination?: false | TablePaginationConfig
    rowSelection?: TableRowSelection<object>
    loadingCount?: number
    footer?: JSX.Element
    disableSelectAll?: boolean
}

const TableComponent: FC<TableComponentProps> = (props) => {
    const {
        columns: rawColumns,
        data,
        loading,
        handleRowClick,
        rowKey,
        className,
        scroll,
        onChange,
        pagination,
        rowSelection,
        loadingCount = 20,
        rowClassName = () => "",
        disableSelectAll,
        footer,
    } = props;

    const columns = rawColumns.map(col => {
        if (col?.key === 'actions' && col?.render && col.render())
            return {
                ...col,
                //width: 100,
                className: "table-hover-actions"
            }
        return col
    })

    return (
        <div className="table-component">
            <Table columns={columns}
                dataSource={loading ? [] : data}
                showSorterTooltip={false}
                className={`${className} ${disableSelectAll && 'disable-select-all'}`}
                pagination={pagination}
                rowKey={rowKey || "id"}
                rowClassName={(record) => rowClassName(record)}
                rowSelection={rowSelection}
                scroll={scroll}
                size="small"
                locale={{
                    emptyText: loading ? <TableLoader length={loadingCount} /> : null
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            if (handleRowClick) handleRowClick(record);
                        }
                    };
                }}
                onChange={onChange}
                footer={footer ? () => footer : undefined}
            />
        </div>
    )
}

export default TableComponent;