import { Col, message, Row, Tabs } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FormikValues } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { InvoiceEnum } from "../../enums/invoice.enum";
import { UserTypes } from "../../enums/userTypes.enum";
import { Activity } from "../../models/activity.model";
import { ActivityInvoice } from "../../models/invoice.model";
import { MetaModel } from "../../models/meta.model";
import { Pagination } from "../../models/pagination.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import ContainerService from "../../services/ContainerService/container.service";
import CustomerService from "../../services/CustomerService/customer.service";
import InvoiceService from "../../services/InvoiceService/invoice.service";
import MetaService from "../../services/MetaService/meta.service";
import AppHeader from "../../shared/components/AppHeader";
import ButtonComponent from "../../shared/components/ButtonComponent";
import CreateInvoiceButton from "../../shared/components/CreateInvoiceButton";
import TableComponent from "../../shared/components/TableComponent";
import TableSearchFilterComponent, { FilterData } from "../../shared/components/TableSearchFilterComponent";
import { ActivityTypes } from "../../shared/definitions/activity.definition";
import { InvoiceTypes } from "../../shared/definitions/invoice.definition";
import useFilters from "../../shared/hooks/useFilters";
import AuthContainer from "../../store/container/AuthContainer";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import "./invoices.scss"

const { TabPane } = Tabs

interface ConfigurationType {
    schema: ColumnsType<object>,
    filters: FilterData[]
    data: any[]
    meta: Pagination
}


const sortFields: any = {
    "customerId": "customer_id",
    "yardId": "yard_id",
    "yardName": "yard_name",
    "customer": "customer_name",
    "activityType": "activity_type_sort",
    "activityUid": "activity_uid_sort",
    // "createdAt": "created_at",
    "activityStatus": "activity_status_sort",
    "activityDate": "created_at"
}

const historySortFields: any = {
    "activity.yardName": "yard_name",
    "activity.customer": "customer_name",
    "activity.containerOwnerName": "owner_name",
    "activity.activityUid": "activity_uid",
    "activity.activityDate": "activity_date",
    "invoiceNumber": "invoice_number",
    "invoiceDate": "invoice_date_sort",
    "status": "activity_status_sort",
}

interface InvoicesProps extends AuthReducerProps { }
const Invoices = (props: InvoicesProps) => {

    const {
        user,
    } = props

    const history = useHistory()

    const [selectedRowKeys, setSelectedRows] = useState<string[]>([]) // ['invoiceUid-activityId']

    const [configuration, setConfiguration] = useState<ConfigurationType>()

    const [customerFilter, setCustomerFilter] = useState<number>()

    const [selectedCustomerBillingType, setSelectedCustomerBillingType] = useState<string>('')

    const [yards, setYards] = useState<MetaModel[]>([])

    const [customers, setCustomers] = useState<MetaModel[]>([])

    const [selectedTab, setSelectedTab] = useState<string>();

    const {
        loading,
        billableInvoices,
        fetchBillableInvoices,
        fetchBilledInvoices,
        billedInvoices,
        billableInvoiceMeta,
        createInvoices,
        billedInvoiceMeta,
        invoicesSubmitting,
    } = InvoiceService()

    const {
        fetchSingleCustomer,
    } = CustomerService()

    const {
        exportSubmitting,
        exportImagesSubmitting,
        exportCustomerPendingActivity,
        exportBulkImageActivity,
    } = ContainerService()

    const {
        getParams,
        setParams,
    } = useFilters()

    const {
        fetchMeta,
    } = MetaService()

    useEffect(() => {
        const tab = history.location.hash.replace("#", "")
        tabChangeHandler(tab || tabsList[user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase() ? 1 : 0].hash)
        fetchMeta(ApiRoutes.YARDS, setYards, 'yards')
        user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase() && fetchMeta(ApiRoutes.ALL_ACTIVE_CUSTOMERS, customerDataMapper, 'customers')
    }, [])

    useEffect(() => {
        fetchCustomerData()
    }, [customerFilter])

    const fetchCustomerData = async () => {
        if (!customerFilter || customerFilter === true as any) return
        const customer = await fetchSingleCustomer(String(customerFilter))
        setSelectedCustomerBillingType(customer?.billingType || '')
    }

    useEffect(() => {
        const filters = getInvoiceListFilterParams()
        fetchInvoices(filters)
        setCustomerFilter(filters.customer_id || user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase())
    }, [selectedTab])

    useEffect(() => {
        selectedTab && configurationModifier(selectedTab)
    }, [billableInvoices, billedInvoices, customers, yards, billableInvoiceMeta, billedInvoiceMeta])

    const fetchInvoices = (filters: any) => {
        if (selectedTab === "ready-for-billing")
            fetchBillableInvoices(filters)
        else if (selectedTab === "invoice-history" && user?.role)
            fetchBilledInvoices(filters, user?.role?.toLowerCase())
    }

    const tabsList = [
        {
            tab: "Ready for Billing",
            hash: 'ready-for-billing',
            disabled: user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase()
        },
        {
            tab: "Invoice History",
            hash: 'invoice-history',
        }
    ]

    const customerDataMapper = (customers: MetaModel[]) => {
        setCustomers(customers.map(customer => ({ value: customer.value, label: customer.customerName })))
    }

    const invoiceHistoryColumns = [
        {
            title: "Container No.",
            dataIndex: ["activity", "containerNumber"],
            key: "activity.containerNumber",
        },
        {
            title: "Yard",
            dataIndex: ["activity", "yardName"],
            key: "activity.yardName",
            sorter: true,
        },
        {
            title: "Customer",
            dataIndex: ["activity", "customer"],
            key: "activity.customer",
            sorter: true,
        },
        {
            title: "Owner Name",
            dataIndex: ["activity", "containerOwnerName"],
            key: "activity.containerOwnerName",
        },
        {
            title: "Activity ID",
            dataIndex: ["activity", "activityUid"],
            key: "activity.activityUid",
            sorter: true,
        },
        {
            title: "Activity Date",
            dataIndex: ["activity", "activityDate"],
            key: "activity.activityDate",
            sorter: true,
            render: (text: string) => <span>{text ? moment(text).format("DD MMM, YYYY") : ""}</span>,
        },
        {
            title: "Invoice No.",
            dataIndex: "invoiceNumber",
            key: "invoiceNumber",
            sorter: true,
        },
        {
            title: "Invoice Date",
            dataIndex: "invoiceDate",
            key: "invoiceDate",
            sorter: true,
            render: (text: string) => <span>{text ? moment(text).format("DD MMM, YYYY") : ""}</span>,
        },
        {
            title: "Total Cost",
            dataIndex: ["activity", "subtotal"],
            key: "activity.subtotal",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: true,
            render: (text: string) => <p className={`status ${text}`}>{text?.replaceAll('_', ' ')}</p>,
        },
    ]

    const readyForBillingColumns = [
        {
            title: "Container Number",
            dataIndex: "containerNumber",
            key: "containerNumber",
        },
        {
            title: "Yard",
            dataIndex: "yardName",
            key: "yardName",
            sorter: true,
        },
        {
            title: "Customer",
            dataIndex: "customer",
            key: "customer",
            sorter: true,
        },
        {
            title: "Owner Name",
            dataIndex: "containerOwnerName",
            key: "containerOwnerName",
        },
        {
            title: "Activity ID",
            dataIndex: "activityUid",
            key: "activityUid",
            sorter: true,
        },
        {
            title: "Activity",
            dataIndex: "activityType",
            key: "activityType",
            sorter: true,
            render: (text: string) => <span className="text-capitalize">{text}</span>,
        },
        {
            title: "Activity Date",
            dataIndex: "activityDate",
            key: "activityDate",
            sorter: true,
            render: (text: string) => <span>{text ? moment(text).format("DD MMM, YYYY") : ""}</span>,
        },
        {
            title: "Total Cost",
            dataIndex: "subtotal",
            key: "subtotal",
        },
    ]

    const invoiceHistoryFilters: FilterData[] = [{
        title: "Activity Date",
        name: "date",
        field: "date",
        options: [],
    },
    {
        title: "Activity",
        name: "activity_type",
        field: "select",
        options: ActivityTypes
    },
    {
        title: "Customer",
        name: "customer_id",
        field: "select",
        disabled: user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase(),
        options: customers,
    },
    {
        title: "Yard",
        name: "yard_id",
        field: "select",
        options: yards,
    },
    {
        title: "Invoice Date",
        name: "invoice_date",
        field: "date",
        options: [],
    },
    {
        title: "Invoice Status",
        name: "status",
        field: "select",
        options: InvoiceTypes,
    }]

    const readyForBillingFilters: FilterData[] = [{
        title: "Date",
        name: "date",
        field: "date",
        options: [],
    },
    {
        title: "Activity",
        name: "activity_type",
        field: "select",
        options: ActivityTypes,
    },
    {
        title: "Customer",
        name: "customer_id",
        field: "select",
        options: customers,
        disabled: user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase(),
    },
    {
        title: "Yard",
        name: "yard_id",
        field: "select",
        options: yards,
    }]

    const handleRowSelection = (selectedRowKeys: any[]) => {
        setSelectedRows(selectedRowKeys)
    }

    const exportHandler = (exportOption: "with_image" | "without_image") => {
        if (!noSelectionHandler() || !user?.role) return
        exportCustomerPendingActivity(
            selectedRowKeys.map(key => +key.split('-')[1]),
            user?.role?.toLowerCase(),
            exportOption
        )
    }

    const bulkImageExportHandler = (exportOption: "with_image" | "without_image") => {
        if (!noSelectionHandler() || !user?.role) return
        exportBulkImageActivity (
            selectedRowKeys.map(key => +key.split('-')[1]),
            user?.role?.toLowerCase(),
            exportOption
        )
    }

    const noSelectionHandler = () => {
        if (!selectedRowKeys.length || !(customerFilter || configuration?.data.length === 1)) {
            !customerFilter
                ? message.info("Apply customer filter to select invoices")
                : message.info("At least one item should be selected")
            return false
        }
        return true
    }

    const tabChangeHandler = (hash: string) => {
        setSelectedTab(hash);
        history.replace({
            pathname: history.location.pathname,
            search: history.location.search,
            hash
        })
        configurationModifier(hash)
    }

    const configurationModifier = (hash: string) => {
        if (hash === tabsList[0]?.hash)
            setConfiguration({
                schema: readyForBillingColumns,
                filters: readyForBillingFilters,
                data: billableInvoices,
                meta: billableInvoiceMeta
            })
        else
            setConfiguration({
                schema: invoiceHistoryColumns,
                filters: invoiceHistoryFilters,
                data: billedInvoices,
                meta: billedInvoiceMeta
            })
    }

    const redirectToInvoiceDetail = (activityInvoice: ActivityInvoice) => {
        if (!activityInvoice.invoiceId || tabsList[0].hash === selectedTab) return
        history.push(generatePath(AppRoutes.INVOICE, { invoiceId: activityInvoice.invoiceId }))
    }

    const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any; }) => {
        const sortOrders = [undefined, 'ascend']
        const params: any = {}
        if (selectedTab === 'invoice-history') {
            const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field.join('.') : undefined
            params[historySortFields[field]] = sortOrders.indexOf(sorter.order)
        } else {
            const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field[0] : undefined
            params[sortFields[field]] = sortOrders.indexOf(sorter.order)
        }
        params.page = pagination.current
        const repairListFilters = getInvoiceListFilterParams()
        const updatedFilters = { ...repairListFilters, page: params.page }
        setParams(updatedFilters)
        fetchInvoices({ ...params, ...updatedFilters })
    };


    const filtersApplyHandler = (values: FormikValues) => {
        const customerId = values["customer_id"]
        setParams(values)
        setCustomerFilter(customerId || user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase())
        fetchInvoices(values as any)
    }

    const getInvoiceListFilterParams = () => {
        const filterNames = [
            'search_text',
            'page',
            ...(configuration?.filters.map(filter => filter.name) || [])
            // ...Object.values(sortFields) as string[],
        ]
        return getParams(filterNames)
    }

    const createInvoicesHandler = async (mode: InvoiceEnum) => {
        if (!noSelectionHandler()) return
        const response = await createInvoices({
            activity_ids: selectedRowKeys.map(key => +key.split('-')[1]),
        }, mode)
        if (response)
            tabChangeHandler(tabsList[1].hash)
    }

    return <div className="invoices__container">
        <AppHeader
            title="Invoices"
        />
        <div className="invoices__tabs">
            <Tabs destroyInactiveTabPane
                onChange={tabChangeHandler}
                activeKey={selectedTab}
            >
                {tabsList.map(({ tab, hash, disabled }) => <TabPane
                    key={hash}
                    tab={tab}
                    disabled={disabled}
                />)}
            </Tabs>
        </div>
        <Row className="invoices__actions" gutter={[10, 0]} justify="space-between">
            <Col className="invoices__actions-filters">
                <TableSearchFilterComponent
                    changeTriggerItem={selectedTab}
                    name="invoice-list-filters"
                    placeholder="Search by container number"
                    filterHandler={filtersApplyHandler}
                    filters={configuration?.filters}
                    values={getInvoiceListFilterParams() || {}}
                />
                < ButtonComponent
                    className="export-btn"
                    iconClass="icon-export"
                    text="Export"
                    loading={exportSubmitting}
                    onClick={() => exportHandler("without_image")}
                />
                < ButtonComponent
                    className="image-export-btn"
                    iconClass="icon-export"
                    text="Export Images"
                    loading={exportImagesSubmitting}
                    onClick={() => bulkImageExportHandler("with_image")}
                />
            </Col>
            {tabsList[0].hash === selectedTab && <Col className="invoices__actions-invoice">
                <CreateInvoiceButton
                    primaryBtnLoading={invoicesSubmitting}
                    primaryBtnText="Create Invoice"
                    primaryBtnOnClick={() => createInvoicesHandler(InvoiceEnum.WITHOUT_IMAGES)}
                    dropdownButtons={[
                        {
                            text: "Create invoice with images",
                            onClick: () => createInvoicesHandler(InvoiceEnum.WITH_IMAGES)
                        }
                    ]}
                />
            </Col>}
        </Row>
        <div className="invocies__list">
            <TableComponent
                handleRowClick={redirectToInvoiceDetail}
                loading={loading}
                data={configuration?.data || []}
                className={`${tabsList[1].hash === selectedTab && "cursor-pointer"}`}
                onChange={handleTableChange}
                rowKey={(record: any) => tabsList[1].hash === selectedTab
                    ? `${record?.invoiceNumber || record?.activity?.activityUid || ''}-${record?.activity?.id || ''}`
                    : `${record?.activityUid || ''}-${record?.id || ''}`}
                rowSelection={(customerFilter || configuration?.data.length === 1)
                    ? {
                        selectedRowKeys,
                        onChange: handleRowSelection,
                    }
                    : undefined
                }
                pagination={{
                    pageSize: 20,
                    current: configuration?.meta?.current || 1,
                    total: configuration?.meta?.totalCount || 0,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
                columns={configuration?.schema || []}
            />
        </div>
    </div >
}

export default AuthContainer(Invoices)