import { Row, Col } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Activity, ActivityItems } from "../../../models/activity.model";
import ContainerFormBodyActions from "./ContainerFormBodyActions";
import "./containerFormBody.scss"
import TableComponent from "../TableComponent";
import ContainerItemForm from "../../../views/Containers/ContainerDetail/ContainerItemForm";
import { Attachment, Container } from "../../../models/container.model";
import ContainerCommentForm from "./ContainerCommentForm";
import DeleteConfirmation from "../DeleteConfirmation";
import ContainerService from "../../../services/ContainerService/container.service";
import CustomerService from "../../../services/CustomerService/customer.service";
import ContainersContainer from "../../../store/container/ContainersContainer";
import { ContainerState } from "../../../store/reducers/containerReducer";
import AppCarousel from "../ContainerCarousel";
import { ActivityStatusEnum } from "../../../enums/activity.enum";
import { ActivityAttachmentEnum } from "../../../enums/containerAttachment.enum";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserTypes } from "../../../enums/userTypes.enum";

interface ContainerFormBodyProps extends ContainerState, AuthReducerProps {
    loading: boolean
    refreshListHandler: (options: {
        container?: boolean,
        activities?: boolean,
        activityItems?: number,
    }) => void
}

const ContainerFormBody: FC<ContainerFormBodyProps> = (props) => {
    const {
        activity,
        loading,
        activityItems,
        refreshListHandler,
        user,
    } = props

    const {
        submitting,
        deleteActivityItem,
        downloadActivityItemsImages,
    } = ContainerService()

    const [itemFormVisible, setItemFormVisible] = useState<boolean>(false)

    const [carouselVisible, setCarouselVisible] = useState<boolean>(false)

    const [commentFormVisible, setCommentFormVisible] = useState<boolean>(false)

    const [deleteVisible, setDeleteVisible] = useState<boolean>(false)

    const [selectedContainerItem, setSelectedContainerItem] = useState<ActivityItems>()

    const columns = [
        {
            title: "Repair ID",
            key: "repairCode",
            dataIndex: "repairCode"
        },
        {
            title: "Repair Area",
            key: "repairArea",
            dataIndex: ["repairArea", "name"]
        },
        {
            title: "Damage Area",
            key: "damagedArea",
            dataIndex: ["damagedArea", "name"]
        },
        {
            title: "Type",
            key: "repairType",
            dataIndex: ["repairType", "name"]
        },
        {
            title: "Quantity",
            key: "unit",
            dataIndex: ["unit", "name"]
        },
        {
            title: "Location",
            key: "location",
            dataIndex: "location"
        },
        {
            title: "Hours",
            key: "hours",
            dataIndex: "hours"
        },
        {
            title: "Labour cost",
            key: "labourCostDollars",
            dataIndex: "labourCostDollars"
        },
        {
            title: "Material cost",
            key: "materialCostDollars",
            dataIndex: "materialCostDollars"
        },
        {
            title: "Total cost",
            key: "totalCostDollars",
            dataIndex: "totalCostDollars"
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
            width: 50,
            render: (_: null, record: ActivityItems) => (
                <div className="table-actions">
                    <i className={`icon-comment${!!record?.comments?.length ? ' comment_green' : ''}`} onClick={(e) => {
                        e.stopPropagation();
                        setSelectedContainerItem(record)
                        openContainerCommentForm()
                    }} />
                    {/* {(!!record?.comments?.length) && <i className="icon-comment" onClick={(e) => {
                        e.stopPropagation();
                        setSelectedContainerItem(record)
                        openContainerCommentForm()
                    }} />} */}
                    <i className={`icon-image${!!record?.attachments?.length ? ' comment_green' : ''}`}onClick={(e) => {
                        e.stopPropagation();
                        setSelectedContainerItem(record)
                        openCarousel()
                    }} />
                    {/* {(!!record?.attachments?.length) && <i className="icon-image" onClick={(e) => {
                        e.stopPropagation();
                        setSelectedContainerItem(record)
                        openCarousel()
                    }} />} */}
                    {
                        activity.activityStatus &&
                        user?.role?.toLowerCase() === UserTypes.ADMIN?.toLowerCase() &&
                        (activity.activityStatus === ActivityStatusEnum.QUOTE_DRAFT ||
                            activity.activityStatus === ActivityStatusEnum.QUOTE_ISSUED ||
                            activity.activityStatus === ActivityStatusEnum.REPAIR_DRAFT ||
                            activity.activityStatus === ActivityStatusEnum.VOID ||
                            activity.activityStatus === ActivityStatusEnum.PENDING_ADMIN_APPROVAL) &&
                        <>
                            <i className="icon-delete" onClick={(e) => {
                                e.stopPropagation()
                                setSelectedContainerItem(record)
                                openDeleteHandler()
                            }} />
                             <i className="icon-edit" onClick={(e) => {
                                e.stopPropagation();
                                setSelectedContainerItem(record)
                                openContainerItemForm()
                            }} />
                        </>
                    }

                </div>
            )
        }
    ]

    const openContainerItemForm = () => setItemFormVisible(true)

    const openCarousel = () => setCarouselVisible(true)

    const closeCarousel = () => {
        setCarouselVisible(false)
        setSelectedContainerItem(undefined)
    }

    const openContainerCommentForm = () => setCommentFormVisible(true)

    const openDeleteHandler = () => setDeleteVisible(true)

    const closeContainerItemForm = (success?: boolean) => {
        setSelectedContainerItem(undefined)
        setItemFormVisible(false)
        if (success)
            refreshListHandler({
                activityItems: activity.id
            })
    }

    const closeContainerCommentForm = (success?: boolean) => {
        setSelectedContainerItem(undefined)
        setCommentFormVisible(false)
        if (success)
            refreshListHandler({
                activityItems: activity?.id
            })
    }

    const closeDeleteHandler = (success?: boolean) => {
        setSelectedContainerItem(undefined)
        setDeleteVisible(false)
        if (success)
            refreshListHandler({
                activityItems: activity?.id
            })
    }

    const deleteHandler = async () => {
        if (!selectedContainerItem?.id || !activity.id) return
        try {
            await deleteActivityItem(activity.id, selectedContainerItem?.id)
            closeDeleteHandler(true)
        } catch (error) {

        }
    }

    const downloadHandler = () => {
        if (!selectedContainerItem?.id || !activity.id || !user?.role) return
        downloadActivityItemsImages(user?.role?.toLowerCase(), activity.id, selectedContainerItem?.id)
    }

    // const getImagesFromContainerItem = (activityItem?: ActivityItems) => {
    //     const attachments: Attachment[] = []
    //     if (activityItem?.attachments)
    //         attachments.push({
    //             ...activityItem?.damagedAreaImage,
    //             attachmentType: ActivityAttachmentEnum.DAMAGE_AREA
    //         })
    //     if (activityItem?.repairedAreaImage)
    //         attachments.push({
    //             ...activityItem?.repairedAreaImage,
    //             attachmentType: ActivityAttachmentEnum.REPAIR_AREA
    //         })
    //     return attachments
    // }

    return <Row className={`container-body__container ${activity?.activityType}`}>
        <Col span={24}>
            <ContainerFormBodyActions
                loading={loading}
                addItemClickHandler={openContainerItemForm}
                refreshListHandler={refreshListHandler}
                disableUpdate={activityItems.length === 0}
            />
        </Col>
        <Col span={24}>
            <TableComponent
                columns={columns}
                data={activityItems}
                loading={loading}
                loadingCount={5}
                pagination={false}
            />
        </Col>
        {!!activity?.subtotal && <Col span={24} className="table-footer">
            <span>Subtotal: ${activity?.subtotal}</span>
        </Col>}
        <ContainerItemForm
            activityId={activity?.id}
            data={selectedContainerItem}
            visible={itemFormVisible}
            closeHandler={closeContainerItemForm}
        />
        <ContainerCommentForm
            activity={activity}
            visible={commentFormVisible}
            data={selectedContainerItem}
            closeHandler={closeContainerCommentForm}
        />
        <DeleteConfirmation
            visible={deleteVisible}
            title="Are you sure to delete the item?"
            name=''
            deleteHandler={deleteHandler}
            closeHandler={() => closeDeleteHandler()}
            buttonLoading={submitting}
        />
        {carouselVisible && <AppCarousel
            loading={submitting}
            attachments={selectedContainerItem?.attachments || []}
            title={selectedContainerItem?.repairCode || ''}
            closeHandler={closeCarousel}
            downloadHandler={downloadHandler}
        />}
    </Row>
}

export default ContainersContainer(AuthContainer(ContainerFormBody))