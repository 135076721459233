import { generatePath } from 'react-router';
import { Profile } from './../../models/profile.model';
import { useState } from 'react';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import { deserialize, serialize } from 'serializr';
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { UserTypes } from '../../enums/userTypes.enum';

const UserService = () => {

    const [users, setUsers] = useState<Profile[]>([])

    const [loading, setLoading] = useState<boolean>(false)

    const [submitting, setSubmitting] = useState<boolean>(false)

    const fetchAdminUsers = async (params: {
        role: UserTypes,
        search_text: string,
    }) => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(ApiRoutes.PROFILES, { params })
            const data = deserialize(Profile, response.data['admins']) as Profile[]
            setUsers(data)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const createAdminUser = async (profile: Profile) => {
        setSubmitting(true)
        try {
            const payload = serialize(Profile, profile)
            const response = await axiosInstance.post(ApiRoutes.PROFILES, payload)
            Notification({
                message: "",
                description: "User created successfully",
                type: NotificationTypes.SUCCESS,
            });
            return response
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }

    const updateAdminUser = async (profile: Profile) => {
        setSubmitting(true)
        try {
            const payload = serialize(Profile, profile)
            const API_URL = generatePath(ApiRoutes.PROFILES_SPECIFIC, { profileId: profile.id })
            const response = await axiosInstance.put(API_URL, payload)
            Notification({
                message: "",
                description: "User edited successfully",
                type: NotificationTypes.SUCCESS,
            });
            return response
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }

    const updateCustomerUser = async (profile: Profile) => {
        setSubmitting(true)
        try {
            const payload = serialize(Profile, profile)
            const API_URL = generatePath(ApiRoutes.CUSTOMER_PROFILES_SPECIFIC, { profileId: profile.id })
            const response = await axiosInstance.put(API_URL, payload)
            Notification({
                message: "",
                description: "User edited successfully",
                type: NotificationTypes.SUCCESS,
            });
            return response
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }

    const deleteAdminUser = async (profile: Profile) => {
        setSubmitting(true)
        try {
            const API_URL = generatePath(ApiRoutes.PROFILES_SPECIFIC, { profileId: profile.id })
            const response = await axiosInstance.delete(API_URL)
            Notification({
                message: "",
                description: "User deleted successfully",
                type: NotificationTypes.SUCCESS,
            });
            return response
        } catch (error) {

        } finally {
            setSubmitting(false)
        }
    }

    return {
        users,
        loading,
        submitting,
        fetchAdminUsers,
        createAdminUser,
        updateAdminUser,
        deleteAdminUser,
        updateCustomerUser,
    }
}

export default UserService