import React, { FC } from 'react'
import "./deleteConfirmation.scss"
import { Modal } from "antd";
import ButtonComponent from "../ButtonComponent";

interface DeleteConfirmationProps {
    visible: boolean;
    title: string;
    name?: string;
    closeHandler: () => void
    deleteHandler: () => void;
    buttonLoading: boolean;
}

const DeleteConfirmation: FC<DeleteConfirmationProps> = (props) => {

    const { title, visible, name, deleteHandler, buttonLoading, closeHandler } = props;

    return (
        <Modal title={null}
            footer={null}
            visible={visible}
            closeIcon={false}
            closable={false}
            onCancel={closeHandler}
            className="delete-confirmation"
        >
            <div className="delete-confirmation--img ">
                <i className="icon-delete"></i>
            </div>
            <div className="delete-confirmation__content">{title} {name ? <>- <span>{name}</span> ?</> : <span></span>}</div>
            <p className="delete-confirmation--hint">You can't undo this action</p>
            <div className="delete-confirmation__actions">
                <ButtonComponent className='delete-confirmation__action'
                    type="text"
                    text="Cancel"
                    onClick={closeHandler}
                />
                <ButtonComponent loading={buttonLoading}
                    className='delete-confirmation__action'
                    type="primary"
                    text="Confirm"
                    onClick={deleteHandler}
                />
            </div>
        </Modal>
    )
}

export default DeleteConfirmation;