import axios from 'axios';
import { store } from "../store";
import { REQUEST_LOGOUT } from "../store/definitions/authConstants";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import * as AppRoutes from '../routes/routeConstants/appRoutes';
import LocalStorage from '../shared/LocalStorage';
import { appHistory } from '../routes';

export const getHeaders = (): any => {
    let headers;
    if (LocalStorage.getItem('user')) {
        const token = LocalStorage.getItem('access-token')
        headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
        };
    }
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});
axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response?.data,
            message: response?.statusText,
            status: response?.status,
        };
    },
    (error) => {
        const { response } = error;
        if (response?.status === 401) {
            Notification({
                type: NotificationTypes.ERROR,
                message: "Unauthorized",
                description: response?.data?.error
            });
            LocalStorage.clear()
            setTimeout(() => {
                window.location.replace(AppRoutes.AUTH)
                // appHistory.push(AppRoutes.LOGIN)
            })
            store.dispatch({ type: REQUEST_LOGOUT, payload: "Request for logout" });
            return Promise.reject(error);
        }
        const errorMsg = response.data?.exception || response?.data?.error
        Notification({
            type: NotificationTypes.ERROR,
            message: "",
            description: errorMsg || "Something went wrong. Please try again",
        });

        return Promise.reject(error);
    }
);

export default axiosInstance;
