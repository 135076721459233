import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./LoginValidation"
import AuthService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import '../auth.scss';
import { DASHBOARD, FORGOT_PASSWORD } from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { store } from "../../../store";
import { AUTHENTICATED } from "../../../store/definitions/authConstants";

interface User {
    email: string,
    password: string
}

const initialValue = {
    email: "",
    password: ""
}

const LoginForm = () => {

    const { user, error, loading, loginUser } = AuthService();

    const history = useHistory();

    const onSubmit = (user: User) => {
        loginUser({
            ...user,
            grant_type: 'password'
        })
    }

    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className="auth-form__header">
                        Login to admin portal
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">Email</div>
                        <InputField type="email"
                            name="email"
                            placeholder="Enter email"
                            prefix={<span className="icon-mail green-icon" />}
                        />
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">Password</div>
                        <InputField type="password"
                            name="password"
                            placeholder="Enter password"
                            prefix={<span className="icon-password green-icon" />}
                        />
                    </div>

                    <ButtonComponent htmlType="submit"
                        loading={loading}
                        className='auth-form__action'
                        type="primary"
                        text="Login"
                    />

                    <div className="auth-form__forgot">
                        <div onClick={() => history.push(FORGOT_PASSWORD)}>Forgot password?</div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(LoginForm);
