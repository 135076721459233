import { Col, Row } from 'antd'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { ContainerComment } from '../../../../../models/containerComment.model'
import "./containerCommentCard.scss"

interface ContainerCommentCardProps {
    data: ContainerComment
    color: string
}

const ContainerCommentCard: FC<ContainerCommentCardProps> = (props) => {

    const {
        data,
        color,
    } = props

    const [expanded, setExpanded] = useState<boolean>(false)

    const expandToggle = () => setExpanded(prev => !prev)

    const hideDesc = () => setExpanded(false)

    return (
        <div className='container-comment-card'>
            <Row className='container-comment-card__header'>
                <Col>
                    <p
                        style={{
                            color,
                            background: color + "10"
                        }}
                        className='container-comment-card__header--pic'>{data.commentedBy?.name && data.commentedBy?.name[0]}{data.commentedBy?.name?.split(" ")[1] && data.commentedBy?.name?.split(" ")[1][0]}</p>
                </Col>
                <Col className='container-comment-card__header--name'>
                    <p>{data.commentedBy?.name}</p>
                    <p className='date'>{moment(data.createdAt).format("DD, MMM YYYY")}</p>
                </Col>
            </Row>
            <div className={`container-comment-card--desc ${!expanded && 'truncate'}`}>
                <p onClick={expandToggle}>{data.comment}</p>
                {/* {!expanded && <span className='see-more' onClick={expandToggle}>See More</span>} */}
            </div>
        </div>
    )
}

export default ContainerCommentCard
