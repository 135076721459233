import { ActivityAttachmentEnum, ContainerAttachmentEnum } from './../enums/containerAttachment.enum';
import { serializable, alias, primitive, object, list } from 'serializr';
import { Customer } from './customer.model';

export class Attachment {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('attachment_type', primitive()))
    attachmentType?: ContainerAttachmentEnum | ActivityAttachmentEnum;

    @serializable(alias('attachment_url', primitive()))
    attachmentUrl?: string;

    @serializable(alias('attachment_id', primitive()))
    attachmentId?: string;
}

export class MetaData {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

export class Container {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('yard_id', primitive()))
    yardId?: number;

    @serializable(alias('yard', object(MetaData)))
    yard?: MetaData;

    @serializable(alias('container_uid', primitive()))
    containerNumber?: string;

    @serializable(alias('customer_id', primitive()))
    customerId?: number;

    @serializable(alias('customer', object(Customer)))
    customer?: Customer;

    @serializable(alias('container_owner_name', primitive()))
    containerOwnerName?: string;

    @serializable(alias('submitter_initials', primitive()))
    submitterInitials?: string;

    @serializable(alias('container_length', object(MetaData)))
    containerLength?: MetaData;

    @serializable(alias('container_height', object(MetaData)))
    containerHeight?: MetaData;

    @serializable(alias('container_length_id', primitive()))
    containerLengthId?: number;

    @serializable(alias('container_height_id', primitive()))
    containerHeightId?: number;

    @serializable(alias('container_type_id', primitive()))
    containerTypeId?: number;

    @serializable(alias('container_type', object(MetaData)))
    containerType?: MetaData;

    @serializable(alias('manufacture_year', primitive()))
    manufactureYear?: string;

    @serializable(alias('location', primitive()))
    location?: string | null = null;

    @serializable(alias('comments', primitive()))
    comments?: string = '';

    @serializable(alias('container_status', primitive()))
    containerStatus?: 'idle' | 'active';

    @serializable(alias('container_attachments', list(object(Attachment))))
    containerAttachments?: Attachment[];
}

export class ContainerNavigation {

    @serializable(alias('next_id', primitive()))
    nextId?: number;

    @serializable(alias('position', primitive()))
    position?: number;

    @serializable(alias('previous_id', primitive()))
    previousId?: number;

    @serializable(alias('total_count', primitive()))
    totalCount?: number;
}