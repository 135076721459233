import React, { FC } from "react";
import "./createInvoiceButton.scss"
import { Dropdown, Menu } from "antd";
import ButtonComponent from "../ButtonComponent";

interface DropdownButtonProps {
    text: string,
    onClick: () => void
}

interface CreateInvoiceButtonProps {
    buttonType?: "link" | "text" | "ghost" | "default" | "primary" | "dashed"
    primaryBtnText: string
    primaryBtnOnClick: () => void
    primaryBtnLoading: boolean
    iconClass?: string
    dropdownButtons?: DropdownButtonProps[]
}

const Item = Menu.Item

const CreateInvoiceButton: FC<CreateInvoiceButtonProps> = (props) => {

    const {
        buttonType = "primary",
        primaryBtnText,
        primaryBtnOnClick,
        dropdownButtons = [],
        primaryBtnLoading,
        iconClass = "icon-invoice"
    } = props

    const dropdownBtnMenu = <Menu>
        {dropdownButtons.map(btn => <Item className={buttonType} onClick={btn.onClick}>{btn.text}</Item>)}
    </Menu>

    return <div className={`double-btn__container ${!dropdownButtons.length && 'single-btn'}`}>
        <ButtonComponent
            suffix={
                dropdownButtons.length
                    ? <Dropdown
                        trigger={["click"]}
                        overlay={dropdownBtnMenu}
                        placement="bottomRight"
                        overlayClassName={`activity-dropdown ${buttonType}`}
                    >
                        <ButtonComponent
                            iconClass="icon-dropdown"
                            className="suffix-btn"
                            type={buttonType}
                        />
                    </Dropdown>
                    : <span />
            }
            onClick={primaryBtnOnClick}
            iconClass={iconClass}
            type={buttonType}
            loading={primaryBtnLoading}
            text={primaryBtnText}
        />
    </div>
}

export default CreateInvoiceButton