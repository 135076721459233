import { serializable, alias, primitive } from 'serializr';

export class ReportModel {
    @serializable(alias('container_number', primitive()))
    containerNumber?: string | null = null;

    @serializable(alias('customer_id', primitive()))
    customerId?: string | null = null;

    @serializable(alias('start_date', primitive()))
    fromDate?: string;

    @serializable(alias('end_date', primitive()))
    toDate?: string;

}

