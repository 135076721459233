import { User } from './user.model';
import { serializable, alias, primitive, object } from 'serializr';


export class ContainerComment {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('comment_text', primitive()))
    comment?: string;

    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('user', object(User)))
    commentedBy?: User;
}
