import { Col, Row, Tabs } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MetaModel } from '../../models/meta.model';
import { ReportModel } from '../../models/report.model';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import MetaService from '../../services/MetaService/meta.service';
import ReportService from '../../services/ReportsService/report.service';
import AppHeader from '../../shared/components/AppHeader';
import ButtonComponent from '../../shared/components/ButtonComponent';
import DatePickerComponent from '../../shared/components/DatePickerComponent';
import DropdownField from '../../shared/components/DropdownField';
import InputField from '../../shared/components/InputField';
import InfoIcon from "./../../assets/Multicolor icons - SVG/info.svg";
import { reportValidationSchema } from "./reportForm.validation";
import "./reports.scss";
const { TabPane } = Tabs;

const Reports = () => {


    const {
        fetchMeta,
    } = MetaService()
    const {
        loading,
        downloadReports,
    } = ReportService()

    const [customers, setCustomers] = useState<MetaModel[]>([])

    useEffect(() => {
        fetchMeta(ApiRoutes.ALL_ACTIVE_CUSTOMERS, customerDataMapper, 'customers')
    }, [])

    const customerDataMapper = (customers: MetaModel[]) => {
        setCustomers(customers.map(customer => ({ value: customer.value, label: customer.customerName })))
    }
    const submitHandler = async (values: ReportModel, helpers: FormikHelpers<ReportModel>) => {
        const response = await downloadReports({
            ...values,
            fromDate: moment(values.fromDate).format("DD-MMM-YYYY"),
            toDate: moment(values.toDate).format("DD-MMM-YYYY"),
        })
        response && helpers.resetForm()
    }

    const containerNumberPrepopulation = (value: string) => {
        let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
        if (formattedValue.length > 4)
            formattedValue = formattedValue.substr(0, 4) + " " + formattedValue.substr(4)
        if (formattedValue.length > 11)
            formattedValue = formattedValue.substr(0, 11) + "-" + formattedValue.substr(11)
        if (formattedValue.length > 13)
            return formattedValue.substr(0, 13).toUpperCase()
        return formattedValue.toUpperCase()
    }

    //Container UID Change - remove space and dash
    // const containerNumberPrepopulation = (value: string) => {
    //     let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
    //     if (formattedValue.length > 4)
    //         //formattedValue = formattedValue.substr(0, 4) + " " + formattedValue.substr(4)
    //     if (formattedValue.length > 11)
    //         //formattedValue = formattedValue.substr(0, 11) + "-" + formattedValue.substr(11)
    //     if (formattedValue.length > 11)
    //         return formattedValue.substr(0, 11).toUpperCase()
    //     return formattedValue.toUpperCase()
    // }


    return <div className='reports__wrapper'>
        <AppHeader
            title="Reports"
            addButton={false}
        />
        <div className="reports__tabs">
            <Tabs destroyInactiveTabPane>
                <TabPane key={'activity'} tab={'Activity Report'} />
                {/* <TabPane key={'invoice'} tab={'Invoice'} disabled /> */}
            </Tabs>
            <div className="reports__content">
                <Formik
                    initialValues={new ReportModel()}
                    onSubmit={submitHandler}
                    validationSchema={reportValidationSchema}>
                    {({ values, setFieldValue, resetForm, errors }) => {
                        return <Form>
                            <Row gutter={[16, 16]}>
                                {/* <Col span={24} className="form-hint text-dark-grey">
                                    <img src={InfoIcon} alt="info" />
                                    <span>Either container number or customer is required to generate report</span>
                                </Col> */}
                                <Col span={8}>
                                    <DropdownField
                                        title={'Customer '}
                                        name='customerId'
                                        options={customers}
                                        value={values.customerId}
                                        onChange={(value) => {
                                            setFieldValue("containerNumber", null);
                                            setFieldValue('customerId', value)
                                        }}
                                    />
                                </Col>
                                <Col span={12}></Col>
                                <Col span={8}>
                                    <div className="form-label">Container Number</div>
                                    <InputField
                                        type="text"
                                        name="containerNumber"
                                        placeholder="ABCD 123456-1" //"ABCD1234561"
                                        value={values.containerNumber || ''}
                                        onChange={(e) => {
                                            setFieldValue("containerNumber", containerNumberPrepopulation(e.target.value));
                                            setFieldValue('customerId', null)
                                        }}
                                    />
                                </Col>
                                <Col span={12}></Col>
                                <Col span={8}>
                                    <DatePickerComponent
                                        name={'fromDate'}
                                        title='Repair Date Range (required field)'
                                        range={true}
                                        rangeValue={[
                                            values.fromDate ? moment(values.fromDate) : null as any,
                                            values.toDate ? moment(values.toDate) : null as any,
                                        ]}
                                        rangePlaceHolder={['Repair From Date', 'Repair To Date']}
                                        onChange={((val, stringArray) => {
                                            setFieldValue("fromDate", stringArray[0] || null);
                                            setFieldValue("toDate", stringArray[1] || null)
                                        })} />
                                </Col>
                                <Col span={12}></Col>
                                <Col span={8} className='report__actions'>
                                    <ButtonComponent
                                        className="secondary"
                                        text="Reset"
                                        onClick={() => resetForm()} />
                                    <ButtonComponent
                                        type='primary'
                                        loading={loading}
                                        disabled={(!values.fromDate || !values.toDate)}
                                        htmlType='submit'
                                        text="Run Report" />
                                </Col>
                                {/* <Col span={8} >
                                    <div className="form-label">Container Owner Name</div>
                                    <InputField
                                        type="text"
                                        name="ownerName"
                                        placeholder="Enter"
                                        value={values.ownerName}
                                    />
                                </Col> */}
                                {/* <Col span={8}>
                                    <DropdownField
                                        title={'Yard Name'}
                                        name='yardId'
                                        options={yards}
                                        value={values.yardId}
                                        onChange={(value) => {
                                            setFieldValue('yardId', value)
                                        }}
                                    />
                                </Col> */}
                            </Row>
                        </Form>
                    }}
                </Formik>
            </div>
        </div>
    </div>
};

export default Reports;
