import { Formik, Form, Field, FormikValues, FormikHelpers } from "formik";
import React, { FC, Fragment, useRef } from "react";
import { AttachmentModel } from "../../../models/meta.model";
import CustomerService from "../../../services/CustomerService/customer.service";
import RepairService from "../../../services/RepairService/repair.service";
import { AttachmentComponent } from "../../../shared/components/AttachmentComponent";
import UIModal from "../../../shared/components/UIModal";

interface RepairImportFormProps {
    visible: boolean
    isCustomerForm?: boolean
    closeHandler: (response?: true) => void
    versionId: string
}

const RepairImportForm: FC<RepairImportFormProps> = (props) => {
    const {
        visible,
        closeHandler,
        versionId,
        isCustomerForm = false,
    } = props

    const {
        uploadRepairList,
        submitting: adminSubmitting,
    } = RepairService()
    const {
        uploadCustomerRepairList,
        submitting: customerSubmitting,
    } = CustomerService()


    const formikRef = useRef<any>(null)

    const submitHandler = async (values: AttachmentModel, helpers: FormikHelpers<any>) => {
        if (!values.id) return
        try {
            if (isCustomerForm)
                await uploadCustomerRepairList(values.id, +versionId)
            else
                await uploadRepairList(values.id, +versionId)
            closeHandler(true)
        } catch (error) {
            console.log(error);
        }
    }

    return <UIModal
        visible={visible}
        title="Bulk Upload Repair List"
        subtitle={
            <Fragment>
                <p className="icon-alert"></p>
                <span>Append the new list with existing list and upload</span>
            </Fragment>}
        okText="Proceed"
        onOk={() => formikRef.current?.submitForm()}
        onCancel={() => closeHandler()}
        loading={adminSubmitting || customerSubmitting}
    >
        <Formik
            initialValues={new AttachmentModel()}
            onSubmit={submitHandler}
            enableReinitialize
            innerRef={formikRef}
        >
            {({ setFieldValue, values, resetForm, isValid }) => {
                return (
                    <Form>
                        <div className="company-form__field">
                            <Field name="companyLogo" as="file-upload">
                                <AttachmentComponent
                                    images={values.id ? [values] : undefined}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onUpload={(response) => {
                                        response && setFieldValue("id", response[0]?.id)
                                        response && setFieldValue("name", response[0]?.name)
                                    }}
                                    onRemove={() => {
                                        setFieldValue("id", undefined)
                                        setFieldValue("name", undefined)
                                    }}
                                />
                            </Field>
                        </div>
                    </Form>
                );
            }}
        </Formik>

    </UIModal>

}

export default RepairImportForm