import { REGEX } from './../../../shared/utils/regex';
import * as Yup from "yup";
import axiosInstance from '../../../interceptor/axiosInstance';
import { ApiRoutes } from '../../../routes/routeConstants/apiRoutes';

export const containerValidationSchema = Yup.object().shape({
    yardId: Yup.number()
        .required('Yard name is required!'),
    containerNumber: Yup.string()
        .matches(REGEX.CONTAINER_NUMBER, 'Container Number should be valid')
        .required('Container number is required!')
        .test('containerNumber', 'Container Number should be valid!', function (value) {
            //if (value?.length !== 11) return true //without space and dash
            if (value?.length !== 13) return true //with space and dash
            return new Promise((resolve, reject) => {
                const params = { container_uid: value }
                axiosInstance.post(ApiRoutes.CONTAINER_NUMBER_VALIDATION, params, { params }).then((response) => {
                    resolve(response.data.result)
                }).catch(() => {
                    resolve(false)
                })
            })
        }),
    customerId: Yup.number()
        .required('Customer is required!'),
    containerOwnerName: Yup.string()
        .required('Customer owner name is required!'),
    submitterInitials: Yup.string()
        .required('Submitter Initials is required!'),
    containerLengthId: Yup.number()
        .required('Container length is required!'),
    containerHeightId: Yup.number()
        .required('Container height is required!'),
    containerTypeId: Yup.string()
        .required('Container type is required!'),
    manufactureYear: Yup.string()
        .test("manufactureYear", 'Container manufacture year should be valid!', (value: any) => +value <= new Date().getFullYear())
        .required('Container manufacture year is required!'),
    location: Yup.string()
        .nullable()
        .matches(REGEX.CONTAINER_LOCATION, 'Location should be valid!'),
    comments: Yup.string()
        .nullable(),
    // containerAttachments: Yup.array()
    //     .of(
    //         Yup.object({
    //             attachmentId: Yup.number().required(" is required"),
    //         })
    //     )
})
