import { Row, Col } from "antd";
import React, {useState} from "react";
import { Container, ContainerNavigation } from "../../../models/container.model";
import "./containerHeader.scss"
import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";

interface ContainerHeaderProps {
    container?: Container
    navigation?: ContainerNavigation
    editClickHandler: () => void
    editVisible: boolean
    carouselTriggerClickHandler: () => void
}

const ContainerHeader = (props: ContainerHeaderProps) => {

    const history = useHistory()

    const {
        editVisible,
        container,
        navigation,
        editClickHandler,
        carouselTriggerClickHandler,
    } = props

    const [selectedContainerItem, setSelectedContainerItem] = useState<Container>()

    const [commentFormVisible, setCommentFormVisible] = useState<boolean>(false)


    const navigateBackHandler = () => {
        history.goBack()
    }

    const containerNavigationHandler = (containerId?: number) => {
        if (!containerId) return
        history.push(AppRoutes.CONTAINER.replace(":containerId", String(containerId)))
    }

    const openContainerCommentForm = () => setCommentFormVisible(true)

    
    const closeContainerCommentForm = (success?: boolean) => { 
        setCommentFormVisible(false)
    }
    //function to remove spaces and dashes from Container Number
    // const containerNumberFormatting = (value: string) => {
    //     let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
    //     return formattedValue.toUpperCase()
    // }

    return (
        <Row className="container-header__container">
            <Col span={24}>
                <Row align="bottom" justify="space-between">
                    <Col className="container-header__title">
                        <p className="container-header__title--back" onClick={navigateBackHandler}>
                            <ArrowLeftOutlined /> Containers </p>
                        <Row align="middle">
                            {!!container?.containerAttachments?.length && <img
                                className="container-header__title--thumb"
                                onClick={carouselTriggerClickHandler}
                                src={container?.containerAttachments[0]?.attachmentUrl} />}
                            {/* <h1>{containerNumberFormatting(String(container?.containerNumber))}</h1> */}
                            <h1>{container?.containerNumber}</h1>
                            {editVisible && <div className="icon-edit container-header__icon" onClick={editClickHandler} />}
                        </Row>
                    </Col>
                    <Col className="container-header__nav">
                        {navigation?.position} of {navigation?.totalCount}
                        <span
                            className={`container-header__nav-item ${!navigation?.previousId && 'disabled'}`}
                            onClick={() => containerNavigationHandler(navigation?.previousId)}
                        ><LeftOutlined /></span>
                        <span
                            className={`container-header__nav-item ${!navigation?.nextId && 'disabled'}`}
                            onClick={() => containerNavigationHandler(navigation?.nextId)}
                        ><RightOutlined /></span>
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="container-header__details">
                <Row justify="space-between">
                    <Col>
                        <p className="container-header--title">Yard Name</p>
                        <p className="container-header--subtitle">{container?.yard?.name}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Customer</p>
                        <p className="container-header--subtitle">{container?.customer?.fullName}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Owner Name</p>
                        <p className="container-header--subtitle">{container?.containerOwnerName}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Submitter Initials</p>
                        <p className="container-header--subtitle">{container?.submitterInitials}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Length</p>
                        <p className="container-header--subtitle">{container?.containerLength?.name}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Height</p>
                        <p className="container-header--subtitle">{container?.containerHeight?.name}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Container Type</p>
                        <p className="container-header--subtitle">{container?.containerType?.name}</p>
                    </Col>
                    <Col>
                        <p className="container-header--title">Manufacture Year</p>
                        <p className="container-header--subtitle">{container?.manufactureYear}</p>
                    </Col>
                </Row>
                {!!container?.comments?.length && <Row className="comment-section">
                    <Col>
                        <p className="container-header--title">Comments</p>
                        <p className="container-header--subtitle-no-caps">{container?.comments}</p>
                    </Col>
                </Row>}
            </Col>
        </Row>
    )
}

export default ContainerHeader