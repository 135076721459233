import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "../../../models/container.model";
import ContainerService from "../../../services/ContainerService/container.service";
import ContainerHeader from "../../../shared/components/ContainerHeader";
import { Col, Collapse, Form, Row, Tabs } from 'antd';
import "./containerDetail.scss"
import ContainerForm from "../ContainerForm";
import Loader from "../../../shared/components/Loader";
import ContainerCarousel from "../../../shared/components/ContainerCarousel";
import ContainerActivity from "../../../shared/components/ContainerFormHeader";
import ContainerActivityPanelActions from "../../../shared/components/ContainerFormBody/ContainerFormBodyActions";
import ContainerFormHeader from "../../../shared/components/ContainerFormHeader";
import ContainerFormBody from "../../../shared/components/ContainerFormBody";
import { Formik, FormikHelpers, FormikValues } from "formik";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import DropdownField from "../../../shared/components/DropdownField";
import { useRef } from "react";
import DatePickerComponent from "../../../shared/components/DatePickerComponent";
import { containerActivityValidationSchema } from "./containerActivity.validation";
import { Activity } from "../../../models/activity.model";
import ContainerItemForm from "./ContainerItemForm";
import { ActivityTypes } from "../../../shared/definitions/activity.definition";
import UIModal from "../../../shared/components/UIModal";
import { convertToTitleCase } from "../../../shared/utils/convertToTitleCase";
import ContainerDateForm from "./ContainerDateForm";
import ContainersContainer from "../../../store/container/ContainersContainer";
import { ContainerReducerProps } from "../../../store/reducers/containerReducer";
import { ActivityStatusEnum } from "../../../enums/activity.enum";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserTypes } from "../../../enums/userTypes.enum";
import ContainerCommentList from "./ContainerCommentList";
import ContainerLogs from "./ContainerLogs";

const { TabPane } = Tabs;
const { Panel } = Collapse

interface ContainerDetailProps extends ContainerReducerProps, AuthReducerProps { }

const ContainerDetail = (props: ContainerDetailProps) => {

    const {
        container,
        setContainer,
        setActivity,
        activity,
        setActivityItems,
        activityItems,
        user,
    } = props

    const history = useHistory()
    const { pathname } = history.location
    const { containerId } = useParams<{ containerId: string }>()

    const [formVisibile, setFormVisible] = useState<boolean>(false)

    const [carouselVisibile, setCarouselVisible] = useState<boolean>(false)

    const [dateFormVisible, setDateFormVisible] = useState<boolean>(false)

    const openCarousel = () => setCarouselVisible(true)

    const closeCarousel = () => setCarouselVisible(false)

    const formikRef = useRef<any>(null)

    const {
        // activity: selectedActivity,
        loading,
        submitting,
        container: selectedContainer,
        // activityItems,
        activityLoading,
        containerActivities,
        containerNavigation,
        fetchSingleContainer,
        createContainerActivity,
        downloadContainerImages,
        containerActivitiesLoading,
        fetchContainerActivityItems,
        fetchSingleContainerActivites,
        fetchContainerActivityDetails,
    } = ContainerService()

    useEffect(() => {
        fetchContainerActivities({
            container: true,
            activities: true
        })
    }, [pathname])

    const openContainerForm = () => setFormVisible(true)

    const fetchContainerActivities = (options: {
        container?: boolean,
        activities?: boolean,
        activityItems?: number,
    }) => {
        if (!containerId || !user?.role) return

        if (options?.activityItems)
            fetchContainerActivityDetailsHandler(String(options.activityItems))

        if (options.container) {
            fetchSingleContainer(containerId, user?.role?.toLowerCase())
            setActivity(new Activity())
        }
        if (options.activities) {
            fetchSingleContainerActivites({ container_id: +containerId }, user?.role?.toLowerCase())
            setActivity(new Activity())
        }
    }

    const closeContainerForm = (success?: boolean) => {
        setFormVisible(false)
        if (success)
            fetchContainerActivities({
                container: true,
                activities: true
            })
    }

    useEffect(() => {
        if (selectedContainer)
            setContainer(selectedContainer)
    }, [selectedContainer])

    const openDateForm = () => {
        setDateFormVisible(true)
    }

    const closeDateForm = (activity?: Activity) => {
        setDateFormVisible(false)
        if (activity) {
            setActivity(activity)
            fetchContainerActivities({
                activities: true
            })
        }
    }

    const downloadHandler = () => {
        downloadContainerImages(container || {})
    }

    const createActivityHandler = async (values: Activity, helpers: FormikHelpers<any>) => {
        if (!containerId || !user?.role) return
        try {
            await createContainerActivity({
                ...values,
                containerId: +containerId,
            }, user?.role?.toLowerCase())
            helpers.resetForm()
            fetchContainerActivities({
                container: true,
                activities: true
            })
        } catch (error) {

        }
    }


    const fetchContainerActivityDetailsHandler = async (id: string | string[]) => {
        setActivity(new Activity())
        if (!id || !user?.role) return
        setActivityItems([])
        const activityId = typeof id === 'string' ? id : id[0] as string
        const activity = await fetchContainerActivityDetails(activityId, user.role.toLowerCase())
        const activityItems = await fetchContainerActivityItems(activityId, user?.role?.toLowerCase())
        if (activity)
            setActivity(activity)
        if (activityItems)
            setActivityItems(activityItems)
    }

    if (loading)
        return <Loader />
    return (
        <div className="container-detail__container">
            <ContainerHeader
                editVisible={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()}
                editClickHandler={openContainerForm}
                container={container}
                navigation={containerNavigation}
                carouselTriggerClickHandler={openCarousel}
            />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Activity" key="1" className="activities__container">
                    {container.containerStatus === ActivityStatusEnum.IDLE
                        && user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()
                        && <Row className="container-form">
                            <Col span={24}>
                                <Formik
                                    initialValues={new Activity()}
                                    onSubmit={createActivityHandler}
                                    enableReinitialize
                                    innerRef={formikRef}
                                    validationSchema={containerActivityValidationSchema}
                                >
                                    {({ setFieldValue, values, errors, validateField, submitForm }) => {
                                        return (
                                            <Form>
                                                <Row gutter={[20, 20]}>
                                                    <Col sm={8} md={8} lg={6} xl={5} xxl={4} className="container-form__field">
                                                        <DropdownField
                                                            placeHolder="Activity Type"
                                                            name='activityType'
                                                            options={ActivityTypes}
                                                            value={values.activityType}
                                                            onChange={(value) => {
                                                                setFieldValue('activityType', value)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm={8} md={8} lg={6} xl={5} xxl={4} className="container-form__field">
                                                        <DatePickerComponent
                                                            placeHolder="Activity Date"
                                                            name='activityDate'
                                                            value={values.activityDate}
                                                            onChange={(value, dateString) => {
                                                                setFieldValue('activityDate', dateString)
                                                            }}
                                                            disableDates="future"
                                                        />
                                                    </Col>
                                                    <Col sm={8} md={8} lg={6} xl={5} xxl={4} className="container-form__field">
                                                        <ButtonComponent loading={submitting} type="primary" htmlType="submit" text="Add Activity" onClick={submitForm} />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Col>
                        </Row>}
                    {containerActivitiesLoading
                        ? <Loader />
                        : containerActivities.length
                            ? <Collapse
                                className="container-form__container"
                                onChange={fetchContainerActivityDetailsHandler}
                                accordion
                                destroyInactivePanel
                            >
                                {containerActivities.map((current, i) => <Panel
                                    showArrow={false}
                                    key={current?.id || ''}
                                    header={<ContainerFormHeader
                                        key={current?.id}
                                        openDateForm={openDateForm}
                                        activity={activity.id === current.id ? activity : current}
                                        isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()}
                                        isActivityValid={!!activity.id && activity.id === current.id}
                                        refreshListHandler={fetchContainerActivities}
                                    />}
                                    collapsible="header"
                                >
                                    <ContainerFormBody
                                        key={activity?.id}
                                        refreshListHandler={fetchContainerActivities}
                                        loading={activityLoading} />
                                </Panel>)}
                            </Collapse>
                            : <div className="container-activity--empty">
                                <p >No Activities yet</p>
                            </div>

                    }
                </TabPane>
                <TabPane tab="Comments" key="2">
                    <ContainerCommentList containerId={containerId} />
                </TabPane>
                <TabPane tab="Log" key="3">
                    <ContainerLogs containerId={containerId} />
                </TabPane>
            </Tabs>
            <ContainerForm
                isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase() || false}
                visible={formVisibile}
                closeHandler={(success) => closeContainerForm(success)}
                data={container}
            />
            {carouselVisibile && <ContainerCarousel
                loading={submitting}
                downloadHandler={downloadHandler}
                attachments={container?.containerAttachments || []}
                title={container?.containerNumber || ''}
                closeHandler={closeCarousel}
            />}
            <ContainerDateForm
                visible={dateFormVisible}
                closeHandler={closeDateForm}
                activity={activity || {}}
            />
        </div>
    )
}

export default ContainersContainer(AuthContainer(ContainerDetail))