import React, { FC } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
//import isAuthenticated from "../shared/components/HOC/requireAuth';
import { RouterProps } from "../shared/types/route.type";
import AppSidebar from "../shared/components/AppSidebar";
import RepairList from "../views/RepairList";
import Dashboard from "../views/Dashboard";
import Customers from "../views/Customers";
import CustomerDetails from "../views/Customers/CustomerDetails";
import Containers from "../views/Containers";
import ContainerDetail from "../views/Containers/ContainerDetail";
import Invoices from "../views/Invoices";
import InvoiceDetails from "../views/Invoices/InvoiceDetails";
import UserManagement from "../views/UserManagement";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import Reports from "../views/Reports";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
	const routes: RouterProps[] = [
		{ exact: false, path: appRoutes.AUTH, component: AuthWrapper },
		{ exact: false, path: appRoutes.REPAIR_LIST, component: isAuthenticated(RepairList) },
		{ exact: false, path: appRoutes.DASHBOARD, component: isAuthenticated(Dashboard) },
		{ exact: true, path: appRoutes.CUSTOMERS, component: isAuthenticated(Customers) },
		{ exact: false, path: appRoutes.CUSTOMER, component: isAuthenticated(CustomerDetails) },
		{ exact: true, path: appRoutes.CONTAINERS, component: isAuthenticated(Containers) },
		{ exact: false, path: appRoutes.CONTAINER, component: isAuthenticated(ContainerDetail) },
		{ exact: true, path: appRoutes.INVOICES, component: isAuthenticated(Invoices) },
		{ exact: false, path: appRoutes.INVOICE, component: isAuthenticated(InvoiceDetails) },
		{ exact: true, path: appRoutes.USER_MANAGEMENT, component: isAuthenticated(UserManagement) },
		{ exact: true, path: appRoutes.REPORTS, component: isAuthenticated(Reports) },
	];

	return (
		<div className="app__container">
			<Router history={appHistory}>
				<AppSidebar />
				<div className="router--outlet">
					<Switch>
						{routes.map((route, index) => (
							<Route
								key={index}
								{...route}
								component={route.component as FC}
							/>
						))}
						<Route
							path="*"
							render={() => <Redirect to={appRoutes.DASHBOARD} />}
						/>
					</Switch>
				</div>
			</Router>
		</div>
	);
};

export default AppRoutes;
