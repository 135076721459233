import { Row, Col } from 'antd'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as  AppRoutes from '../../../routes/routeConstants/appRoutes'
import DashboardService from '../../../services/DashboardService/dashboard.service'
import AppHeader from '../../../shared/components/AppHeader'
import Loader from '../../../shared/components/Loader'
import TableComponent from '../../../shared/components/TableComponent'
import "./customerDashboard.scss"

interface CustomerDashboardProps { }

const CustomerDashboard: FC<CustomerDashboardProps> = (props) => {

    const history = useHistory()

    const {
        rate,
        date,
        loading,
        statusTable,
        containerTable,
        fetchCustomerDashboard,
    } = DashboardService()

    useEffect(() => {
        fetchCustomerDashboard()
    }, [])


    const columns = [
        {
            title: "",
            dataIndex: "0",
            key: "0",
            width: 200,
            render: (text: string) => <span className='text-capitalize'>{text?.replaceAll("_", " ")}</span>
        },
        {
            title: "Today",
            dataIndex: "1",
            key: "1",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Last 7 days",
            dataIndex: "2",
            key: "2",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Last 30 days",
            dataIndex: "3",
            key: "3",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Last year",
            dataIndex: "4",
            key: "4",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
    ]

    const statusColumns = [
        {
            title: "Number of Containers by status",
            dataIndex: "0",
            key: "0",
            width: 200,
            render: (text: string) => <span className='text-capitalize'>{text?.replaceAll("_", " ")}</span>
        },
        {
            title: "Aheer",
            dataIndex: "1",
            key: "1",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Habourlink",
            dataIndex: "2",
            key: "2",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Nordel",
            dataIndex: "3",
            key: "3",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
        {
            title: "Total",
            dataIndex: "4",
            key: "4",
            className: "amount-column",
            align: 'right' as 'right',
            width: 100,
            render: (text: number) => (text || 0)
            // sorter: true,
        },
    ]

    if (loading)
        return <Loader />

    return (
        <div className='customer-dashboard__container'>
            <AppHeader
                title={"Dashboard"}
                addButton={false}
            />
            {rate && <p className='title-helper'>Current hourly rate:
                <b>CAD ${rate}</b>
                <span className='chip'> {date && moment(date).format('MMM DD, YYYY')}</span>
            </p>}
            <Row className='customer-dashboard__table-wrapper' gutter={[32, 32]}>
                <Col span={24}>
                    <TableComponent
                        columns={columns}
                        data={containerTable}
                        loading={false}
                        pagination={false}
                    />
                </Col><Col span={24}>
                    <TableComponent
                        className='cursor-pointer'
                        columns={statusColumns}
                        handleRowClick={(row: string[]) => {
                            const status = row[0]
                            history.push({
                                pathname: AppRoutes.CONTAINERS,
                                hash: status.replaceAll("_", "-")
                            })
                        }}
                        data={statusTable}
                        loading={false}
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default CustomerDashboard