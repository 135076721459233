import { serializable, alias, primitive } from 'serializr';

export class Pagination {
    @serializable(alias('current_page', primitive()))
    current?: number;

    @serializable(alias('next_page', primitive()))
    next?: number;

    @serializable(alias('prev_page', primitive()))
    prev?: number;

    @serializable(alias('total_count', primitive()))
    totalCount?: number;

    @serializable(alias('total_pages', primitive()))
    totalPages?: number;
}
