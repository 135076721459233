import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserTypes } from "../../enums/userTypes.enum";
import { Activity } from "../../models/activity.model";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import AppHeader from "../../shared/components/AppHeader";
import AuthContainer from "../../store/container/AuthContainer";
import { AuthReducerProps } from "../../store/reducers/authReducer";
import AllContainers from "./AllContainers";
import ApprovalPendingContainers from "./ApprovalPendingContainers";
import ContainerForm from "./ContainerForm";
import "./containers.scss"
import DraftContainers from "./DraftContainers";
import QuotesApprovedContainers from "./QuotesApprovedContainers";
import ReviewPendingContainers from "./ReviewPendingContainers";

const { TabPane } = Tabs
interface ContainersProps extends AuthReducerProps { }

const Containers = (props: ContainersProps) => {

    const {
        user,
    } = props

    const history = useHistory()

    const [formVisibile, setFormVisible] = useState<boolean>(false)

    const [selectedTab, setSelectedTab] = useState<string>();

    useEffect(() => {
        const tab = history.location.hash.replace("#", "")
        setSelectedTab(tab || tabsList[0].hash)
    }, [history.location.pathname, formVisibile])

    const activityRowClickHandler = (activity: Activity) => {
        const containerId = activity?.container?.id
        if (containerId)
            history.push(appRoutes.CONTAINER.replace(":containerId", String(containerId)))
    }

    const tabsList = [{
        title: "All",
        hash: 'all',
        component: <AllContainers isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()} formVisible={formVisibile} rowClickHandler={activityRowClickHandler} />,
    },
    {
        title: "Draft",
        hash: 'in-draft',
        disabled: user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase(),
        component: <DraftContainers isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()} formVisible={formVisibile} rowClickHandler={activityRowClickHandler} />,
    },
    {
        title: "Admin Review Pending",
        hash: 'pending-admin-review',
        disabled: user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase(),
        component: <ReviewPendingContainers isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()} formVisible={formVisibile} rowClickHandler={activityRowClickHandler} />,
    },
    {
        title: "Pending Customer Approval",
        hash: 'pending-customer-review',
        component: <ApprovalPendingContainers isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()} formVisible={formVisibile} rowClickHandler={activityRowClickHandler} />,
    },
    {
        title: "Quotes Approved by Customer",
        hash: 'approved-by-customer',
        component: <QuotesApprovedContainers isAdmin={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()} formVisible={formVisibile} rowClickHandler={activityRowClickHandler} />,
    }
    ]

    const openContainerForm = () => user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase() && setFormVisible(true)

    const closeContainerForm = () => setFormVisible(false)

    const handleTabChange = (hash: string) => {
        setSelectedTab(hash);
        history.push({
            pathname: history.location.pathname,
            hash
        })
    }

    return <div className="containers__container">
        <AppHeader
            title="Containers"
            addButton={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()}
            className="bg-white"
            addButtonClickHandler={openContainerForm}
        />
        <div className="containers__tabs">
            <Tabs
                destroyInactiveTabPane
                activeKey={selectedTab}
                onChange={handleTabChange}>
                {tabsList.map(({ title, component, hash, disabled }) => <TabPane
                    key={hash}
                    disabled={disabled}
                    tab={title}
                    active={hash === selectedTab}
                >{component}</TabPane>)}
            </Tabs>
        </div>
        <ContainerForm
            isAdmin={user?.role?.includes(UserTypes.ADMIN) || false}
            visible={formVisibile}
            closeHandler={closeContainerForm}
        />
    </div >
}

export default AuthContainer(Containers)