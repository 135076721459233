export const CustomerApprovalTypes = [
    {
        label: "Merc+", value: "merc_approval_type",
    },
    {
        label: "Common", value: "common_approval_type",
    },
    {
        label: "MSC", value: "msc_approval_typ",
    },
]
export const CustomerBillingTypes = [
    {
        label: "Merc+", value: "merc",
    },
    {
        label: "Common", value: "common",
    },
    {
        label: "MSC", value: "msc",
    },
]