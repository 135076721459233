import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as ContainerFunctions from "../actions/container";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => ({
	container: state.container.container,
	activity: state.container.activity,
	activityItems: state.container.activityItems,
	...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(ContainerFunctions, dispatch);

const ContainersContainer = (component: any) => {
	return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default ContainersContainer;
