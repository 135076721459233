import { serializable, alias, primitive } from 'serializr';

export class MetaModel {
    @serializable(alias('id', primitive()))
    value?: string;

    @serializable(alias('name', primitive()))
    label?: string;

    //Only for all Customers
    @serializable(alias('full_name', primitive()))
    customerName?: string;
}

export class AttachmentModel {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('attachment_url', primitive()))
    attachmentUrl?: string;

}
