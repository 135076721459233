import React, { FC, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import RestrictAccess from "../RestrictedAccess";
import AuthContainer from '../../../store/container/AuthContainer';
import { UserTypes } from '../../../enums/userTypes.enum';

const requireAuth = (Component: any, allowedRoles: any = []) => {
    const Authentication = (props: any) => {
        useEffect(() => {
            const { authenticated, history } = props;
            if (!authenticated && history.location.pathname !== appRoutes.LOGIN) {
                return history.push(appRoutes.LOGIN);
            }
            if (authenticated && history.location.pathname.includes(appRoutes.AUTH)) {
                return history.push(appRoutes.DASHBOARD);
            }

            if (authenticated) {
                const { user } = props;
                if (user?.role?.toLowerCase() === UserTypes.CUSTOMER.toLowerCase() &&
                    (history.location.pathname.includes(appRoutes.CUSTOMERS) ||
                        history.location.pathname.includes(appRoutes.USER_MANAGEMENT) ||
                        history.location.pathname.includes(appRoutes.REPORTS))
                ) {
                    return history.push(appRoutes.DASHBOARD);
                }
            }
        }, [props]);

        if (allowedRoles.length) {
            const { user } = props;
            return allowedRoles.includes(user.role) ? <Component {...props} /> : <RestrictAccess />;
        }
        return <Component {...props} />
    }
    return withRouter(AuthContainer(Authentication));
};

export const isAuthenticated = (component: FC) => {
    return requireAuth(component);
};


export default isAuthenticated;