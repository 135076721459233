import { Row, Col } from "antd";
import { Formik, Form, Field, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Customer } from "../../../models/customer.model";
import { MetaModel } from "../../../models/meta.model";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import MetaService from "../../../services/MetaService/meta.service";
import RepairService from "../../../services/RepairService/repair.service";
import { AttachmentComponent } from "../../../shared/components/AttachmentComponent";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import StatusField from "../../../shared/components/StatusField";
import UIModal from "../../../shared/components/UIModal";
import { CustomerApprovalTypes, CustomerBillingTypes } from "./customer.definition";
import { customerValidationSchema } from "./customerForm.validation"

interface CustomerFormProps {
    visible: boolean
    loading: boolean
    data?: Customer
    closeHandler: (success?: boolean) => void
    submitHandler: (values: Customer, helpers: FormikHelpers<any>) => void
}
const CustomerForm = (props: CustomerFormProps) => {

    const {
        data,
        visible,
        loading,
        closeHandler,
        submitHandler,
    } = props

    const [initialValues, setInitialValues] = useState<Customer>(new Customer())

    const [cities, setCities] = useState<MetaModel[]>([])

    const [provinces, setProvinces] = useState<MetaModel[]>([])

    const [repairList, setRepairList] = useState<MetaModel[]>([])

    const formikRef = useRef<any>(null)

    useEffect(() => {
        if (data?.province?.id)
            fetchCityInProvince(data?.province?.id)
        if (data) {
            data.provinceId = data.province?.id
            data.hourlyRate = Number(data?.hourlyRateDollars)
        }
        setInitialValues(data ? { ...data } : new Customer())
    }, [data])

    const {
        fetchCustomerRepairListMeta,
    } = RepairService()

    const {
        fetchMeta,
    } = MetaService()

    useEffect(() => {
        fetchRepairListMeta()
        fetchMeta(ApiRoutes.PROVINCES, setProvinces, "provinces")
    }, [])


    const fetchRepairListMeta = async () => {
        const data = await fetchCustomerRepairListMeta()
        if (data && data.length) {
            const repairList = data.map(version => ({ label: version.name, value: version.id })) as MetaModel[]
            setRepairList(repairList)
        }
    }

    const fetchCityInProvince = (provinceId: number) => {
        fetchMeta(ApiRoutes.CITY.replace(":provinceId", String(provinceId)), setCities, "cities")
    }


    return <UIModal
        visible={visible}
        title="Customer"
        okText="Proceed"
        onOk={() => formikRef.current?.submitForm()}
        onCancel={() => closeHandler()}
        loading={loading}
    >
        <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            enableReinitialize
            innerRef={formikRef}
            validationSchema={customerValidationSchema}
        >
            {({ setFieldValue, values, errors, isValid }) => {
                return (
                    <Form>
                        <Row gutter={[20, 20]}>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Username</div>
                                <InputField
                                    type="text"
                                    name="fullName"
                                    placeholder="Enter"
                                    value={values.fullName}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Email</div>
                                <InputField
                                    type="text"
                                    name="email"
                                    placeholder="Enter"
                                    value={values.email}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Customer Name</div>
                                <InputField
                                    type="text"
                                    name="ownerName"
                                    placeholder="Enter"
                                    value={values.ownerName}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Billing Name</div>
                                <InputField
                                    type="text"
                                    name="billingName"
                                    placeholder="Enter"
                                    value={values.billingName}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Hourly Rate</div>
                                <InputField
                                    type="text"
                                    name="hourlyRate"
                                    placeholder="0 $"
                                    value={values.hourlyRate}
                                />
                            </Col>
                            <Col span={12} className="customer-form__field">
                                <div className="form-label">GST</div>
                                <InputField
                                    type="text"
                                    name="gst"
                                    placeholder="0 %"
                                    value={values.gst}
                                />
                            </Col>
                            <Col span={12} className="customer-form__field">
                                <div className="form-label">PST</div>
                                <InputField
                                    type="text"
                                    name="pst"
                                    placeholder="0 %"
                                    value={values.pst}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <DropdownField
                                    title={'Province'}
                                    name='provinceId'
                                    options={provinces}
                                    value={values.provinceId}
                                    onChange={(value) => {
                                        setFieldValue('provinceId', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Address</div>
                                <InputField
                                    type="text"
                                    name="address"
                                    placeholder="Enter"
                                    value={values.address}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">City</div>
                                <InputField
                                    type="text"
                                    name="city"
                                    placeholder="Enter"
                                    value={values.city}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Postal Code</div>
                                <InputField
                                    type="text"
                                    name="postalCode"
                                    placeholder="Enter"
                                    value={values.postalCode}
                                />
                            </Col>
                            {!values.id && <Col span={24} className="customer-form__field">
                                <DropdownField
                                    title={'Repair List'}
                                    name='repairListId'
                                    options={repairList}
                                    value={values.repairListId ?? values.repairList}
                                    onChange={(value) => {
                                        setFieldValue('repairListId', value)
                                    }}
                                />
                            </Col>}
                            <Col span={24} className="customer-form__field">
                                <DropdownField
                                    title={'Approval Type'}
                                    name='approvalType'
                                    options={CustomerApprovalTypes}
                                    value={values.approvalType}
                                    onChange={(value) => {
                                        setFieldValue('approvalType', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <DropdownField
                                    title={'Billing Type'}
                                    name='billingType'
                                    options={CustomerBillingTypes}
                                    value={values.billingType}
                                    onChange={(value) => {
                                        setFieldValue('billingType', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Password</div>
                                <InputField
                                    type="text"
                                    name="password"
                                    placeholder="Enter"
                                    value={values.password}
                                />
                            </Col>
                            <Col span={24} className="customer-form__field">
                                <div className="form-label">Status</div>
                                <StatusField
                                    checked={!!values.status}
                                    onChange={(value: boolean) => {
                                        setFieldValue("status", value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    </UIModal>

}

export default CustomerForm