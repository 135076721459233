import { Row, Col } from "antd";
import { FormikValues } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ActivityEnum, ActivityStatusEnum } from "../../../enums/activity.enum";
import { UserTypes } from "../../../enums/userTypes.enum";
import { Activity } from "../../../models/activity.model";
import ContainerService from "../../../services/ContainerService/container.service";
import ApproveQuotesModal from "../../../shared/components/ApproveQuotesModal";
import ContainerTableActions from "../../../shared/components/ContainerTableActions";
import TableComponent from "../../../shared/components/TableComponent";
import useFilters from "../../../shared/hooks/useFilters";
import { ContainerActivitiesInterface } from "../AllContainers";
import { containerFilters } from "../container.definitions";

const columns = [
    {
        title: "Container Number",
        dataIndex: "containerNumber",
        key: "containerNumber",
        width: 100,
    },
    {
        title: "Yard",
        dataIndex: "yardName",
        key: "yardName",
        width: 100,
        sorter: true,
        render: (text: string) => <span className="text-capitalize">{text}</span>,
    },
    {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 100,
        sorter: true,
    },
    {
        title: "Owner Name",
        dataIndex: "containerOwnerName",
        key: "containerOwnerName",
        width: 100,
    },
    {
        title: "Activity",
        dataIndex: "activityType",
        key: "activityType",
        width: 100,
        sorter: true,
        render: (text: string) => <span className="text-capitalize">{text}</span>,
    },
    {
        title: "Activity ID",
        dataIndex: "activityUid",
        key: "activityUid",
        width: 100,
        sorter: true,
    },
    {
        title: "Activity Date",
        dataIndex: "activityDate",
        key: "activityDate",
        width: 100,
        sorter: true,
        render: (text: string) => <span>{text ? moment(text).format("DD MMM, YYYY") : ""}</span>,
    },
    {
        title: "Status",
        dataIndex: "activityStatus",
        key: "activityStatus",
        width: 100,
        sorter: true,
        render: (text: string) => <p className={`status ${text}`}>{text?.replaceAll('_', ' ')}</p>,
    },
]

const sortFields: any = {
    "customer": "customer_name",
    "yardName": "yard_name",
    "containerOwnerName": "owner_name",
    "activityType": "activity_type_sort",
    "activityUid": "activity_uid",
    "activityDate": "created_at",
    "activityStatus": "activity_status_sort",
}

const ApprovalPendingContainers = (props: ContainerActivitiesInterface) => {


    const {
        rowClickHandler,
        formVisible,
        isAdmin,
    } = props

    const [selectedRowKeys, setSelectedRows] = useState<number[]>([])

    const [selectedRow, setSelected] = useState<Activity>()

    const [approveVisible, setApproveVisible] = useState<boolean>(false)

    const [customerFilter, setCustomerFilter] = useState<number>()

    const {
        activities,
        loading,
        submitting,
        fetchActivites,
        activitiesMeta,
        approveActivites,
        exportCustomerPendingActivity,
        exportSubmitting,
        exportBulkImageActivity,
        exportImagesSubmitting,
    } = ContainerService()

    const {
        getParams,
        setParams,
    } = useFilters()

    useEffect(() => {
        if (formVisible) return
        const filters = getParams(containerFilters)
        fetchQuotesApprovedActivities(filters)
        setCustomerFilter(filters.customer_id || undefined)
    }, [formVisible])

    useEffect(() => {
        setSelectedRows([])
    }, [activities])

    const filtersApplyHandler = (values: FormikValues) => {
        const customerId = values["customer_id"]
        setParams(values)
        setCustomerFilter(customerId)
        fetchQuotesApprovedActivities(values)
    }

    const exportHandler = (exportOption: "with_image" | "without_image") => {
        exportCustomerPendingActivity(selectedRowKeys, isAdmin ? UserTypes.ADMIN.toLowerCase() : UserTypes.CUSTOMER.toLowerCase(), exportOption)
    }

    const bulkImageExportHandler = (exportOption: "with_image" | "without_image") => {
        exportBulkImageActivity (
            selectedRowKeys,
            isAdmin ? UserTypes.ADMIN.toLowerCase() : UserTypes.CUSTOMER.toLowerCase(),
            exportOption
        )
    }

    const handleRowSelection = (selectedRowKeys: any[]) => {
        if (!selectedRowKeys.length || !selectedRow)
            setSelected(activities?.find(activity => activity.id === selectedRowKeys[0]))
        setSelectedRows(selectedRowKeys)
    }

    const approveClickHandler = () => {
        if (selectedRowKeys.length)
            setApproveVisible(true)
    }

    const approveHandler = async () => {
        const response = await approveActivites(ActivityStatusEnum.READY_FOR_BILLING, selectedRowKeys);
        if (response) {
            fetchQuotesApprovedActivities(getParams(containerFilters))
            approveCloseHandler()
        }
    }
    const approveCloseHandler = () => setApproveVisible(false)

    const fetchQuotesApprovedActivities = (options?: FormikValues) => {
        fetchActivites({
            status: ActivityEnum.CUSTOMER_PENDING,
            ...options
        }, isAdmin ? UserTypes.ADMIN : UserTypes.CUSTOMER)
    }
    const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any; }) => {
        const sortOrders = [undefined, 'ascend']
        const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field[0] : undefined
        const params: any = {}
        params[sortFields[field]] = sortOrders.indexOf(sorter.order)
        params.page = pagination.current
        const repairListFilters = getParams(containerFilters)
        const updatedFilters = { ...repairListFilters, page: params.page }
        setParams(updatedFilters)
        fetchQuotesApprovedActivities({ ...params, ...updatedFilters })
    };

    const getSelectionStatus = (record: Activity) => {
        if (selectedRow && record.customer !== selectedRow.customer)
            return "selection-disabled"
        return ""
    }

    return <Row gutter={[0, 20]}>
        <Col span={24}>
            <ContainerTableActions
                isAdmin={isAdmin}
                exportVisible={!!customerFilter && !!selectedRowKeys.length}
                exportLoading={exportSubmitting}
                imageExportVisible={!!customerFilter && !!selectedRowKeys.length}
                imageExportLoading={exportImagesSubmitting}
                approveVisible={true}
                exportHandler={() => exportHandler("with_image")}
                exportImagesHandler={() => bulkImageExportHandler("with_image")}
                values={getParams(containerFilters)}
                approveClickHandler={approveClickHandler}
                filtersApplyHandler={filtersApplyHandler} />
        </Col>
        <Col span={24}>
            <TableComponent
                className="cursor-pointer"
                loading={loading}
                columns={columns}
                data={activities}
                scroll={{ x: 800 }}
                rowClassName={getSelectionStatus}
                onChange={handleTableChange}
                pagination={{
                    pageSize: 20,
                    current: activitiesMeta.current || 1,
                    total: activitiesMeta.totalCount || 0,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
                rowKey="id"
                rowSelection={customerFilter
                    ? {
                        selectedRowKeys,
                        onChange: handleRowSelection,
                    }
                    : undefined
                }
                handleRowClick={rowClickHandler}
            />
        </Col>
        <ApproveQuotesModal
            visible={approveVisible}
            loading={submitting}
            onOk={approveHandler}
            onCancel={approveCloseHandler}
        />
    </Row>

}
export default ApprovalPendingContainers