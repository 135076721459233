import * as Yup from "yup";
import { REGEX } from "../../../../shared/utils/regex";

export const containerItemFormValidationSchema = Yup.object().shape({
    repairCode: Yup.number().positive("Invalid repair code!")
        .typeError("Invalid repair code!")
        .required('Repair code is required!'),
    repairId: Yup.number().positive()
        .typeError("Repair area is required!")
        .required('Repair area is required!'),
    damagedId: Yup.number().positive()
        .typeError("Damage area is required!")
        .required('Damage area is required!'),
    repairTypeId: Yup.number().positive()
        .typeError("Type area is required!")
        .required('Type is required!'),
    unitId: Yup.number().positive()
        .typeError("Unit is required!")
        .required('Unit is required!'),
    lengthId: Yup.number().positive()
        .typeError("Length is required!")
        .required('Length is required!'),
    widthId: Yup.number().positive()
        .typeError("Width is required!")
        .required('Width is required!'),
    location: Yup.string()
        .matches(REGEX.CONTAINER_LOCATION, 'Location should be valid!')
        .required('Location is required!'),
    hours: Yup.number()
        .required('Hours is required!'),
    totalCost: Yup.number()
        .required('Total cost is required!'),
    labourCost: Yup.number()
        .required('Labour cost is required!'),
    materialCost: Yup.number()
        .required('Material cost is required!'),
    comments: Yup.string()
        .nullable(),
    // attachments: Yup.array().min(1, "Damaged area photo is required!")
    //     .required('Damaged area photo is required!'),
})
