import { ActivityItems } from './../../models/activity.model';
import { Dispatch, useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { AttachmentModel, MetaModel } from "../../models/meta.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { generatePath } from "react-router";

const MetaService = () => {

    const [uploading, setUploading] = useState<boolean>(false)

    const [activityStatuses, setActivityStatuses] = useState<string[]>([])

    const fetchMeta = (metaUrl: string, setStateFn: Dispatch<MetaModel[]>, key: string) => {
        return axiosInstance
            .get(metaUrl)
            .then((response) => {
                const meta: any = deserialize(MetaModel, response.data[key]);
                setStateFn(meta)
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }

    const fetchContainerActivityTypeDamagedArea = async (params: {
        activity_id?: number,
        container_repair_area_id?: string
    }, setStateFn: Dispatch<MetaModel[]>) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITY_ITEM_DAMAGED_AREA)
            const response = await axiosInstance.post(API_URL, {}, { params })
            const data = deserialize(MetaModel, response.data["container_damaged_areas"]) as MetaModel[]
            setStateFn(data);
        } catch (error) {

        }
    }
    const fetchContainerActivityTypeRepairType = async (
        params: {
            activity_id?: number,
            container_repair_area_id?: number,
            container_damaged_area_id?: number,
        },
        setStateFn: Dispatch<MetaModel[]>) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITY_ITEM_REPAIR_TYPE)
            const response = await axiosInstance.post(API_URL, {}, { params })
            const data = deserialize(MetaModel, response.data["repair_types"]) as MetaModel[]
            setStateFn(data)
        } catch (error) {

        }
    }
    const fetchContainerActivityLength = async (
        params: {
            activity_id?: number
            container_repair_area_id?: number,
            container_damaged_area_id?: number,
            repair_type_id?: number
        },
        setStateFn: Dispatch<MetaModel[]>) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITY_ITEM_LENGTH)
            const response = await axiosInstance.post(API_URL, {}, { params })
            const data = deserialize(MetaModel, response.data["lengths"]) as MetaModel[]
            setStateFn(data)
        } catch (error) {

        }
    }

    const fetchContainerActivityWidth = async (
        params: {
            activity_id?: number
            container_repair_area_id?: number,
            container_damaged_area_id?: number,
            repair_type_id?: number,
            length_id?: number
        },
        setStateFn: Dispatch<MetaModel[]>) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITY_ITEM_WIDTH)
            const response = await axiosInstance.post(API_URL, {}, { params })
            const data = deserialize(MetaModel, response.data["widths"]) as MetaModel[]
            setStateFn(data)
        } catch (error) {

        }
    }
    const fetchContainerActivityUnit = async (
        params: {
            activity_id?: number
            container_repair_area_id?: number,
            container_damaged_area_id?: number,
            repair_type_id?: number,
            length_id?: number,
            width_id?: number
        },
        setStateFn: Dispatch<MetaModel[]>) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITY_ITEM_UNIT)
            const response = await axiosInstance.post(API_URL, {}, { params })
            const data = deserialize(MetaModel, response.data["units"]) as MetaModel[]
            setStateFn(data)
        } catch (error) {

        }
    }

    const fetchContainerActivityRepairCode = async (
        params: {
            activity_id?: number
            container_repair_area_id?: number,
            container_damaged_area_id?: number,
            repair_type_id?: number,
            length_id?: number,
            width_id?: number,
            unit_id?: number
        }) => {
        try {
            const API_URL = generatePath(ApiRoutes.CONTAINER_ACTIVITY_ITEM_ALL)
            const response = await axiosInstance.post(API_URL, {}, { params })
            const data = deserialize(ActivityItems, response.data) as ActivityItems
            return data
        } catch (error) {

        }
    }

    const fetchActivityStatusMeta = (activity_id: number | string) => {
        return axiosInstance
            .post(ApiRoutes.ACTIVITY_STATUSES, { activity_id })
            .then((response) => {
                const statuses = response.data['activity_statuses']
                setActivityStatuses(statuses)
            })
            .catch((error) => {
            })
            .finally(() => {
            });
    }


    const uploadAttachment = async (file: File): Promise<AttachmentModel | undefined> => {
        setUploading(true)
        try {
            const payload = {
                attachment: file
            }
            const API_URL = ApiRoutes.ATTACHMENT
            const response = await axiosInstance.post(API_URL, convertJSONToFormData(payload))
            return deserialize(AttachmentModel, response.data['uploaded_file'])
        } catch (error) {
        } finally {
            setUploading(false)
        }
    }


    return {
        uploading,
        activityStatuses,
        uploadAttachment,
        fetchActivityStatusMeta,
        fetchMeta,
        fetchContainerActivityLength,
        fetchContainerActivityTypeDamagedArea,
        fetchContainerActivityTypeRepairType,
        fetchContainerActivityWidth,
        fetchContainerActivityUnit,
        fetchContainerActivityRepairCode,
    }
}

export default MetaService;