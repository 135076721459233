import EllipsisOutlined from "@ant-design/icons/lib/icons/EllipsisOutlined";
import { Col, Dropdown, Menu, Row, Tooltip } from "antd";
import moment from "moment";
import React, { MouseEventHandler, useState } from "react";
import { UserTypes } from "../../../enums/userTypes.enum";
import { Activity } from "../../../models/activity.model";
import ContainerService from "../../../services/ContainerService/container.service";
import "./containerFormHeader.scss"
import DownloadIcon from "./../../../assets/image-download.svg"
import { LoadingOutlined } from "@ant-design/icons";
import ConfirmationModal from "../ConfirmationModal";
import { convertToTitleCase } from "../../utils/convertToTitleCase";
import DefaultIcon from "./../../../assets/default\ version.svg"

interface ContainerFormHeaderProps {
    activity?: Activity
    openDateForm: () => void
    isActivityValid: boolean
    isAdmin: boolean
    refreshListHandler: (options: {
        container?: boolean,
        activities?: boolean,
        activityItems?: number,
    }) => void
}

const ContainerFormHeader = (props: ContainerFormHeaderProps) => {

    const {
        activity,
        openDateForm,
        isActivityValid,
        refreshListHandler,
        isAdmin,
    } = props

    const {
        submitting,
        activityLoading,
        deleteContainerActivity,
        downloadAllActivityItemImages,
    } = ContainerService()

    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState<boolean>(false)

    const openDateFormHandler = (e: any) => {
        e.domEvent.stopPropagation()
        openDateForm()
    }

    const deleteHandler = async () => {
        if (!activity?.id) return
        try {
            await deleteContainerActivity(activity?.id, isAdmin ? UserTypes.ADMIN.toLowerCase() : UserTypes.CUSTOMER.toLowerCase())
            refreshListHandler({
                container: true,
                activities: true
            })
        } catch (error) {

        }
    }

    const openDeleteFormHandler = (e: any) => {
        e.domEvent.stopPropagation()
        setDeleteConfirmationVisible(true)
        // deleteHandler()
    }

    const closeDeleteFormHandler = () => setDeleteConfirmationVisible(false)

    const formActivityDropDownGenerator = () => {
        return (
            <Menu className="activity-form-menu" mode="vertical">
                <Menu.Item className="change-form"
                    onClick={openDateFormHandler}
                >
                    <i className="icon-calendar"></i>
                    Change Date
                </Menu.Item>
                <Menu.Item className="delete-form"
                    onClick={openDeleteFormHandler}
                >
                    <i className="icon-delete"></i>
                    Delete Form
                </Menu.Item>
            </Menu>
        )
    }

    const handleMenuOpenHandler = (e: any) => {
        e.stopPropagation()
    }

    const downloadHandler = (e: any) => {
        e.stopPropagation()
        if (!activity?.id) return
        downloadAllActivityItemImages(isAdmin ? 'admin' : 'customer', activity.id)
    }

    return <Row className={`container-form__header ${activity?.activityType}`} gutter={20}>
        <Col className="container-form__header--details">
            <p className="container-form__header--icon">
                <i className={`icon-${activity?.activityType}`} />
            </p>
            <div>
                <p className="form-type">{activity?.activityType} Form</p>
                <p className="form-detail">
                    <span>{activity?.activityUid}</span>
                    <hr />
                    <span
                        className="form-detail--date">{activity?.activityDate ? moment(activity.activityDate).format("DD-MM-YYYY") : ""}
                        {/* {isActivityValid && <i className="icon-edit" onClick={e => {
                            e.stopPropagation()
                            openDateForm()
                        }} />} */}
                    </span>
                </p>
            </div>
        </Col>
        <Col>
            <span className={`status ${activity?.activityStatus}`}>{activity?.activityStatus?.replaceAll('_', ' ')}</span>
        </Col>
        {isActivityValid && (submitting
            ? <span className="container-form--download"><LoadingOutlined /></span>
            : <Tooltip overlay="Download form images"><img src={DownloadIcon} onClick={downloadHandler} className="container-form--download" /></Tooltip>
        )}
        {isActivityValid && isAdmin && <Dropdown overlay={formActivityDropDownGenerator} trigger={['click']} className="container-form--menu">
            <EllipsisOutlined onClick={handleMenuOpenHandler} />
        </Dropdown>}
        <ConfirmationModal
            buttonLoading={activityLoading}
            icon={<i className="icon-delete"></i>}
            visible={deleteConfirmationVisible}
            title={`Are you sure to delete the ${convertToTitleCase(activity?.activityType || '')} Form `}
            name={activity?.activityUid}
            closeHandler={closeDeleteFormHandler}
            proceedHandler={deleteHandler} />
    </Row>
}

export default ContainerFormHeader