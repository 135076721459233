import { Carousel, Col, Row } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import React, { useEffect, useState } from "react";
import "./containerCarousel.scss";
import PreviousIcon from "./../../../assets/Multicolor\ icons\ -\ SVG/previous\ blac.svg"
import NextIcon from "./../../../assets/Multicolor\ icons\ -\ SVG/next black.svg"
import { Attachment } from "../../../models/container.model";
import { LoadingOutlined } from "@ant-design/icons";

interface AppCarouselProps {
    attachments: Attachment[]
    title: string
    loading: boolean
    closeHandler: () => void
    downloadHandler: () => void
}

const AppCarousel = (props: AppCarouselProps) => {

    const {
        closeHandler,
        attachments,
        downloadHandler,
        loading,
    } = props

    const [currentIndex, setCurrentIndex] = useState<number>(0)


    let carouselRef: CarouselRef | null = null

    const handleNext = () => {
        carouselRef?.next()
        setCurrentIndex(prev => prev + 1 >= attachments.length ? 0 : prev + 1)
    }

    const handlePrev = () => {
        carouselRef?.prev()
        setCurrentIndex(prev => prev - 1 < 0 ? attachments.length - 1 : prev - 1)
    }

    return <div className="container-carousel" onKeyUp={(e) => {
        if (e.key === 'Escape')
            closeHandler()
        else if (e.key === 'ArrowLeft')
            handlePrev()
        else if (e.key === 'ArrowRight')
            handleNext()
    }}>
        <Row className="container-carousel--actions">
            <Col>
                <i className="icon-back"
                    onClick={closeHandler}
                ></i>
                <i className="icon-image"></i>
                <span >{attachments[currentIndex]?.attachmentType?.replaceAll("_", " ")}</span>
            </Col>
            <Col>
                {!loading
                    ? <i className="icon-download"
                        onClick={downloadHandler}></i>
                    : <LoadingOutlined />}
            </Col>
        </Row>
        <Carousel
            dots={false}
            ref={(ref) => carouselRef = ref}>
            {/* {attachments.map((attachment, i) => */}
            <img
                className="carousel-image"
                key={(attachments[currentIndex]?.attachmentType || '') + (attachments[currentIndex]?.attachmentUrl || '')}
                src={attachments[currentIndex]?.attachmentUrl}
                alt={attachments[currentIndex]?.attachmentType} />
            {/* )} */}
        </Carousel>
        {currentIndex !== 0 && <img
            src={PreviousIcon}
            className="carousel-nav prev-item"
            alt="previous"
            onClick={handlePrev}
        />}
        {currentIndex !== attachments?.length - 1 && <img
            src={NextIcon}
            className="carousel-nav next-item"
            alt="next"
            onClick={handleNext}
        />}

    </div>
}

export default AppCarousel