import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import containerReducer, { ContainerState } from "./containerReducer";

export interface RootReducerProps {
    auth: AuthState,
    container: ContainerState
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    container: containerReducer
});

export default RootReducer;