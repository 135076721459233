import React, { FC, useEffect, useRef, useState } from "react";
import UIModal from "../../../shared/components/UIModal";
import { Checkbox, Col, Form, Radio, Row, Tabs } from 'antd';
import "./repairForm.scss"
import { placeholder } from "@babel/types";
import { ErrorMessage, Field, Formik, FormikHelpers } from "formik";
import InputField from "../../../shared/components/InputField";
import { Repair } from "../../../models/repair.model";
import { ApiRoutes } from '../../../routes/routeConstants/apiRoutes';
import MetaService from "../../../services/MetaService/meta.service";
import { MetaModel } from "../../../models/meta.model";
import DropdownField from "../../../shared/components/DropdownField";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { firstPaneValidationSchema, secondPaneValidationSchema, thirdPaneValidationSchema } from "./repairForm.validation";
import RepairService from "../../../services/RepairService/repair.service";
import CustomerService from "../../../services/CustomerService/customer.service";

const { TabPane } = Tabs;
interface RepairFormProps {
    visible: boolean
    isCustomerForm?: boolean
    data?: Repair
    versionId: string
    closeHandler: (response?: true) => void
}

const RepairForm: FC<RepairFormProps> = (props) => {

    const {
        visible,
        closeHandler,
        versionId,
        data,
        isCustomerForm = false,
    } = props

    const [activeKey, setActiveKey] = useState<number>(1)
    const [currentTab, setCurrentTab] = useState<number>(1)
    const [formData, setFormData] = useState<Repair>(new Repair())
    const [repairAreas, setRepairAreas] = useState<MetaModel[]>([])
    const [damagedAreas, setDamagedAreas] = useState<MetaModel[]>([])
    const [repairTypes, setRepairTypes] = useState<MetaModel[]>([])
    const [components, setComponents] = useState<MetaModel[]>([])
    const [comps, setComps] = useState<MetaModel[]>([])
    const [events, setEvents] = useState<MetaModel[]>([])
    const [dams, setDams] = useState<MetaModel[]>([])
    const [reps, setReps] = useState<MetaModel[]>([])
    const [units, setUnits] = useState<MetaModel[]>([])
    const [repairModes, setRepairModes] = useState<MetaModel[]>([])
    const [modeNumbers, setModeNumbers] = useState<MetaModel[]>([])
    const [lengths, setLength] = useState<MetaModel[]>([])
    const [widths, setWidths] = useState<MetaModel[]>([])

    const firstPaneForm = useRef<any>(null)
    const secondPaneForm = useRef<any>(null)
    const thirdPaneForm = useRef<any>(null)

    const {
        fetchMeta,
    } = MetaService()

    const {
        submitting,
        createRepairItem,
        editRepairItem,
        fetchRepairUid,
    } = RepairService()

    const {
        submitting: customerSubmitting,
        editCustomerRepairItem,
    } = CustomerService()

    useEffect(() => {
        fetchMeta(ApiRoutes.REPAIR_AREAS, setRepairAreas, "container_repair_areas")
        fetchMeta(ApiRoutes.DAMAGED_AREAS, setDamagedAreas, "container_damaged_areas")
        fetchMeta(ApiRoutes.REPAIR_TYPES, setRepairTypes, "repair_types")
        fetchMeta(ApiRoutes.COMPS, setComps, "comps")
        fetchMeta(ApiRoutes.COMPONENTS, setComponents, "components")
        fetchMeta(ApiRoutes.DAMS, setDams, "dams")
        fetchMeta(ApiRoutes.REPS, setReps, "reps")
        fetchMeta(ApiRoutes.EVENTS, setEvents, "events")
        fetchMeta(ApiRoutes.UNITS, setUnits, "units")
        fetchMeta(ApiRoutes.WIDTH, setWidths, "widths")
        fetchMeta(ApiRoutes.LENGTH, setLength, "lengths")
        fetchMeta(ApiRoutes.REPAIR_MODES, setRepairModes, "repair_modes")
        fetchMeta(ApiRoutes.MODE_NUMBERS, setModeNumbers, "mode_numbers")

    }, [])

    useEffect(() => {
        if (visible && !data) {
            // fetchRepairUid(versionId)
            //     .then(uid => {
            //         firstPaneForm.current?.setFieldValue('repairUid', uid)
            //         firstPaneForm.current?.setFieldTouched('repairUid')
            //     })
            //     .catch()
        } else {
            setActiveKey(1)
            setCurrentTab(1)
        }
    }, [visible])

    useEffect(() => {
        const newData = {
            ...data,
            containerDamagedAreaId: data?.containerDamagedArea?.id,
            containerRepairAreaId: data?.containerRepairArea?.id,
            repairTypeId: data?.repairType?.id,
            nonMearskMaterialCost: data?.nonMearskMaterialCostDollars,
            compId: data?.comp?.id,
            damId: data?.dam?.id,
            repId: data?.rep?.id,
            eventId: data?.event?.id,
            componentId: data?.component?.id,
            unitId: data?.unit?.id,
            len: data?.lengthMeta?.id,
            width: data?.widthMeta?.id,
            mearskUnitMaterialCost: data?.mearskUnitMaterialCostDollar,
            mearskMaxMaterialCost: data?.mearskMaxMaterialCostDollars,
            repairModeId: data?.repairMode?.id,
            modeNumberId: data?.modeNumber?.id,
        } as Repair

        setFormData({ ...newData })
    }, [data])

    const discardHandler = (response?: true) => {
        setFormData(new Repair())
        setActiveKey(1)
        setCurrentTab(1)
        closeHandler(response)
    }

    const generatePaneTemplate = (tab: number) => {
        const tabs = [
            "Repair Details",
            "Common Details",
            "Merc+ Details"
        ]
        return <span>
            {
                activeKey > tab
                    ? <i className="icon-done green-icon"></i>
                    : <Radio disabled></Radio>
            }
            {tabs[tab - 1]}
        </span>
    }

    const firstPaneSubmitHandler = (values: Repair, helpers: FormikHelpers<any>) => {
        setFormData({ ...values })
        setActiveKey(2)
        setCurrentTab(2)
    }
    const secondPaneSubmitHandler = (values: Repair, helpers: FormikHelpers<any>) => {
        setFormData({ ...values })
        setActiveKey(3)
        setCurrentTab(3)
    }
    const thirdPaneSubmitHandler = async (values: Repair, helpers: FormikHelpers<any>) => {
        try {
            if (isCustomerForm) {
                await editCustomerRepairItem(values)
            } else {
                if (values.id) {
                    await editRepairItem(values, +versionId)
                } else {
                    await createRepairItem(values, +versionId)
                }
            }
            discardHandler(true)
        } catch (err) {
            console.log(err);

        }
    }

    const FirstPaneFormTemplate = () => {
        return <Formik
            initialValues={formData}
            onSubmit={firstPaneSubmitHandler}
            validationSchema={firstPaneValidationSchema}
            enableReinitialize
            innerRef={firstPaneForm}
        >
            {({ setFieldValue, values, setFieldTouched }) => (
                <Form className="repair-form">
                    <Row gutter={[25, 25]} >
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Repair ID</div>
                            <InputField
                                type="number"
                                name="repairUid"
                                placeholder="Repair ID"
                                value={values.repairUid}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Container Repair Area'}
                                name='containerRepairAreaId'
                                options={repairAreas}
                                value={values.containerRepairAreaId || values.containerRepairArea?.id}
                                onChange={(value) => {
                                    setFieldValue('containerRepairAreaId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Container Damaged Area'}
                                name='containerDamagedAreaId'
                                options={damagedAreas}
                                value={values.containerDamagedAreaId || values.containerDamagedArea?.id}
                                onChange={(value) => {
                                    setFieldValue('containerDamagedAreaId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Repair Type'}
                                name='repairTypeId'
                                options={repairTypes}
                                value={values.repairTypeId || values.repairType?.id}
                                onChange={(value) => {
                                    setFieldValue('repairTypeId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title="Length"
                                name="len"
                                value={values.len || values.lengthMeta?.id}
                                options={lengths}
                                onChange={(value) => {
                                    setFieldValue('len', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title="Width"
                                name="width"
                                options={widths}
                                value={values.width || values.widthMeta?.id}
                                onChange={(value) => {
                                    setFieldValue('width', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Units'}
                                name="unitId"
                                options={units}
                                value={values.unitId}
                                onChange={(value) => {
                                    setFieldValue('unitId', value)
                                }}
                            />
                        </Col>
                        <Col span={24} className="repair-form__actions">
                            <ButtonComponent
                                text="Discard"
                                type="default"
                                onClick={() => discardHandler()}
                            />
                            <ButtonComponent
                                text="Proceed"
                                type="primary"
                                onClick={() => {
                                    firstPaneForm.current?.submitForm()
                                    setFieldTouched('repairUid')
                                    setFieldTouched('len')
                                    setFieldTouched('width')
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>

    }

    const SecondPaneFormTemplate = () => {
        return <Formik
            initialValues={formData}
            onSubmit={secondPaneSubmitHandler}
            innerRef={secondPaneForm}
            validationSchema={secondPaneValidationSchema}
            enableReinitialize
        >
            {({ setFieldValue, values, resetForm, errors }) => (
                <Form className={`repair-form ${values.isNonMearskNotApplicable && 'disabled'}`}>
                    <Row className="repair-row">
                        <Col>
                            <Field name="isNonMearskNotApplicable">
                                {({ field }: any) => (<Checkbox
                                    {...field}
                                    name="isNonMearskNotApplicable"
                                    checked={values.isNonMearskNotApplicable}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            resetForm()
                                        }
                                        setFieldValue('isNonMearskNotApplicable', event.target.checked)
                                    }}
                                >N/A</Checkbox>
                                )}
                            </Field>
                        </Col>
                        <ErrorMessage name="isNonMearskNotApplicable"></ErrorMessage>
                    </Row>
                    <p className="repair-form--title">Cost Details</p>
                    <Row gutter={[25, 25]} className="repair-row">
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Hours</div>
                            <InputField
                                type="number"
                                name="nonMearskHours"
                                placeholder="Enter"
                                value={values.nonMearskHours}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Material Cost</div>
                            <InputField
                                type="number"
                                name="nonMearskMaterialCost"
                                placeholder="0$"
                                value={values.nonMearskMaterialCost}
                            />
                        </Col>
                    </Row>
                    <p className="repair-form--title">Customer Related Details</p>
                    <Row gutter={[25, 25]}>
                        {/* <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Container Section'}
                                disabled
                                name='containerRepairAreaId'
                                options={repairAreas}
                                value={values.containerRepairAreaId}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Damaged Area'}
                                disabled
                                name='containerDamagedAreaId'
                                options={damagedAreas}
                                value={values.containerDamagedAreaId}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <div className="form-label"></div>
                            <DropdownField
                                title={'Repair Type'}
                                disabled
                                name='repairTypeId'
                                options={repairTypes}
                                value={values.repairTypeId}
                            />
                        </Col> */}
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'COMP'}
                                name='compId'
                                options={comps}
                                value={values.compId}
                                onChange={(value) => {
                                    setFieldValue('compId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'DAM'}
                                name='damId'
                                options={dams}
                                value={values.damId}
                                onChange={(value) => {
                                    setFieldValue('damId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'REP'}
                                name='repId'
                                options={reps}
                                value={values.repId}
                                onChange={(value) => {
                                    setFieldValue('repId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Component'}
                                name='componentId'
                                options={components}
                                value={values.componentId}
                                onChange={(value) => {
                                    setFieldValue('componentId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Event'}
                                name='eventId'
                                options={events}
                                value={values.eventId}
                                onChange={(value) => {
                                    setFieldValue('eventId', value)
                                }}
                            />
                        </Col>
                        <Col span={24} className="repair-form__actions">
                            <ButtonComponent
                                text="Discard"
                                type="default"
                                onClick={() => discardHandler()}
                            />
                            <ButtonComponent
                                text="Proceed"
                                type="primary"
                                onClick={() => secondPaneForm.current.submitForm()}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>

    }

    const ThirdPaneFormTemplate = () => {
        return <Formik
            initialValues={formData}
            onSubmit={thirdPaneSubmitHandler}
            innerRef={thirdPaneForm}
            validationSchema={thirdPaneValidationSchema}
            enableReinitialize
        >
            {({ setFieldValue, values, resetForm, errors, setFieldError }) => (
                <Form className={`repair-form ${values.isMearskNotApplicable && 'disabled'}`}>
                    <Row className="repair-row">
                        <Col>
                            <Field name="isMearskNotApplicable">
                                {({ field }: any) => (<Checkbox
                                    {...field}
                                    name="isMearskNotApplicable"
                                    checked={values.isMearskNotApplicable}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            resetForm()
                                        }
                                        setFieldValue('isMearskNotApplicable', event.target.checked)
                                    }}
                                >N/A</Checkbox>
                                )}
                            </Field>
                        </Col>
                        {values.isNonMearskNotApplicable &&
                            values.isMearskNotApplicable &&
                            <Col span={24} className="error-message">Alteast one billing details is required</Col>
                        }
                        <ErrorMessage name="isMearskNotApplicable"></ErrorMessage>
                    </Row>
                    <p className="repair-form--title">Cost Details</p>
                    <Row gutter={[25, 25]} className="repair-row">
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Unit Mat. Cost</div>
                            <InputField
                                type="number"
                                name="mearskUnitMaterialCost"
                                placeholder="Enter"
                                value={values.mearskUnitMaterialCost}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Hours Per Unit</div>
                            <InputField
                                type="number"
                                name="mesrskHoursPerUnit"
                                placeholder="Enter"
                                value={values.mesrskHoursPerUnit}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Units</div>
                            <InputField
                                type="number"
                                name="mearskUnits"
                                placeholder="Enter"
                                value={values.mearskUnits}
                            />
                        </Col>
                    </Row>
                    <p className="repair-form--title">Customer Related Details</p>
                    <Row gutter={[25, 25]}>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Repair Mode'}
                                options={repairModes}
                                name="repairModeId"
                                value={values.repairModeId}
                                onChange={(value) => {
                                    setFieldValue('repairModeId', value)
                                }}
                            />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <DropdownField
                                title={'Mode Number'}
                                options={modeNumbers}
                                name="modeNumberId"
                                value={values.modeNumberId}
                                onChange={(value) => {
                                    setFieldValue('modeNumberId', value)
                                }} />
                        </Col>
                        <Col span={12} className="repair-form__field">
                            <div className="form-label">Repair Code</div>
                            <InputField
                                type="text"
                                name="repairCode"
                                placeholder="Enter"
                                value={values.repairCode}
                            />
                        </Col>
                        <Col span={24} className="repair-form__actions">
                            <ButtonComponent
                                text="Discard"
                                type="default"
                                onClick={() => discardHandler()}
                                disabled={submitting || customerSubmitting}
                            />
                            <ButtonComponent
                                text={`${!data ? 'Add' : 'Edit'} Repair`}
                                type="primary"
                                className="success"
                                loading={submitting || customerSubmitting}
                                onClick={() => {
                                    if (values.isNonMearskNotApplicable && values.isMearskNotApplicable) {
                                        return
                                    }
                                    thirdPaneForm.current.submitForm()
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>

    }

    return <UIModal
        visible={visible}
        onCancel={() => closeHandler()}
        footer={false}
        title={`${!data ? 'Add' : 'Edit'} Repair Part`}
        className="repair-form-modal"
    >
        <Tabs activeKey={String(currentTab)} onChange={e => {
            if (+e < activeKey)
                setCurrentTab(Number(e))
        }}>
            <TabPane
                tab={generatePaneTemplate(1)}
                key="1">
                {FirstPaneFormTemplate()}
            </TabPane>
            <TabPane
                tab={generatePaneTemplate(2)}
                key="2">
                {SecondPaneFormTemplate()}
            </TabPane>
            <TabPane
                tab={generatePaneTemplate(3)}
                key="3">
                {ThirdPaneFormTemplate()}
            </TabPane>
        </Tabs>
    </UIModal>
}


export default RepairForm