import { Row, Col, Dropdown, Menu } from "antd";
import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { MetaModel } from "../../../models/meta.model";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import MetaService from "../../../services/MetaService/meta.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import TableSearchFilterComponent, { FilterData } from "../../../shared/components/TableSearchFilterComponent";
import { ActivityStatus, ActivityTypes } from "../../definitions/activity.definition";

interface CustomerRepairListProps {
    exportVisible?: boolean
    exportLoading?: boolean
    imageExportVisible?: boolean
    imageExportLoading?: boolean
    isAdmin: boolean
    values?: any
    approveVisible?: boolean
    exportHandler?: (versionId?: number) => void
    exportImagesHandler?: (versionId?: number) => void
    approveClickHandler?: () => void
    filtersApplyHandler: (formikValues: FormikValues) => void
}

const ContainerTableActions = (props: CustomerRepairListProps) => {

    const {
        isAdmin,
        exportVisible = false,
        exportLoading = false,
        imageExportVisible = false,
        imageExportLoading = false,
        approveVisible = false,
        exportHandler = () => { },
        exportImagesHandler = () => { },
        approveClickHandler = () => { },
        filtersApplyHandler,
        values,
    } = props

    const [yards, setYards] = useState<MetaModel[]>([])

    const [customers, setCustomers] = useState<MetaModel[]>([])

    const filters = [
        {
            title: "Date",
            name: 'date',
            field: "date",
            options: []
        },
        {
            title: "Activity",
            name: 'activity_type',
            options: ActivityTypes
        },
        {
            title: "Status",
            name: 'activity_status',
            options: ActivityStatus
        },
        {
            title: "Yard",
            name: 'yard_id',
            options: yards
        },
        {
            title: "Customer",
            name: 'customer_id',
            disabled: !isAdmin,
            options: customers
        },
    ] as FilterData[]

    const {
        fetchMeta,
    } = MetaService()

    useEffect(() => {
        fetchMeta(ApiRoutes.YARDS, setYards, 'yards')
        isAdmin && fetchMeta(ApiRoutes.ALL_ACTIVE_CUSTOMERS, customerDataMapper, 'customers')
    }, [])

    const customerDataMapper = (customers: MetaModel[]) => {
        setCustomers(customers.map(customer => ({ value: customer.value, label: customer.customerName })))
    }

    return (
        <Row className="container__table-header" justify="space-between" wrap={false}>
            <Col>
                <Row wrap={false} gutter={10}>
                    <Col>
                        <TableSearchFilterComponent
                            name="containers-list-filters"
                            filterHandler={filtersApplyHandler}
                            placeholder="Search container by number"
                            filters={filters}
                            values={values}
                        />
                    </Col>
                    {exportVisible && <Col>
                        <Row gutter={10} wrap={false}>
                            <Col>
                                <ButtonComponent
                                    className="search-bar--trigger"
                                    iconClass="icon-export"
                                    text="Export"
                                    loading={exportLoading}
                                    onClick={exportHandler}
                                />
                            </Col>
                        </Row>
                    </Col>}
                    {imageExportVisible && <Col>
                        <Row gutter={10} wrap={false}>
                            <Col>
                                <ButtonComponent
                                    className="search-bar--trigger"
                                    iconClass="icon-export"
                                    text="Export Images"
                                    loading={imageExportLoading}
                                    onClick={exportImagesHandler}
                                />
                            </Col>
                        </Row>
                    </Col>}
                </Row>
            </Col>
            {approveVisible && <Col>
                <ButtonComponent
                    type="primary"
                    text="Approve Quote"
                    onClick={approveClickHandler}
                />
            </Col>}
        </Row>

    )
}

export default ContainerTableActions