import { Row, Col } from "antd";
import { FormikHelpers, Formik, Form, Field, FormikErrors } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ContainerAttachmentEnum } from "../../../enums/containerAttachment.enum";
import { UserTypes } from "../../../enums/userTypes.enum";
import { Attachment, Container } from "../../../models/container.model";
import { AttachmentModel, MetaModel } from "../../../models/meta.model";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import ContainerService from "../../../services/ContainerService/container.service";
import MetaService from "../../../services/MetaService/meta.service";
import { AttachmentComponent } from "../../../shared/components/AttachmentComponent";
import DatePickerComponent from "../../../shared/components/DatePickerComponent";
import DropdownField from "../../../shared/components/DropdownField";
import ErrorMessage from "../../../shared/components/Error";
import InputField from "../../../shared/components/InputField";
import UIModal from "../../../shared/components/UIModal";
import { convertToTitleCase } from "../../../shared/utils/convertToTitleCase";
import { containerValidationSchema } from "./containerForm.validation";

interface ContainerFormProps {
    visible: boolean
    isAdmin: boolean
    data?: Container
    closeHandler: (success?: boolean) => void
}

const containerAttachmentTypes = [
    ContainerAttachmentEnum.DOOR,
    ContainerAttachmentEnum.LEFTSIDE,
    ContainerAttachmentEnum.RIGHTSIDE,
    ContainerAttachmentEnum.FRONTSIDE,
    ContainerAttachmentEnum.INTERIOR,
    ContainerAttachmentEnum.UNDERSIDE,
    ContainerAttachmentEnum.ROOF,
    ContainerAttachmentEnum.CSCPLATENUMBER,
]

const containerAttachmentLabels = [
    "Door photo including container number",
    "Left Side Photo",
    "Right Side Photo",
    "Front Side Photo",
    "Interior Photo",
    "Underside Photo",
    "Roof Photo",
    "CSC Plate Photo"
]

const ContainerForm = (props: ContainerFormProps) => {

    const {
        data,
        isAdmin,
        visible,
        closeHandler,
    } = props

    const [initialValues, setInitialValues] = useState<Container>({
        ...new Container()
    })

    const [yards, setYards] = useState<MetaModel[]>([])

    const [containerTypes, setContainerTypes] = useState<MetaModel[]>([])

    const [customers, setCustomers] = useState<MetaModel[]>([])

    const [lengths, setLength] = useState<MetaModel[]>([])

    const [widths, setWidths] = useState<MetaModel[]>([])

    const formikRef = useRef<any>(null)

    const {
        submitting,
        createContainer,
        updateContainer,
    } = ContainerService()

    const {
        fetchMeta,
    } = MetaService()

    useEffect(() => {
        if (!visible) return
        fetchMeta(ApiRoutes.YARDS, setYards, 'yards')
        fetchMeta(ApiRoutes.CONTAINER_TYPES, setContainerTypes, 'container_types')
        fetchMeta(ApiRoutes.ALL_ACTIVE_CUSTOMERS, customerDataMapper, 'customers')
        fetchMeta(ApiRoutes.CONTAINER_WIDTH, setWidths, "container_heights")
        fetchMeta(ApiRoutes.CONTAINER_LENGTH, setLength, "container_lengths")
    }, [visible])

    useEffect(() => {
        if (data) {
            const attachments: Attachment[] = []
            containerAttachmentTypes.forEach((attachmentType, i) => {
                const availableAttachments = data.containerAttachments?.filter(att => att.attachmentType === attachmentType)
                if (availableAttachments)
                    attachments.push(...availableAttachments);
                // if()
                // return {
                //     attachmentType,
                //     attachmentId: reqAttachment?.attachmentId,
                //     attachmentUrl: reqAttachment?.attachmentUrl
                // }
            })
            setInitialValues({
                ...data,
                containerTypeId: data.containerType?.id,
                customerId: data.customer?.id,
                yardId: data.yard?.id,
                containerLengthId: data.containerLength?.id,
                containerHeightId: data.containerHeight?.id,
                containerAttachments: attachments
            })
        }
    }, [data])

    const customerDataMapper = (customers: MetaModel[]) => {
        setCustomers(customers.map(customer => ({ value: customer.value, label: customer.customerName })))
    }

    const getAttachmentErrorMessages = (errors: FormikErrors<any>, index: number): string => {
        const attachmentsErrors = errors[`containerAttachments`] as any[]
        if (attachmentsErrors)
            return attachmentsErrors[index] && convertToTitleCase(containerAttachmentTypes[index].replaceAll("_", " ")) + attachmentsErrors[index]['attachmentId'] as string
        return ''
    }

    const submitHandler = async (values: Container, helpers: FormikHelpers<any>) => {
        let response;
        if (values.id)
            response = await updateContainer(values, isAdmin ? UserTypes.ADMIN : UserTypes.CUSTOMER)
        else
            response = await createContainer(values, isAdmin ? UserTypes.ADMIN : UserTypes.CUSTOMER)
        if (response)
            closeHandler(true)
    }

    const containerNumberPrepopulation = (value: string) => {
        let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
        if (formattedValue.length > 4)
           formattedValue = formattedValue.substr(0, 4) + " " + formattedValue.substr(4)
        if (formattedValue.length > 11)
            formattedValue = formattedValue.substr(0, 11) + "-" + formattedValue.substr(11)
        if (formattedValue.length > 13)
            return formattedValue.substr(0, 13).toUpperCase()
        return formattedValue.toUpperCase()
    }

    //without space and dash
    // const containerNumberPrepopulation = (value: string) => {
    //     let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
    //     if (formattedValue.length > 4)
    //        //formattedValue = formattedValue.substr(0, 4) + " " + formattedValue.substr(4)
    //     if (formattedValue.length > 11)
    //        // formattedValue = formattedValue.substr(0, 11) + "-" + formattedValue.substr(11)
    //     if (formattedValue.length > 11)
    //         return formattedValue.substr(0, 11).toUpperCase()
    //     return formattedValue.toUpperCase()
    // }

    // const removeAttachmentHandler = (url: string, attachments: Attachment[], setFieldValue: (name: string, value: any) => void, fieldName: string) => {
    //     const filteredAttachments = attachments.filter(image => image.attachmentUrl !== url)
    //     setFieldValue(fieldName, filteredAttachments)
    // }

    return <UIModal
        visible={visible}
        title={data?.id ? "Edit Container" : "Add New Container"}
        okText="Proceed"
        onOk={() => formikRef.current?.submitForm()}
        onCancel={() => closeHandler()}
        loading={submitting}
    >
        <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            enableReinitialize
            innerRef={formikRef}
            validationSchema={containerValidationSchema}
        >
            {({ setFieldValue, values, errors, validateField, }) => {
                return (
                    <Form>
                        <Row gutter={[20, 20]}>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Yard Name'}
                                    name='yardId'
                                    options={yards}
                                    value={values.yardId}
                                    onChange={(value) => {
                                        setFieldValue('yardId', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Container Number</div>
                                <InputField
                                    type="text"
                                    name="containerNumber"
                                    placeholder="ABCD 123456-1" //"ABCD1234561"
                                    value={values.containerNumber}
                                    onChange={(e) => {
                                        setFieldValue("containerNumber", containerNumberPrepopulation(e.target.value));
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Customer'}
                                    name='customerId'
                                    options={customers}   //options={cutomers.filter(customre => customer.status)}
                                    value={values.customerId}
                                    onChange={(value) => {
                                        setFieldValue('customerId', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Container Owner Name</div>
                                <InputField
                                    type="text"
                                    name="containerOwnerName"
                                    placeholder="Enter"
                                    value={values.containerOwnerName}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Submitter Initials</div>
                                <InputField
                                    type="text"
                                    name="submitterInitials"
                                    placeholder="Enter"
                                    value={values.submitterInitials}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Container Length'}
                                    name="containerLengthId"
                                    options={lengths}
                                    value={values.containerLengthId || values.containerLength?.id}
                                    onChange={(value) => {
                                        setFieldValue('containerLengthId', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Container Height'}
                                    name="containerHeightId"
                                    options={widths}
                                    value={values.containerHeightId || values.containerHeight?.id}
                                    onChange={(value) => {
                                        setFieldValue('containerHeightId', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Container Type'}
                                    name='containerTypeId'
                                    options={containerTypes}
                                    value={values.containerTypeId}
                                    onChange={(value) => {
                                        setFieldValue('containerTypeId', value)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DatePickerComponent
                                    name="manufactureYear"
                                    picker="year"
                                    title="Container manufacture year"
                                    placeHolder="Enter"
                                    disableDates="future"
                                    value={values.manufactureYear}
                                    onChange={(value, dateString) => setFieldValue("manufactureYear", dateString)}
                                />
                            </Col>
                            {/* <Col span={24} className="container-form__field">
                                <div className="form-label">Location</div>
                                <InputField
                                    type="text"
                                    name="location"
                                    placeholder="DBXN"
                                    value={values?.location || ''}
                                />
                            </Col> */}
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Comments</div>
                                <InputField
                                    type="textarea"
                                    name="comments"
                                    placeholder="Enter"
                                    value={values.comments || ''}
                                />
                            </Col>
                            {containerAttachmentLabels.map((label, i) =>
                                <Col span={24} className="container-form__field" key={label}>
                                    <div className="form-label">{label}</div>
                                    <Field name={`containerAttachments[${i}]`}>
                                        {() =>
                                            <Fragment>
                                                <AttachmentComponent
                                                    images={values.containerAttachments
                                                        ?.filter(att => att && att?.attachmentType === containerAttachmentTypes[i])
                                                        ?.map(att => Object.assign(new AttachmentModel(), { ...att }))
                                                    }
                                                    multiple
                                                    className="image-only"
                                                    onRemove={(attachment) => setFieldValue("containerAttachments", values.containerAttachments?.filter(image => image.id !== attachment.id))}
                                                    onUpload={(response) => {
                                                        if (!response?.length) return
                                                        const attachments = response.map(resp => {
                                                            return Object.assign(new Attachment(), { ...resp, attachmentId: resp.id, attachmentType: containerAttachmentTypes[i] })
                                                        })
                                                        setFieldValue(`containerAttachments`, [...(values.containerAttachments || []), ...attachments])
                                                    }}
                                                    accept="image/*"
                                                ></AttachmentComponent>
                                                <ErrorMessage message={getAttachmentErrorMessages(errors, i)} />
                                            </Fragment>
                                        }
                                    </Field>
                                </Col>)}
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    </UIModal>
}

export default ContainerForm