import { Skeleton } from "antd";
import Button from "antd/lib/skeleton/Button";
import React from "react";
import "./tableLoader.scss"
interface TableLoaderProps {
    length?: number
}
const TableLoader = (props: TableLoaderProps) => {
    const {
        length = 10,
    } = props

    const items = new Array(length).fill('')

    return <div className="table-loader__container">
        {
            items.map((item, i) => <Skeleton.Button key={i} active={true} size="small" shape="round" />)
        }
    </div>
}

export default TableLoader