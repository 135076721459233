import { Field } from "formik";
import { Col, Row, Switch } from 'antd';
import React from "react";
import "./statusField.scss";

interface StatusFieldProps {
    checked: boolean
    onChange: (isChecked: boolean) => void
}

const StatusField = (props: StatusFieldProps) => {

    const {
        onChange,
        checked,
    } = props

    return (
        <div className={`status-toggle__container ${checked ? 'active' : ''}`}>
            <Row justify="space-between">
                <Col>
                    {checked ? "Active" : "Inactive"}
                </Col>
                <Col>
                    <Switch
                        onChange={onChange}
                        defaultChecked={checked} />
                </Col>
            </Row>
        </div>
    )
}

export default StatusField