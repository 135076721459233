import { Repair, RepairVerison } from './../../models/repair.model';
import { Customer } from './../../models/customer.model';
import { REPAIR_LIST } from '../../routes/routeConstants/appRoutes';
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import { AUTHENTICATED, UNAUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { fileDownloader, fileExtensionGenerator } from '../../shared/utils/fileDownloader';
import { Pagination } from '../../models/pagination.model';
import { convertJSONToFormData } from '../../shared/utils/dataFormatConverter';
import { REGEX } from '../../shared/utils/regex';

const CustomerService = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [submitting, setSubmitting] = useState(false);

	const [customers, setCustomers] = useState<Customer[]>([]);

	const [customersMeta, setCustomersMeta] = useState<Pagination>({})

	const [customerVersions, setCustomerVersions] = useState<RepairVerison[]>([]);

	const [customerRepairItems, setCustomerRepairItems] = useState<Repair[]>([]);

	const [customerRepairItemsMeta, setCustomerRepairItemsMeta] = useState<Pagination>({})

	const fetchCustomers = async (params?: {
		search_text?: string,
		full_name?: number,
		id?: number,
	}) => {
		setLoading(true)
		try {
			const API_URL = ApiRoutes.CUSTOMERS
			const response = await axiosInstance.get(API_URL, { params })
			const data = deserialize(Customer, response.data['customers']) as Customer[]
			const meta = deserialize(Pagination, response.data['meta'])
			setCustomers(data);
			setCustomersMeta(meta)
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	const fetchCustomerRepairList = async (customer_id: string) => {
		setLoading(true)
		try {
			const params = { customer_id }
			const API_URL = ApiRoutes.REPAIRS_LIST_CUSTOMER
			const response = await axiosInstance.get(API_URL, { params })
			const data = deserialize(RepairVerison, response.data['customer_repair_lists']) as RepairVerison[]
			setCustomerVersions(data.map(item => ({ ...item, name: item.name?.replace(REGEX.DATE_MATCH_TEMP, "") })))
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	const createCustomerRepairList = async (customer_id: string) => {
		setLoading(true)
		try {
			const params = { customer_id }
			const API_URL = ApiRoutes.REPAIRS_LIST_CUSTOMER
			const response = await axiosInstance.post(API_URL, {}, { params })
			fetchCustomerRepairList(customer_id)
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	const createCustomer = async (customer: Customer) => {
		setSubmitting(true)
		try {
			const payload = {
				customer: serialize(Customer, customer)
			}
			const API_URL = ApiRoutes.CUSTOMERS
			const response = await axiosInstance.post(API_URL, payload)
			const data = deserialize(Customer, response.data['customer'])
			return data
		} catch (error) {
			console.log(error);

		} finally {
			setSubmitting(false)
		}
	}

	const updateCustomer = async (customer: Customer) => {
		setSubmitting(true)
		try {
			const payload = {
				customer: serialize(Customer, customer)
			}
			const API_URL = ApiRoutes.CUSTOMERS_SPECIFIC.replace(":customerId", String(customer?.id))
			const response = await axiosInstance.put(API_URL, payload)
			const data = deserialize(Customer, response.data['customer'])
			return data
		} catch (error) {
			console.log(error);

		} finally {
			setSubmitting(false)
		}
	}

	const fetchSingleCustomer = async (customerId: string) => {
		setLoading(true)
		try {
			const API_URL = ApiRoutes.CUSTOMERS_SPECIFIC.replace(":customerId", customerId)
			const response = await axiosInstance.get(API_URL)
			const data = deserialize(Customer, response.data['customer'])
			return data
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false)
		}
	}

	const fetchCustomerRepairListItems = async (customerRepairListId: string, params: {
		search_text?: string,
		container_repair_area_id?: string,
		container_damaged_area_id?: string,
		repair_type?: string,
		repair_type_id?: string,
		page?: string | number,
	}) => {
		setLoading(true)
		try {
			const API_URL = ApiRoutes.REPAIRS_LIST_CUSTOMER_ITEMS.replace(":customerRepairListId", customerRepairListId)
			const response = await axiosInstance.get(API_URL, { params })
			const data = deserialize(Repair, response.data['customer_repair_list_items']) as Repair[]
			const meta = deserialize(Pagination, response.data['meta'])
			setCustomerRepairItems(data)
			setCustomerRepairItemsMeta(meta)
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false)
		}
	}


	const deleteCustomerRepairItem = (repairItemId: string) => {
		setSubmitting(true)
		return axiosInstance
			.delete(ApiRoutes.REPAIRS_LIST_CUSTOMER_DELETE_ITEMS.replace(':id', String(repairItemId)))
			.then((response) => {
			})
			.catch((error) => {
				throw error;
			})
			.finally(() => {
				setSubmitting(false)
			});
	}


	const editCustomerRepairItem = (repairItem: Repair) => {
		setSubmitting(true)
		const payload = {
			customer_repair_list_item: serialize(Repair, repairItem)
		}
		return axiosInstance
			.put(ApiRoutes.REPAIRS_LIST_CUSTOMER_DELETE_ITEMS.replace(":id", String(repairItem?.id)),
				payload,
			)
			.then((response) => {
			})
			.catch((error) => {
				throw error;
			})
			.finally(() => {
				setSubmitting(false)
			});
	}

	const downloadCustomerRepairList = (customerRepairListId: number | string) => {
		setSubmitting(true)
		return axiosInstance
			.get(ApiRoutes.REPAIRS_LIST_CUSTOMER_EXPORT.replace(":customerRepairListId", String(customerRepairListId)), { responseType: "blob" })
			.then((response) => {
				fileDownloader(response?.data, `Customer-repair-list-version-${customerRepairListId}.${fileExtensionGenerator(response.data.type)}`);
			})
			.catch((error) => {
				throw error;
			})
			.finally(() => {
				setSubmitting(false)
			});
	}


	const uploadCustomerRepairList = (uploaded_file_id: Number, customerRepairListId: number) => {
		setSubmitting(true)
		return axiosInstance
			.post(ApiRoutes.REPAIRS_LIST_CUSTOMER_IMPORT.replace(":customerRepairListId", String(customerRepairListId)), { uploaded_file_id })
			.then((response) => {
			})
			.catch((error) => {
				throw error;
			}).finally(() => {
				setSubmitting(false)
			})
	}


	const setVersionAsDefault = async (repairListId: string, customerId: string) => {
		setLoading(true)
		const params = { customer_id: customerId }
		try {
			const API_URL = generatePath(
				ApiRoutes.CUSTOMERS_REPAIRS_LIST_DEFAULT_VERSION,
				{ repairListId })
			const { data } = await axiosInstance.put(API_URL, {}, { params })
			return data
		} catch (error) {

		} finally {
			setLoading(false)
		}
	}

	return {
		loading,
		submitting,
		customers,
		customerVersions,
		customerRepairItems,
		customersMeta,
		customerRepairItemsMeta,
		createCustomer,
		fetchCustomers,
		updateCustomer,
		setVersionAsDefault,
		downloadCustomerRepairList,
		fetchSingleCustomer,
		fetchCustomerRepairList,
		createCustomerRepairList,
		uploadCustomerRepairList,
		fetchCustomerRepairListItems,
		editCustomerRepairItem,
		deleteCustomerRepairItem,
	};
};

export default CustomerService;
