import { MetaModel } from './meta.model';
import { serializable, alias, primitive, object } from 'serializr';

class RepairType {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;
}
class RepairArea {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;
}
class DamageArea {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;
}

class RepairCost {

    @serializable(alias('cents', primitive()))
    cents?: string;

    @serializable(alias('currency_iso', primitive()))
    currencyIso?: string;

}

export class Repair {
    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('uid', primitive()))
    repairUid?: string;

    @serializable(alias('non_mearsk_hours', primitive()))
    nonMearskHours?: string;

    // @serializable(alias('non_mearsk_material_cost', object(RepairCost)))
    // nonMearskMaterialCost?: RepairCost;
    @serializable(alias('non_mearsk_material_cost', primitive()))
    nonMearskMaterialCost?: number;

    @serializable(alias('non_mearsk_material_cost_dollars', primitive()))
    nonMearskMaterialCostDollars?: number;

    @serializable(alias('mearsk_hours_per_unit', primitive()))
    mesrskHoursPerUnit?: number;

    @serializable(alias('mearsk_unit_material_cost', primitive()))
    mearskUnitMaterialCost?: number;

    @serializable(alias('mearsk_unit_material_cost_dollars', primitive()))
    mearskUnitMaterialCostDollar?: number;
    // @serializable(alias('mearsk_unit_material_cost', object(RepairCost)))
    // mearskUnitMaterialCost?: RepairCost;

    @serializable(alias('container_repair_area', object(RepairArea)))
    containerRepairArea?: RepairArea;

    @serializable(alias('container_damaged_area', object(DamageArea)))
    containerDamagedArea?: DamageArea;

    @serializable(alias('repair_type', object(RepairType)))
    repairType?: RepairType;

    @serializable(alias('repair_type_id', primitive()))
    repairTypeId?: number;

    @serializable(alias('container_repair_area_id', primitive()))
    containerRepairAreaId?: string;

    @serializable(alias('container_damaged_area_id', primitive()))
    containerDamagedAreaId?: string;

    @serializable(alias('is_non_mearsk_not_applicable', primitive()))
    isNonMearskNotApplicable?: boolean;

    @serializable(alias('non_mearsk_description', primitive()))
    nonMearskDescription?: string;

    @serializable(alias('comp_id', primitive()))
    compId?: string;

    @serializable(alias('comp', object(RepairType)))
    comp?: RepairType;

    @serializable(alias('rep_id', primitive()))
    repId?: string;

    @serializable(alias('rep', object(RepairType)))
    rep?: RepairType;

    @serializable(alias('dam_id', primitive()))
    damId?: string;

    @serializable(alias('dam', object(RepairType)))
    dam?: RepairType;

    @serializable(alias('component_id', primitive()))
    componentId?: string;

    @serializable(alias('component', object(RepairType)))
    component?: RepairType;

    @serializable(alias('event_id', primitive()))
    eventId?: string;

    @serializable(alias('event', object(RepairType)))
    event?: RepairType;

    @serializable(alias('location', primitive()))
    location?: string;

    @serializable(alias('length_id', primitive()))
    len?: string;

    @serializable(alias('length', object(RepairType)))
    lengthMeta?: RepairType;

    @serializable(alias('width', object(RepairType)))
    widthMeta?: RepairType;

    @serializable(alias('width_id', primitive()))
    width?: string;

    @serializable(alias('unit_id', primitive()))
    unitId?: string;

    @serializable(alias('unit', object(RepairType)))
    unit?: RepairType;

    @serializable(alias('non_mearsk_id_source', primitive()))
    nonMearskIdSource?: string;

    @serializable(alias('is_mearsk_not_applicable', primitive()))
    isMearskNotApplicable?: string;

    @serializable(alias('mearsk_max_material_cost', primitive()))
    mearskMaxMaterialCost?: number;

    @serializable(alias('mearsk_max_material_cost_dollars', primitive()))
    mearskMaxMaterialCostDollars?: number;

    // @serializable(alias('mearsk_max_material_cost', object(RepairCost)))
    // mearskMaxMaterialCost?: RepairCost;

    @serializable(alias('mesrsk_max_pieces', primitive()))
    mesrskMaxPieces?: string;

    @serializable(alias('mearsk_units', primitive()))
    mearskUnits?: string;

    @serializable(alias('repair_mode_id', primitive()))
    repairModeId?: string;

    @serializable(alias('repair_mode', object(RepairType)))
    repairMode?: RepairType;

    @serializable(alias('mode_number_id', primitive()))
    modeNumberId?: string

    @serializable(alias('mode_number', object(RepairType)))
    modeNumber?: RepairType;


    @serializable(alias('repair_code', primitive()))
    repairCode?: string;

    @serializable(alias('combined', primitive()))
    combined?: string;

    @serializable(alias('mearsk_description', primitive()))
    mearskDescription?: string;

    @serializable(alias('mearsk_id_source', primitive()))
    mearskIdSource?: string;

}

export class RepairVerison {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;


    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('is_active', primitive()))
    isActive?: boolean;

}
