import { Row, Col, Dropdown } from "antd";
import { FormikHelpers, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Customer } from "../../../models/customer.model";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import CustomerService from "../../../services/CustomerService/customer.service";
import AppHeader from "../../../shared/components/AppHeader";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import TableComponent from "../../../shared/components/TableComponent";
import TableSearchFilterComponent from "../../../shared/components/TableSearchFilterComponent";
import useFilters from "../../../shared/hooks/useFilters";
import LocalStorage from "../../../shared/LocalStorage";
import CustomerForm from "../CustomerForm";
import "./customerList.scss"

const sortFields: any = {
    "fullName": "full_name",
    "id": "id",
    'status': "status"
}

const CustomerList = () => {

    const history = useHistory()

    const {
        fetchCustomers,
        createCustomer,
        loading,
        customers,
        submitting,
        customersMeta,
    } = CustomerService()

    const {
        getParams,
        setParams,
    } = useFilters()

    const [formVisible, setFormVisible] = useState<boolean>(false)

    useEffect(() => {
        fetchCustomers(getCustomerListFilterParams())
    }, [])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            sorter: true,
        },
        {
            title: 'User',
            dataIndex: 'fullName',
            key: 'fullName',
            ellipsis: true,
            width: 100,
            sorter: true,
        },
        {
            title: 'Customer Name',
            dataIndex: 'ownerName',
            key: 'ownerName',
            ellipsis: true,
            width: 100,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Billing Type',
            dataIndex: 'billingType',
            key: 'billingType',
            width: 70,
            ellipsis: true,
            render: (text: string) => <span>{customerTypeGenerator(text)}</span>
        },
        {
            title: 'Hourly Rate',
            dataIndex: 'hourlyRateDollars',
            key: 'hourlyRateDollars',
            width: 70,
            render: (text: string) => <span>{`${text} $`}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 70,
            sorter: true,
            render: (text: boolean) => (
                <span className={`status ${text ? 'active' : 'inactive'}`}>{text ? "Active" : "Inactive"}</span>
            )
        },
    ]

    const getCustomerListFilterParams = () => {
        const filterNames = [
            'search_text',
            'page',
            ...Object.values(sortFields) as string[],
        ]
        return getParams(filterNames)
    }

    const searchHandler = (values: FormikValues) => {
        setParams(values)
        fetchCustomers({ ...values })
    }

    const formSubmissionHandler = async (values: Customer, helpers: FormikHelpers<any>) => {
        const customer = await createCustomer(values)
        if (customer) {
            fetchCustomers(getCustomerListFilterParams())
            formCloseHandler()
        }
    }

    const formCloseHandler = (status?: boolean) => {
        setFormVisible(false)
    }

    const customerTypeGenerator = (type: string) => {
        if (type.includes("msc"))
            return "MSC"
        if (type.includes("common"))
            return "Common"
        if (type.includes("merc"))
            return "Merc"
        return ""
    }

    const handleRowClick = (customer: Customer) => {
        history.push(AppRoutes.CUSTOMER.replace(":customerId", String(customer?.id)))
    }

    const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any; }) => {
        const sortOrders = [undefined, 'ascend']
        const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field[0] : undefined
        const params: any = {}
        params[sortFields[field]] = sortOrders.indexOf(sorter.order)
        params.page = pagination.current
        const repairListFilters = getCustomerListFilterParams()
        const updatedFilters = { ...repairListFilters, page: params.page }
        setParams(updatedFilters)
        fetchCustomers({ ...params, ...updatedFilters })
    };

    return (
        <div className="customer-list__container">
            <AppHeader
                title={"Customers"}
                addButton={true}
                addButtonClickHandler={() => setFormVisible(true)} />
            <Row className="customer-list__table-header" justify="space-between" wrap={false}>
                <Col>
                    <TableSearchFilterComponent
                        placeholder={"Search by name"}
                        filterHandler={searchHandler}
                        name="customer-list-filters"
                        values={getCustomerListFilterParams()}
                    />
                </Col>
            </Row>
            <div className="customer-list__table">
                <TableComponent
                    className="cursor-pointer"
                    handleRowClick={handleRowClick}
                    scroll={{ x: 460 }}
                    loading={loading}
                    columns={columns}
                    data={customers}
                    onChange={handleTableChange}
                    pagination={{
                        pageSize: 20,
                        current: customersMeta.current || 1,
                        total: customersMeta.totalCount || 0,
                        showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                    }}
                />
            </div>
            <CustomerForm
                visible={formVisible}
                loading={submitting}
                closeHandler={formCloseHandler}
                submitHandler={formSubmissionHandler}
            />
        </div>
    )
}

export default CustomerList