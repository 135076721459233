export const REGEX = {
    NEGATIVE: '\-[0-9]*',
    NUMERIC: /^(0|[1-9][0-9]*)$/,
    //without space and dash:
    //CONTAINER_NUMBER: /^[a-zA-Z]{4}[0-9]{6}[0-9]{1}$/,
    //With space and dash:
    CONTAINER_NUMBER: /^[a-zA-Z]{4}\ [0-9]{6}\-[0-9]{1}$/,
    CONTAINER_LOCATION:/^[DF][BHGTX][1-4][2-4N]$|^[DF][BHGTX][X][X]$|^[LR][BHGTX][0-9][02-9N]$|^[LR][BHGTX][X][X]$|^[TUB][LXR][0-9][02-9N]$|^[TUB][LXR][X][X]$|^[XEI][X][X][X]$/,
    //CONTAINER_LOCATION: /^[DF][BHGTX][1-4][2-4N]|[DF][BHGTX][X][X]|[LR][BHGTX][0-9][02-9N]|[LR][BHGTX][X][X]|[TUB][LXR][0-9][02-9N]|[TUB][LXR][X][X]|[XEI][X][X][X]$/,
    DATE_MATCH_TEMP: /[0-9]{2}-[a-zA-Z]{3}-[0-9]{4}/,
}