import { serializable, alias, primitive, object, list } from 'serializr';
import { Attachment, Container, MetaData } from './container.model';
import { ActivityStatusEnum, ActivityTypeEnum } from '../enums/activity.enum';

export class ActivityTimeline {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('activity_id', primitive()))
    activityId?: number;

    @serializable(alias('history_date', primitive()))
    historyDate?: string;

    @serializable(alias('history_status', primitive()))
    historyStatus?: ActivityStatusEnum;
}

export class InvoiceTimeline {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('history_status', primitive()))
    historyStatus?: number;

    @serializable(alias('history_date', primitive()))
    date?: number;

    @serializable(alias('invoice_number', primitive()))
    invoiceNumber?: number;

    @serializable(alias('total_cost', primitive()))
    totalCost?: number;
}


export class Activity {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('yard_name', primitive()))
    yardName?: string;

    @serializable(alias('container_number', primitive()))
    containerNumber?: string;

    @serializable(alias('customer_name', primitive()))
    customer?: string;

    @serializable(alias('container_id', primitive()))
    containerId?: number;

    @serializable(alias('container', object(Container)))
    container?: Container;

    @serializable(alias('owner_name', primitive()))
    containerOwnerName?: string;

    @serializable(alias('activity_uid', primitive()))
    activityUid?: string;

    @serializable(alias('activity_type', primitive()))
    activityType?: ActivityTypeEnum;

    @serializable(alias('activity_status', primitive()))
    activityStatus?: ActivityStatusEnum;

    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('activity_date', primitive()))
    activityDate?: string;

    @serializable(alias('activity_timelines', list(object(ActivityTimeline))))
    activityTimeline?: ActivityTimeline[];

    @serializable(alias('activity_items_subtotal', primitive()))
    subtotal?: string;

    @serializable(alias('latest_invoice_id', list(primitive())))
    invoiceIds?: number[];

    @serializable(alias('invoice_timelines', list(object(InvoiceTimeline))))
    invoiceTimeline?: InvoiceTimeline[];

}

export class ActivityItems {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('unit_id', primitive()))
    unitId?: number;

    @serializable(alias('repair_type_id', primitive()))
    repairTypeId?: number;

    @serializable(alias('container_damaged_area_id', primitive()))
    damagedId?: number;

    @serializable(alias('container_repair_area_id', primitive()))
    repairId?: number;

    @serializable(alias('activity_item_attachments', list(object(Attachment))))
    attachments?: Attachment[] = [];

    @serializable(alias('repair_code', primitive()))
    repairCode?: string;

    @serializable(alias('container_repair_area', object(MetaData)))
    repairArea?: MetaData;

    @serializable(alias('container_damaged_area', object(MetaData)))
    damagedArea?: MetaData;

    @serializable(alias('repair_type', object(MetaData)))
    repairType?: MetaData;

    @serializable(alias('unit', object(MetaData)))
    unit?: MetaData;

    @serializable(alias('length_id', primitive()))
    lengthId?: number;

    @serializable(alias('length', object(MetaData)))
    length?: MetaData;

    @serializable(alias('width_id', primitive()))
    widthId?: number;

    @serializable(alias('width', object(MetaData)))
    width?: MetaData;

    @serializable(alias('location', primitive()))
    location?: string;

    @serializable(alias('hours', primitive()))
    hours?: number;

    @serializable(alias('labour_cost_dollars', primitive()))
    labourCostDollars?: number;

    @serializable(alias('labour_cost_cents', primitive()))
    labourCostCents?: number;

    @serializable(alias('labour_cost_currency', primitive()))
    labourCostCurrency?: string;

    @serializable(alias('labour_cost', primitive()))
    labourCost?: number;

    @serializable(alias('material_cost_dollars', primitive()))
    materialCostDollars?: number;

    @serializable(alias('material_cost', primitive()))
    materialCost?: number;

    @serializable(alias('material_cost_cents', primitive()))
    materialCostCents?: number;

    @serializable(alias('material_cost_currency', primitive()))
    materialCostCurrency?: string;

    @serializable(alias('total_cost_dollars', primitive()))
    totalCostDollars?: number;

    @serializable(alias('total_cost_cents', primitive()))
    totalCostCents?: number;

    @serializable(alias('total_cost_currency', primitive()))
    totalCostCurrency?: string;

    @serializable(alias('total_cost', primitive()))
    totalCost?: number;

    @serializable(alias('comments', primitive()))
    comments?: string;

    // @serializable(alias('activity_attachments', list(object(Attachment))))
    // activityAttachments?: Attachment[];
}
