import { Row, Col } from "antd";
import { Formik, Form, Field, FormikProps } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { ActivityAttachmentEnum } from "../../../../enums/containerAttachment.enum";
import { ActivityItems } from "../../../../models/activity.model";
import { Attachment } from "../../../../models/container.model";
import { MetaModel } from "../../../../models/meta.model";
import { ApiRoutes } from "../../../../routes/routeConstants/apiRoutes";
import ContainerService from "../../../../services/ContainerService/container.service";
import MetaService from "../../../../services/MetaService/meta.service";
import { AttachmentComponent } from "../../../../shared/components/AttachmentComponent";
import DropdownField from "../../../../shared/components/DropdownField";
import ErrorMessage from "../../../../shared/components/Error";
import InputField from "../../../../shared/components/InputField";
import UIModal from "../../../../shared/components/UIModal";
import { containerItemFormValidationSchema } from "./containerItemForm.validation";
import "./containerItemForm.scss"
import { UserTypes } from "../../../../enums/userTypes.enum";

interface ContainerItemFormProps {
    visible: boolean
    activityId?: number
    data?: ActivityItems
    closeHandler: (success?: boolean) => void
}

const ContainerItemForm = (props: ContainerItemFormProps) => {

    const {
        data,
        activityId,
        visible,
        closeHandler,
    } = props

    const {
        submitting,
        autoPopulatedActivityItemsForm,
        createActivityItem,
        updateActivityItem,
    } = ContainerService()

    const {
        fetchMeta,
        fetchContainerActivityTypeDamagedArea,
        fetchContainerActivityTypeRepairType,
        fetchContainerActivityLength,
        fetchContainerActivityWidth,
        fetchContainerActivityUnit,
        fetchContainerActivityRepairCode,
    } = MetaService()

    const [initialValues, setInitialValues] = useState<ActivityItems>(new ActivityItems())

    const [repairAreas, setRepairAreas] = useState<MetaModel[]>([])

    const [damagedAreas, setDamagedAreas] = useState<MetaModel[]>([])

    const [repairTypes, setRepairTypes] = useState<MetaModel[]>([])

    const [units, setUnits] = useState<MetaModel[]>([])

    const [lengths, setLength] = useState<MetaModel[]>([])

    const [widths, setWidths] = useState<MetaModel[]>([])

    const formikRef = useRef<FormikProps<ActivityItems>>(null)

    useEffect(() => {
        if (!visible) return
        fetchMetaForRepairCode()
        fetchMeta(ApiRoutes.UNITS, setUnits, "units")
        fetchMeta(ApiRoutes.WIDTH, setWidths, "widths")
        fetchMeta(ApiRoutes.LENGTH, setLength, "lengths")
    }, [visible])

    const fetchMetaForRepairCode = () => {
        fetchMeta(ApiRoutes.REPAIR_AREAS, setRepairAreas, "container_repair_areas")
        fetchMeta(ApiRoutes.DAMAGED_AREAS, setDamagedAreas, "container_damaged_areas")
        fetchMeta(ApiRoutes.REPAIR_TYPES, setRepairTypes, "repair_types")
    }

    useEffect(() => {
        setInitialValues(data
            ? {
                ...data,
                // repairedAreaImageId: data?.repairedAreaImage?.id,
                damagedId: data?.damagedArea?.id,
                repairTypeId: data?.repairType?.id,
                unitId: data?.unit?.id,
                repairId: data.repairArea?.id,
                lengthId: data.length?.id,
                widthId: data.width?.id,
                // damagedAreaImageId: [data?.damagedAreaImage[0].id],
                labourCost: data?.labourCostDollars,
                materialCost: data?.materialCostDollars,
                totalCost: data?.totalCostDollars
            }
            : new ActivityItems())
    }, [data])

    const repairCodeRelatedFields = [
        "repairId",
        "damagedId",
        "repairTypeId",
        "lengthId",
        "length.id",
        "widthId",
        "width.id",
        "unitId",
        "unit.id",
        "hours",
        "labourCost",
        "materialCost",
        "totalCost",
        "labourCostDollars",
        "materialCostDollars",
        "totalCostDollars",
    ]

    const repairCodeBlurHandler = async (values: ActivityItems, form: FormikProps<ActivityItems>) => {
        const {
            setFieldValue,
        } = form
        if (!values.repairCode || !activityId) return
        const response: any = await autoPopulatedActivityItemsForm({
            activity_id: activityId,
            repair_code: values.repairCode
        })
        setFieldValue("repairCode", response ? values?.repairCode : null)
        repairCodeRelatedFields.forEach(field => setFieldValue(field, response ? response[field] : undefined))
    }

    const submitHandler = async (values: ActivityItems) => {
        if (!activityId) return
        try {
            let response;
            if (values.id) {
                response = await updateActivityItem(activityId, values)
            } else {
                response = await createActivityItem(activityId, values, UserTypes.ADMIN.toLowerCase())
            }
            if (response)
                resetFormHandler(true, true)
        } catch (error) {
            console.log(error);
        }
    }

    const resetFormHandler = (closeForm?: boolean, closeHanlderParam?: boolean) => {
        setInitialValues(prev => ({ ...new ActivityItems(), id: prev.id }))
        formikRef.current?.resetForm()
        if (closeForm)
            closeHandler(closeHanlderParam)
    }

    const setValueAsUndefined = (setFieldValue: any, setFieldTouched: any, ...rest: string[]) => {
        rest.forEach(field => {
            setFieldValue(field, undefined)
            setFieldTouched(field, false)
        })
    }

    return <UIModal
        visible={visible}
        title={`${initialValues.id ? "Edit" : "Add"} Item`}
        okText="Proceed"
        onOk={() => formikRef.current?.submitForm()}
        onCancel={() => resetFormHandler(true)}
        loading={submitting}
    >
        <a onClick={() => resetFormHandler()} className="container-item-form reset-form">Reset Form</a>
        <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            enableReinitialize
            innerRef={formikRef}
            validationSchema={containerItemFormValidationSchema}
        >
            {(form) => {
                const { setFieldValue, values, errors, setFieldTouched } = form
                return (
                    <Form>
                        <Row gutter={[20, 20]}>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Repair Code</div>
                                <InputField
                                    type="number"
                                    name="repairCode"
                                    placeholder="Enter 4 digit repair code"
                                    value={values.repairCode}
                                    onChange={(event: any) => {
                                        if (event.target.value?.length < 5)
                                            setFieldValue("repairCode", event.target.value)
                                        if (event.target.value?.length === 0)
                                            [...repairCodeRelatedFields.slice(1)].forEach(field => setFieldValue(field, undefined))
                                    }}
                                    onKeyUp={(event: any) => {
                                        if (event.target.value?.length !== 4) return
                                        setFieldTouched("repairCode");
                                        [...repairCodeRelatedFields.slice(1)].forEach(field => setFieldValue(field, undefined))
                                        fetchMetaForRepairCode()
                                        repairCodeBlurHandler(values, form)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Repair Area'}
                                    name='repairId'
                                    options={repairAreas}
                                    value={values.repairId}
                                    onChange={(value) => {
                                        setFieldValue('repairId', value)
                                        setValueAsUndefined(setFieldValue, setFieldTouched,
                                            'repairCode',
                                            'lengthId',
                                            "length.id",
                                            'widthId',
                                            'width.id',
                                            "repairTypeId",
                                            'hours',
                                            'unitId',
                                            'labourCost',
                                            'totalCost',
                                            'materialCost',
                                            'damagedId')
                                        setDamagedAreas([])
                                        fetchContainerActivityTypeDamagedArea({
                                            activity_id: activityId,
                                            container_repair_area_id: value
                                        }, setDamagedAreas)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Damage Area'}
                                    name='damagedId'
                                    options={damagedAreas}
                                    value={values.damagedId}
                                    disabled={!values.repairId}
                                    onChange={(value) => {
                                        setFieldValue('damagedId', value)
                                        setValueAsUndefined(setFieldValue, setFieldTouched,
                                            'repairCode',
                                            'lengthId',
                                            "length.id",
                                            'widthId',
                                            'width.id',
                                            "repairTypeId",
                                            'hours',
                                            'unitId',
                                            'labourCost',
                                            'totalCost',
                                            'materialCost')
                                        setRepairTypes([])
                                        fetchContainerActivityTypeRepairType({
                                            activity_id: activityId,
                                            container_repair_area_id: values.repairId,
                                            container_damaged_area_id: value,
                                        }, setRepairTypes)
                                    }}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <DropdownField
                                    title={'Type'}
                                    name='repairTypeId'
                                    options={repairTypes}
                                    disabled={!values.damagedId}
                                    value={values.repairTypeId}
                                    onChange={(value) => {
                                        setFieldValue('repairTypeId', value)
                                        setValueAsUndefined(setFieldValue, setFieldTouched,
                                            'repairCode',
                                            'lengthId',
                                            "length.id",
                                            'widthId',
                                            'width.id',
                                            'hours',
                                            'unitId',
                                            'labourCost',
                                            'totalCost',
                                            'materialCost')
                                        setLength([])
                                        fetchContainerActivityLength({
                                            activity_id: activityId,
                                            container_repair_area_id: values.repairId,
                                            container_damaged_area_id: values.damagedId,
                                            repair_type_id: value
                                        }, setLength)
                                        // if (response)
                                        //     repairCodeBlurHandler({ ...values, ...response }, form)
                                    }}
                                />
                            </Col>
                            <Col span={24}>
                                <Row gutter={5}>
                                    <Col span={8} className="container-form__field">
                                        <DropdownField
                                            title={'Length'}
                                            name="lengthId"
                                            options={lengths}
                                            disabled={!values.repairTypeId}
                                            value={values.lengthId || values.length?.id}
                                            onChange={(value) => {
                                                setFieldValue('lengthId', value)
                                                setValueAsUndefined(setFieldValue, setFieldTouched,
                                                    'repairCode',
                                                    'widthId',
                                                    'width.id',
                                                    'hours',
                                                    'unitId',
                                                    'labourCost',
                                                    'totalCost',
                                                    'materialCost')
                                                setWidths([])
                                                fetchContainerActivityWidth({
                                                    activity_id: activityId,
                                                    container_damaged_area_id: values.damagedId,
                                                    container_repair_area_id: values.repairId,
                                                    repair_type_id: values.repairTypeId,
                                                    length_id: value
                                                }, setWidths)
                                            }}
                                        />
                                    </Col>
                                    <Col span={8} className="container-form__field">
                                        <DropdownField
                                            title={'Width'}
                                            name="widthId"
                                            options={widths}
                                            disabled={!values.lengthId && !values.length?.id}
                                            value={values.widthId || values.width?.id}
                                            onChange={(value) => {
                                                setFieldValue('widthId', value)
                                                setValueAsUndefined(setFieldValue, setFieldTouched,
                                                    'repairCode',
                                                    'hours',
                                                    'unitId',
                                                    'labourCost',
                                                    'totalCost',
                                                    'materialCost')
                                                setUnits([])
                                                fetchContainerActivityUnit({
                                                    activity_id: activityId,
                                                    container_damaged_area_id: values.damagedId,
                                                    container_repair_area_id: values.repairId,
                                                    repair_type_id: values.repairTypeId,
                                                    length_id: values.lengthId,
                                                    width_id: value
                                                }, setUnits)
                                            }}
                                        />
                                    </Col>
                                    <Col span={8} className="container-form__field">
                                        <DropdownField
                                            title={'Units'}
                                            name='unitId'
                                            options={units}
                                            disabled={!values.widthId && !values.width?.id}
                                            value={values.unitId}
                                            onChange={async (value) => {
                                                setFieldValue('unitId', value)
                                                const response = await fetchContainerActivityRepairCode({
                                                    activity_id: activityId,
                                                    container_damaged_area_id: values.damagedId,
                                                    container_repair_area_id: values.repairId,
                                                    repair_type_id: values.repairTypeId,
                                                    length_id: values.lengthId,
                                                    width_id: values.widthId,
                                                    unit_id: value
                                                })
                                                if (!response) return
                                                setFieldValue("repairCode", response?.repairCode)
                                                setFieldValue("hours", response?.hours)
                                                setFieldValue("materialCost", response?.materialCost)
                                                setFieldValue("labourCost", response?.labourCost)
                                                setFieldValue("totalCost", response?.totalCost)

                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="container-form__field hide-disabled">
                                <div className="form-label">Hours</div>
                                <InputField
                                    type="number"
                                    name="hours"
                                    placeholder=""
                                    value={values.hours}
                                    disabled
                                />
                            </Col>
                            <Col span={24}>
                                <Row gutter={5}>
                                    <Col span={8} className="container-form__field hide-disabled">
                                        <div className="form-label">Labour Cost</div>
                                        <InputField
                                            type="text"
                                            name="labourCost"
                                            placeholder=""
                                            disabled
                                            value={values.labourCost || values.labourCostDollars}
                                        />
                                    </Col>
                                    <Col span={8} className="container-form__field hide-disabled">
                                        <div className="form-label">Material Cost</div>
                                        <InputField
                                            type="text"
                                            name="materialCost"
                                            placeholder=""
                                            disabled
                                            value={values.materialCost || values.materialCostDollars}
                                        />
                                    </Col>
                                    <Col span={8} className="container-form__field hide-disabled">
                                        <div className="form-label">Total Cost</div>
                                        <InputField
                                            type="text"
                                            name="totalCost"
                                            placeholder=""
                                            disabled
                                            value={values.totalCost || values.totalCostDollars}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Location</div>
                                <InputField
                                    type="text"
                                    name="location"
                                    placeholder="DBXX"
                                    value={values.location}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Comments</div>
                                <InputField
                                    type="textarea"
                                    name="comments"
                                    placeholder="Enter"
                                    value={values.comments}
                                />
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Damaged area photo</div>
                                {<Field>
                                    {({ meta }: any) =>
                                        <Fragment>
                                            <AttachmentComponent
                                                images={values.attachments
                                                    ?.filter(att => att.attachmentType === ActivityAttachmentEnum.DAMAGE_AREA)
                                                    ?.map((val) => Object.assign(new Attachment(), val))}
                                                multiple={true}
                                                className="image-only"
                                                onRemove={(attachment) => setFieldValue("attachments", values.attachments?.filter(image => image.id !== attachment.id))}
                                                onUpload={(response) => {
                                                    if (!response?.length) return
                                                    const attachments = response.map(resp => {
                                                        return Object.assign(new Attachment(), { ...resp, attachmentId: resp.id, attachmentType: ActivityAttachmentEnum.DAMAGE_AREA })
                                                    })
                                                    setFieldValue(`attachments`, [...(values.attachments || []), ...attachments])
                                                }}
                                                onClick={() => setFieldTouched("attachments")}
                                                accept="image/*"
                                            ></AttachmentComponent>
                                            <ErrorMessage message={meta.touched && (errors['attachments'] || "")} />
                                        </Fragment>
                                    }
                                </Field>}
                            </Col>
                            <Col span={24} className="container-form__field">
                                <div className="form-label">Repaired area photo</div>
                                <Field >
                                    {() =>
                                        <>
                                            <AttachmentComponent
                                                images={values.attachments
                                                    ?.filter(att => att.attachmentType === ActivityAttachmentEnum.REPAIR_AREA)
                                                    ?.map((val) => Object.assign(new Attachment(), val))}
                                                multiple={true}
                                                className="image-only"
                                                onRemove={(attachment) => setFieldValue("attachments", values.attachments?.filter(image => image.id !== attachment.id))}
                                                onUpload={(response) => {
                                                    if (!response?.length) return
                                                    const attachments = response.map(resp => {
                                                        return Object.assign(new Attachment(), { ...resp, attachmentId: resp.id, attachmentType: ActivityAttachmentEnum.REPAIR_AREA })
                                                    })
                                                    setFieldValue(`attachments`, [...(values.attachments || []), ...attachments])
                                                }}
                                                accept="image/*"
                                                onClick={() => setFieldTouched("attachments")}
                                            ></AttachmentComponent>
                                        </>
                                    }
                                </Field>
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    </UIModal>
}

export default ContainerItemForm