import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { ActivityStatusEnum } from "../../../../enums/activity.enum";
import { Activity, ActivityItems } from "../../../../models/activity.model";
import ContainerService from "../../../../services/ContainerService/container.service";
import InputField from "../../InputField";
import UIModal from "../../UIModal";
import "./containerCommentForm.scss"

interface ContainerCommentFormProps {
    visible: boolean
    activity?: Activity
    data?: ActivityItems
    closeHandler: (success?: boolean) => void
}

const ContainerCommentForm: FC<ContainerCommentFormProps> = (props) => {

    const {
        data,
        visible,
        closeHandler,
        activity,
    } = props

    const {
        submitting,
        updateActivityItem,
    } = ContainerService()

    const [initialValues, setInitialValues] = useState<ActivityItems>({})

    const [isDirty, setIsDirty] = useState<boolean>(false)

    const formikRef = useRef<FormikProps<FormikValues>>(null)

    useEffect(() => {
        setInitialValues({ ...data })
    }, [data])

    const submitHandler = async (values: FormikValues, helpers: FormikHelpers<any>) => {
        if (!activity?.id) return
        try {
            await updateActivityItem(activity.id, values)
            closeHandler(true)
        } catch (error) {

        }
    }

    return <UIModal
        visible={visible}
        closable
        okText="Update"
        onCancel={() => closeHandler()}
        loading={submitting}
        onOk={formikRef.current?.submitForm}
        className={`container-comment-form__modal ${isDirty ? 'form-dirty' : 'form-pristine'}`}
        title={activity?.activityUid}
    >
        <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            innerRef={formikRef}
            enableReinitialize
        >
            {({ setFieldValue, dirty, values }) =>
                <Form>
                    <InputField
                        placeholder="Enter "
                        name="comments"
                        type="textarea"
                        disabled={(activity?.activityStatus &&
                            (activity?.activityStatus !== ActivityStatusEnum.QUOTE_DRAFT &&
                                activity?.activityStatus !== ActivityStatusEnum.QUOTE_ISSUED &&
                                activity?.activityStatus !== ActivityStatusEnum.REPAIR_DRAFT &&
                                activity?.activityStatus !== ActivityStatusEnum.PENDING_ADMIN_APPROVAL))}
                        onKeyUp={e => {
                            setFieldValue('comments', e.target.value)
                            setIsDirty(dirty)
                        }}
                    />
                </Form>}
        </Formik>
    </UIModal>
}

export default ContainerCommentForm