import { Col, Row } from "antd";
import React from "react";
import { Customer } from "../../../models/customer.model";
import { convertToTitleCase } from "../../utils/convertToTitleCase";
import "./customerCard.scss"
interface CustomerCardProps {
    customer?: Customer
}

const CustomerCard = (props: CustomerCardProps) => {

    const {
        customer,
    } = props

    const customerTypeGenerator = (type: string) => {
        if (type.includes("msc"))
            return "MSC"
        if (type.includes("common"))
            return "Common"
        if (type.includes("merc"))
            return "Merc"
        return ""
    }
    return (
        <div className="customer-card">
            <Row>
                <Col span={8} className="customer-card--key">Email</Col>
                <Col span={16} className="customer-card--value">{customer?.email}</Col>
                <Col span={8} className="customer-card--key">Customer Name</Col>
                <Col span={16} className="customer-card--value">{customer?.ownerName}</Col>
                <Col span={8} className="customer-card--key">Billing Name</Col>
                <Col span={16} className="customer-card--value">{customer?.billingName}</Col>
                <Col span={8} className="customer-card--key">Hourly Rate</Col>
                <Col span={16} className="customer-card--value">{customer?.hourlyRateDollars}</Col>
                {!!customer?.gst && <Col span={8} className="customer-card--key">GST</Col>}
                {!!customer?.gst && <Col span={16} className="customer-card--value">{customer?.gst}</Col>}
                {!!customer?.pst && <Col span={8} className="customer-card--key">PST</Col>}
                {!!customer?.pst && <Col span={16} className="customer-card--value">{customer?.pst}</Col>}
                <Col span={8} className="customer-card--key">City</Col>
                <Col span={16} className="customer-card--value">{convertToTitleCase(customer?.city || '')}</Col>
                <Col span={8} className="customer-card--key">Address</Col>
                <Col span={16} className="customer-card--value">{customer?.address}</Col>
                <Col span={8} className="customer-card--key">Postal Code</Col>
                <Col span={16} className="customer-card--value">{customer?.postalCode}</Col>
                <Col span={8} className="customer-card--key">Province</Col>
                <Col span={16} className="customer-card--value">{customer?.province?.name}</Col>
                <Col span={8} className="customer-card--key">Approval Type</Col>
                <Col span={16} className="customer-card--value text-capitalize">{customerTypeGenerator(customer?.approvalType || '')}</Col>
                <Col span={8} className="customer-card--key">Billing Type</Col>
                <Col span={16} className="customer-card--value text-capitalize">{customerTypeGenerator(customer?.billingType || '')}</Col>
                <Col span={8} className="customer-card--key">Repair List</Col>
                <Col span={16} className="customer-card--value">{customer?.repairList}</Col>
            </Row>
        </div>
    )
}

export default CustomerCard