import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./ForgotPasswordValidation"
import AuthService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import '../auth.scss';
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { User } from "../../../models/user.model";
import { LOGIN } from "../../../routes/routeConstants/appRoutes";

const initialValue = {
    email: ""
}

const ForgotPasswordForm = () => {

    const { loading, forgotPassword } = AuthService();

    const history = useHistory();

    const onSubmit = (user: User) => {
        const data = {
            email: user?.email,
            redirect_url: `${window.location.host}/auth/reset-password`
        }
        forgotPassword(data);
    }

    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className="auth-form__header">
                        Forgot Password
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">Registered Email</div>
                        <InputField type="email"
                            name="email"
                            placeholder="Enter email"
                            prefix={<span className="icon-mail green-icon" />}
                        />
                    </div>
                    <ButtonComponent htmlType="submit"
                        loading={loading}
                        className='auth-form__action'
                        type="primary"
                        text="Send Password to Email"
                    />
                    <div className="auth-form__forgot">
                        <div onClick={() => history.push(LOGIN)}>Login Here</div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(ForgotPasswordForm);
