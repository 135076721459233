import { Row, Col } from 'antd'
import { Formik, Form, FormikProps, FormikHelpers } from 'formik'
import React, { FC, useRef } from 'react'
import { UserTypes } from '../../../enums/userTypes.enum'
import { Profile } from '../../../models/profile.model'
import UserService from '../../../services/UserService/user.service'
import AuthContainer from '../../../store/container/AuthContainer'
import { AuthReducerProps } from '../../../store/reducers/authReducer'
import InputField from '../InputField'
import UIModal from '../UIModal'
import { passwordFormValidationSchema } from './passwordForm.validation'

interface ChangePasswordFormProps extends AuthReducerProps {
    visible: boolean
    closeHandler: (response?: boolean) => void
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {

    const {
        visible,
        closeHandler,
        user,
    } = props

    const {
        submitting,
        updateAdminUser,
        updateCustomerUser,
    } = UserService()

    const initialValues = {
        password: '',
        confirmPassword: ""
    }

    const formikRef = useRef<FormikProps<Profile>>(null)


    const submitHandler = async (values: Profile, helpers: FormikHelpers<Profile>) => {
        if (!user?.id) return
        const response = user.role?.toLowerCase() === UserTypes.ADMIN
            ? await updateAdminUser({ ...values, id: user.id })
            : await updateCustomerUser({ ...values, id: user.id })
        if (!response) return
        helpers.resetForm()
        closeHandler(true)
    }

    return (
        <UIModal
            visible={visible}
            okText="Change Password"
            title="Change Password"
            onOk={() => formikRef.current?.submitForm()}
            onCancel={() => closeHandler()}
            loading={submitting}
        >
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={submitHandler}
                validationSchema={passwordFormValidationSchema}
                enableReinitialize
            >
                {({ setFieldValue, values, errors }) => {
                    return (
                        <Form>
                            <Row gutter={[20, 20]}>
                                <Col span={24} className="user-form__field">
                                    <div className="form-label">Password</div>
                                    <InputField
                                        type="text"
                                        name="password"
                                        placeholder="Enter"
                                    />
                                </Col>
                                <Col span={24} className="user-form__field">
                                    <div className="form-label">Confirm password</div>
                                    <InputField
                                        type="text"
                                        name="confirmPassword"
                                        placeholder="Enter"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </UIModal>
    )
}

export default AuthContainer(ChangePasswordForm)