import { REGEX } from './../../shared/utils/regex';
import * as Yup from "yup";
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';

export const reportValidationSchema = Yup.object().shape({
    containerNumber: Yup.string()
        .nullable(true)
        .matches(REGEX.CONTAINER_NUMBER, 'Container Number should be valid')
        // .test('containerNumber', 'Container Number should be valid!', function (value) {
        //     if (value?.length !== 11) return true
        //     return new Promise((resolve, reject) => {
        //         const params = { container_uid: value }
        //         axiosInstance.post(ApiRoutes.CONTAINER_NUMBER_VALIDATION, params, { params }).then((response) => {
        //             resolve(response.data.result)
        //         }).catch(() => {
        //             resolve(false)
        //         })
        //     })
        // }),
})
