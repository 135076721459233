import { Row, Col, Dropdown } from "antd";
import { Formik, Form, FormikValues, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { MetaModel } from "../../../models/meta.model";
import { RepairVerison } from "../../../models/repair.model";
import LocalStorage, { Name as FilterNameType } from "../../LocalStorage";
import { removeFalsyKeys } from "../../utils/removeFalsyKeys";
import ButtonComponent from "../ButtonComponent";
import DatePickerComponent from "../DatePickerComponent";
import DropdownField from "../DropdownField";
import InputField from "../InputField";
import "./tableSearchFilterComponent.scss"

export interface FilterData {
    title: string
    name: string
    field?: "select" | "date"
    disabled?: boolean
    options: MetaModel[]
}
interface TableSearchFilterComponentProps {
    placeholder: string
    filters?: FilterData[]
    values?: any
    filterHandler: (values: FormikValues) => void
    name: FilterNameType
    changeTriggerItem?: RepairVerison | string
}

const TableSearchFilterComponent = (props: TableSearchFilterComponentProps) => {

    const {
        placeholder,
        filters,
        filterHandler,
        name,
        changeTriggerItem,
        values,
    } = props


    const [initialValues, setInitialValues] = useState<FormikValues>({})

    const formikRef = useRef<FormikProps<FormikValues>>(null)

    useEffect(() => {
        formikRef.current?.resetForm()
        let initialValue: any = {}
        initialValue['search_text'] = ''
        if (filters?.length) {
            const keys = filters.map(filter => filter.name)
            keys.forEach(key => {
                initialValue[key] = ""
            })
        }
        const previousFilters = values
        initialValue = {
            ...initialValue,
            ...previousFilters,
        }
        setInitialValues(initialValue);

    }, [changeTriggerItem])

    const resetFiltersHandler = (initialValues: FormikValues, resetForm: Function) => {
        const values = {
            search_text: initialValues?.search_text
        }
        resetForm({ values })
        applyFiltersHandler(values)
    }

    const applyFiltersHandler = (values: FormikValues) => {
        filterHandler({ ...values, page: 1 })
    }

    const filterDropDownGenerator = (setFieldValue: Function, resetForm: Function, values: FormikValues) => {
        return (
            <Row className="table-filter__container" >
                <Col span={24}>
                    <Row
                        className="table-filter__header"
                        align="middle"
                        justify="space-between"
                    >
                        <Col>
                            <h2 className="table-filter--title" >
                                Filters
                            </h2>
                        </Col>
                        <Col className="table-filter--actions" >
                            <span className="reset" onClick={() => resetFiltersHandler(values, resetForm)}>Reset</span>
                            <span className="apply" onClick={() => applyFiltersHandler(values)}>Apply</span>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row
                        className="table-filter__body"
                        align="middle"
                        gutter={[20, 20]}
                        onClick={e => e.stopPropagation()}
                    >
                        {
                            filters?.length && filters.map(filter => <Col span={12}
                                key={filter?.name}
                                className={`${filter.disabled && 'd-none'}`}
                            >
                                {
                                    filter.field === 'date'
                                        ? <DatePickerComponent
                                            title={filter?.title}
                                            name={filter?.name}
                                            value={values[filter?.name]}
                                            onChange={(value, dateString) => {
                                                setFieldValue(filter?.name, dateString)
                                            }}
                                        />
                                        : <DropdownField
                                            title={filter?.title}
                                            name={filter?.name}
                                            placeHolder="Select"
                                            options={filter?.options}
                                            value={values[filter?.name]}
                                            onChange={(value) => {
                                                setFieldValue(filter?.name, value)
                                            }}
                                        />
                                }
                            </Col>)
                        }
                    </Row>
                </Col>
            </Row>
        )
    }

    let searchTimeout: any;
    const handleSearchChange = (values: FormikValues) => {
        if (searchTimeout)
            clearTimeout(searchTimeout)
        searchTimeout = setTimeout(() => {
            LocalStorage.setItem(name, { ...removeFalsyKeys({ ...values, page: 1 }) })
            filterHandler({ ...values, page: 1 })
        }, 800);
    }

    return <Formik
        initialValues={initialValues}
        onSubmit={() => { }}
        innerRef={formikRef}
        enableReinitialize
    >
        {({ setFieldValue, values, resetForm, }) => (
            <Form>
                <Row gutter={10} wrap={false}>
                    <Col className="search-form__field">
                        <InputField
                            type="text"
                            name="search_text"
                            placeholder={placeholder}
                            prefix={<span className="icon-search green-icon" />}
                            value={values.search_text}
                            onKeyUp={(event) => {
                                setFieldValue('search_text', event.target.value)
                                handleSearchChange(values)
                            }}
                        />
                    </Col>
                    {!!filters?.length && <Col>
                        <Dropdown
                            trigger={["click"]}
                            overlay={() => filterDropDownGenerator(setFieldValue, resetForm, values)} >
                            <ButtonComponent
                                className="search-bar--trigger"
                                iconClass="icon-filter"
                                text="Filters"
                            />
                        </Dropdown>
                    </Col>}
                </Row>
            </Form>
        )}
    </Formik>
}
export default TableSearchFilterComponent