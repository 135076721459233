
export enum ActivityEnum {
    ALL = 'all',
    DRAFT = 'draft',
    ADMIN_PENDING = "admin_pending",
    CUSTOMER_PENDING = "customer_pending",
    CUSTOMER_APPROVED = "customer_approved",
}

export enum ActivityTypeEnum {
    QUOTE = 'quote',
    REPAIR = 'repair',
}

export enum ActivityStatusEnum {
    QUOTE_DRAFT = 'quote_draft',
    QUOTE_ISSUED = 'quote_issued',
    PENDING_ADMIN_APPROVAL = "pending_admin_approval",
    PENDING_CUSTOMER_APPROVAL = "pending_customer_approval",
    READY_FOR_REPAIR = "ready_for_repair",
    REPAIR_DRAFT = "repair_draft",
    REPAIR_DONE = "repair_done",
    REPAIR_PENDING_ADMIN_APPROVAL = "repair_pending_admin_approval",
    READY_FOR_BILLING = "ready_for_billing",
    BILLED = "billed",
    IDLE = "idle",
    VOID = "invoice_void",
}


