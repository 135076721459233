import { ActivityStatusEnum } from './../../enums/activity.enum';
export const ActivityTypes = [
    { label: "Quote", value: "quote" },
    { label: "Repair", value: "repair" },
]

export const ActivityStatus = [
    { label: "Quote Draft", value: ActivityStatusEnum.QUOTE_DRAFT },
    // { label: "Quote Issued", value: ActivityStatusEnum.QUOTE_ISSUED },
    { label: "Pending Admin Approval", value: ActivityStatusEnum.PENDING_ADMIN_APPROVAL },
    { label: "Pending Customer Approval", value: ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL },
    { label: "Ready For Repair", value: ActivityStatusEnum.READY_FOR_REPAIR },
    { label: "Repair Draft", value: ActivityStatusEnum.REPAIR_DRAFT },
    // { label: "Repair Done", value: ActivityStatusEnum.REPAIR_DONE },
    { label: "Repair Pending Admin Approval", value: ActivityStatusEnum.REPAIR_PENDING_ADMIN_APPROVAL },
    { label: "Ready For Billing", value: ActivityStatusEnum.READY_FOR_BILLING },
    { label: "Billed", value: ActivityStatusEnum.BILLED },
    // { label: "Idle", value: ActivityStatusEnum.IDLE },
]