export const fileDownloader = (data: Blob, filename: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    downloadActionSimulator(link, filename);
}

export const fileDownloadFromUrl = (url: string, name: string) => {
    if (!url) { return }
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    downloadActionSimulator(link, name);
}

const downloadActionSimulator = (link: any, filename: string) => {
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const fileExtensionGenerator = (responseType: string) => {
    let extension = ''
    if (responseType.includes('csv'))
        extension = "csv"
    if (responseType.includes('pdf'))
        extension = "pdf"
    if (responseType.includes('zip'))
        extension = "zip"
    if (responseType.includes('xml'))
        extension = "xml"
    return extension
}