import React, { ChangeEvent, FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from 'antd';
import Error from "../Error";
import './inputField.scss';

interface InputFieldProps {
    type: string;
    name: string;
    placeholder: string;
    rows?: number;
    prefix?: any;
    value?: string | number
    disabled?: boolean
    onKeyUp?: (event: React.ChangeEvent<any>) => void
    onChange?: (event: React.ChangeEvent<any>) => void
    onBlur?: (event: React.ChangeEvent<any>) => void
}

const { TextArea } = Input;

const InputField: FC<InputFieldProps> = (props) => {
    const { name, type } = props;

    return (
        <div className='input-field'>
            {type === "textarea" ? <Field as={TextArea} {...props} /> :
                <Field as={Input} {...props} />
            }
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default InputField;
