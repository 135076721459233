import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { UserTypes } from '../../enums/userTypes.enum';
import * as AppRoutes from '../../routes/routeConstants/appRoutes';
import AuthContainer from '../../store/container/AuthContainer';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import AdminDashboard from './AdminDashboard';
import CustomerDashboard from './CustomerDashboard';

interface DashboardProps extends AuthReducerProps { }

const Dashboard: FC<DashboardProps> = (props) => {
    const { user } = props;

    if (user?.role?.toLowerCase() === UserTypes.ADMIN)
        return <AdminDashboard />
    return <CustomerDashboard />
}

export default AuthContainer(Dashboard);