import { AUTHENTICATED, UNAUTHENTICATED } from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { User } from "../../models/user.model";
import { ActionProps } from "../../shared/types/action.type";
import LocalStorage from "../../shared/LocalStorage";

export interface AuthState {
    authenticated: boolean;
    user?: User;
}

export interface AuthReducerProps extends AuthState {
    setAuthenticated: (user: User) => ActionProps;
    setUnAuthenticated: () => ActionProps;
}

const initState: AuthState = {
    authenticated: !!LocalStorage.getItem("access-token") || false,
    user: LocalStorage.getItem('user')
};

const containerReducer = CreateReducer(initState, {
    [AUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
        const { authenticated, user } = action?.payload;
        return {
            ...state,
            authenticated,
            user: user,
        };
    },
    [UNAUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
        const { authenticated } = action?.payload;
        return { ...state, authenticated };
    }
});

export default containerReducer;



