import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import { AUTHENTICATED, UNAUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import LocalStorage from "../../shared/LocalStorage";

const AuthService = () => {
	const history = useHistory();

	const [user, setUser] = useState<User>();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const loginUser = (data: {
		email: string,
		password: string,
		grant_type: string
	}) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.USER_LOGIN, data)
			.then((response) => {
				if (!response.data['user']) {
					Notification({
						message: "",
						description: "Unable to login",
						type: NotificationTypes.ERROR,
					});
					throw new Error()
				}
				const userDetails = deserialize(User, response.data['user']);
				const accessToken = response.data["token"]['access_token'];
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});

				if (userDetails) LocalStorage.setItem("user", userDetails);

				LocalStorage.setItem("access-token", accessToken ?? '');

				Notification({
					message: "",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				setUser(userDetails);
				setLoading(false);
				history.push(AppRoutes.DASHBOARD);


			})
			.catch((error) => {
				setError(error);
				setLoading(false);
			})
			.finally(() => { });
	};

	const logoutUser = () => {
		setLoading(true);
		return axiosInstance
			.delete(ApiRoutes.USER_LOGOUT)
			.then((response) => {
				store.dispatch({
					type: UNAUTHENTICATED,
					payload: {
						authenticated: false,
						user: {},
					},
				});

				LocalStorage.removeItem("access-token");
				LocalStorage.removeItem("user");

				Notification({
					message: "",
					description: "Logged out successfully",
					type: NotificationTypes.SUCCESS,
				});
				history.push(AppRoutes.LOGIN);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPassword = (data: any) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.FORGOT_PASSWORD, data)
			.then((response) => {
				Notification({
					message: "",
					description: "You will receive a password reset link if the submitted email address is of a registered user",
					type: NotificationTypes.SUCCESS,
				});
			}).catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchResetPasswordValidity = (data: any) => {
		return axiosInstance
			.post(ApiRoutes.VERIFY_RESET_PASSWORD, data)
			.then((response) => {
			}).catch((error) => {
				history.push(AppRoutes.LOGIN)
			})
	}
	const resetPassword = (data: any) => {
		setLoading(true);
		return axiosInstance
			.put(ApiRoutes.RESET_PASSWORD, data)
			.then((response) => {
				Notification({
					message: "",
					description: "Password has been reset successfully",
					type: NotificationTypes.SUCCESS,
				});
				history.push(AppRoutes.LOGIN)
			}).catch((error) => {
				Notification({
					message: "",
					description: "Unable to reset the password",
					type: NotificationTypes.ERROR,
				});
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		user,
		error,
		loading,
		loginUser,
		logoutUser,
		forgotPassword,
		resetPassword,
		fetchResetPasswordValidity,
	};
};

export default AuthService;
