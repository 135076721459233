import { Tabs } from 'antd'
import { FormikValues } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UserTypes } from '../../enums/userTypes.enum'
import { Profile } from '../../models/profile.model'
import UserService from '../../services/UserService/user.service'
import AppHeader from '../../shared/components/AppHeader'
import DeleteConfirmation from '../../shared/components/DeleteConfirmation'
import TableSearchFilterComponent from '../../shared/components/TableSearchFilterComponent'
import useFilters from '../../shared/hooks/useFilters'
import UserForm from './UserForm'
import "./userManagement.scss"
import UsersList from './UsersList'

const { TabPane } = Tabs

const UserManagement = () => {

    const history = useHistory()

    const [selectedProfile, setSelectedProfile] = useState<Profile>();

    const [selectedTab, setSelectedTab] = useState<UserTypes>();

    const [formVisible, setFormVisible] = useState<boolean>(false);

    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

    const {
        getParams,
        setParams,
    } = useFilters()

    const {
        users,
        loading,
        submitting,
        fetchAdminUsers,
        deleteAdminUser,
    } = UserService()

    useEffect(() => {
        const tab = history.location.hash.replace("#", "") as UserTypes || 'administrator'
        setSelectedTab(tab)
    }, [])

    useEffect(() => {
        fetchUsers()
    }, [selectedTab, history.location.search])

    const tabChangeHandler = (hash: string) => {
        setSelectedTab(hash as UserTypes)
        updateUrlHash(hash, true)
    }

    const updateUrlHash = (hash: string, removeSearch?: boolean) => {
        const search = removeSearch
            ? undefined
            : history.location.search

        history.replace({
            pathname: history.location.pathname,
            search,
            hash
        })
    }

    const fetchUsers = () => {
        if (selectedTab)
            fetchAdminUsers({
                role: selectedTab,
                search_text: getParams(['search_text']).search_text
            })
    }

    const openUserForm = () => setFormVisible(true)

    const closeUserForm = (response?: UserTypes) => {
        setFormVisible(false)
        setSelectedProfile(undefined)
        if (!response) return
        updateUrlHash(response)
        selectedTab === response && fetchUsers()
        setSelectedTab(response)
    }

    const openDeleteModal = () => setDeleteVisible(true)

    const closeDeleteModal = () => setDeleteVisible(false)

    const filtersApplyHandler = (values: FormikValues) => {
        setParams(values)
        // fetchAdminRepairList(String(selectedVersion?.id) || "", {
        //     ...values,
        // })
    }

    const editModeHandler = (profile: Profile) => {
        openUserForm()
        setSelectedProfile(profile)
    }

    const deleteModeHandler = (profile: Profile) => {
        openDeleteModal()
        setSelectedProfile(profile)
    }

    const deleteHandler = async () => {
        if (!selectedProfile) return
        const response = await deleteAdminUser(selectedProfile)
        const role = selectedProfile?.role
        if (!response || !role) return
        updateUrlHash(role)
        setSelectedTab(role)
        closeDeleteModal()
        fetchUsers()
        setSelectedProfile(undefined)
    }

    const closeDeleteHandler = () => {
        closeDeleteModal()
        setSelectedProfile(undefined)
    }

    return (
        <div className="user-management__container">
            <AppHeader
                title="User Management"
                addButton={true}
                addButtonClickHandler={openUserForm}
            />
            <div className="user-management__tabs">
                <Tabs destroyInactiveTabPane
                    onChange={tabChangeHandler}
                    activeKey={selectedTab}
                >
                    <TabPane key={'administrator'} tab={'Admin'} />
                    <TabPane key={'employee'} tab={'Employee'} />
                </Tabs>
            </div>
            <div className="user-management__table">
                <div className="user-management__table-header">
                    <TableSearchFilterComponent
                        name="repair-list-filters"
                        filterHandler={filtersApplyHandler}
                        placeholder="Search by name"
                        values={getParams(['search_text'])}
                        changeTriggerItem={selectedTab}
                    />
                </div>
                <div className="user-management__table-body">
                    <UsersList
                        loading={loading}
                        data={users}
                        editModeHandler={editModeHandler}
                        deleteModeHandler={deleteModeHandler}
                    />
                </div>
            </div>
            <UserForm
                closeHandler={closeUserForm}
                visible={formVisible}
                data={selectedProfile}
            />
            <DeleteConfirmation
                visible={deleteVisible}
                title="Are you sure to delete "
                name={`${selectedProfile?.firstName || ''} ${selectedProfile?.lastName || ''}`}
                deleteHandler={deleteHandler}
                closeHandler={() => closeDeleteHandler()}
                buttonLoading={submitting}
            />
        </div>
    )
}

export default UserManagement
