import { Row, Col } from "antd";
import { Formik, Form, FormikHelpers, FormikValues } from "formik";
import React, { FC, useRef, useState } from "react";
import { Activity } from "../../../../models/activity.model";
import ContainerService from "../../../../services/ContainerService/container.service";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import DatePickerComponent from "../../../../shared/components/DatePickerComponent";
import UIModal from "../../../../shared/components/UIModal";
import { convertToTitleCase } from "../../../../shared/utils/convertToTitleCase";
import { containerDateFormValidation } from "./containerDateForm.validation";
import "./containerDateForm.scss"

interface ContainerDateFormProps {
    visible: boolean
    activity: Activity
    closeHandler: (activity?: Activity) => void
}

const ContainerDateForm: FC<ContainerDateFormProps> = (props) => {

    const {
        visible,
        activity,
        closeHandler,
    } = props

    const {
        submitting,
        updateActivityDate,
    } = ContainerService()


    const dateFormikRef = useRef<any>(null)

    const dateEditHandler = async (values: Activity, helpers: FormikHelpers<any>) => {
        try {
            if (activity.id) {
                const data = await updateActivityDate({
                    activity_date: values.activityDate || "",
                    activity_id: activity.id
                })
                closeHandler(data)
            }
        } catch (error) {
        }
    }

    return <UIModal
        visible={visible}
        className="timeline-edit-date-modal"
        title={`Edit ${convertToTitleCase(activity?.activityType || '')} Date`}
        onCancel={() => closeHandler()}
        footer={false}
    >
        <Formik
            initialValues={new Activity()}
            innerRef={dateFormikRef}
            onSubmit={dateEditHandler}
            validationSchema={containerDateFormValidation}
        >
            {({ setFieldValue, values }) => <Form onClick={e => e.stopPropagation()}>
                <Row gutter={[0, 30]}>
                    <Col span={24}>
                        <DatePickerComponent
                            name="activityDate"
                            value={values.activityDate}
                            title={`${convertToTitleCase(activity?.activityType || '')} Date`}
                            placeHolder="Enter"
                            disableDates="future"
                            onChange={(value, dateString) => setFieldValue('activityDate', dateString)} />
                    </Col>
                    <Col span={24}>
                        <ButtonComponent
                            type="primary"
                            htmlType="submit"
                            loading={submitting}
                            text="Update"
                            onClick={(e) => { e.stopPropagation() }}
                        />
                    </Col>
                </Row>
            </Form>}
        </Formik>
    </UIModal>
}

export default ContainerDateForm