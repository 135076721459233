import { UserTypes } from './../enums/userTypes.enum';
import { serializable, alias, primitive } from 'serializr';

export class User {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('password', primitive()))
    password?: string;

    @serializable(alias('access_token', primitive()))
    accessToken?: string;

    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias('user_type', primitive()))
    role?: UserTypes;

    @serializable(alias('full_name', primitive()))
    name?: string;

    @serializable(alias('billing_type', primitive()))
    billingType?: string;
}
