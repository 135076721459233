import React, { useEffect, useState } from "react";
import { Menu, Row, Col, Popover, Divider } from "antd";
import "./appSidebar.scss";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import avatarPlaceholder from "../../../assets/navbar-userplaceholder.svg";
import sideBarLogo from "../../../assets/MLCAN-logo.png";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import AuthService from "../../../services/AuthService/auth.service";
import { UserTypes } from "../../../enums/userTypes.enum";
import ChangePasswordForm from "../ChangePasswordForm";

interface AppSidebarProps extends AuthReducerProps { }

function AppSidebar({ authenticated, user }: AppSidebarProps) {
  const history = useHistory();

  const { logoutUser } = AuthService();

  const [pathname, setPathname] = useState<string>(history.location.pathname);

  const [passwordFormVisiblity, setPasswordFormVisiblity] = useState<boolean>(false);

  const handleClick = ({ key }: any) => {
    if (key !== "profile") history.push(key);
  };

  useEffect(() => {
    return history.listen((location) => {
      setPathname("/" + location?.pathname?.split('/')[1]);
    });
  }, [history.location.pathname]);

  const openPasswordForm = () => setPasswordFormVisiblity(true)

  const closePasswordForm = () => {
    setPasswordFormVisiblity(false)
  }

  const profileContent = (
    <div className="profile-menu">
      <div className="profile-menu__details">
        <div className='app-sidebar__profile-img'>
          <img
            src={avatarPlaceholder}
            alt="User Placeholder"
          />
        </div>
        <div className="app-sidebar__profile-info">
          <div className="app-sidebar__profile-submenu">
            <div className='app-sidebar__username text-capitalize'>
              {`${user?.name || ''}`}
            </div>
          </div>
          <div className='app-sidebar__profile-role'>
            <span className='app-sidebar__profile-role__name'> {user?.role}</span>
            <span className="app-sidebar__profile-role__separator">.</span>
            <span className="app-sidebar__profile-role__id">A009988</span>
          </div>
        </div>
      </div>
      <Divider />
      <div className="profile-menu__contact">
        <div className="profile-menu__item">
          <i className="icon-mail" />
          <span className="app-sidebar__menu-title">{user?.email || ''}</span>
        </div>
        {/* <div className="profile-menu__item">
          <i className="icon-call" />
          <span className="app-sidebar__menu-title">(123)456-7890</span>
        </div> */}
      </div>
      <Divider />
      <div className="profile-menu__actions">
        {/* <div className="profile-menu__item">
          <i className="icon-edit profile-menu-icon" />
          <span className="app-sidebar__menu-title">Edit Profile</span>
        </div> */}
        <div className="profile-menu__item" onClick={openPasswordForm}>
          <i className="icon-password profile-menu-icon" />
          <span className="app-sidebar__menu-title">Change Password</span>
        </div>
        <div className="profile-menu__item" onClick={logoutUser}>
          <i className="icon-logout profile-menu-icon" />
          <span className="app-sidebar__menu-title">Logout</span>
        </div>
      </div>
    </div>
  );

  return (!history?.location?.pathname?.includes('auth') && true) ? (
    <div className="app-sidebar">
      <Menu
        className="app-sidebar__menubar"
        onClick={handleClick}
        mode="inline"
      >
        <div className='app-sidebar__menubar__logo'>
          <img src={sideBarLogo} alt='' />
        </div>
        <Menu.Item className={`${pathname.includes('dashboard') ? "active" : ""}`} key={AppRoutes.DASHBOARD}>
          <i className={`menu-icon icon-dashboard ${pathname.includes('dashboard') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">Dashboard</div>
        </Menu.Item>
        <Menu.Item className={`${pathname.includes('container') ? "active" : ""}`} key={AppRoutes.CONTAINERS}>
          <i className={`menu-icon icon-container ${pathname.includes('container') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">Containers</div>
        </Menu.Item>
        <Menu.Item className={`${pathname.includes('invoice') ? "active" : ""}`} key={AppRoutes.INVOICES}>
          <i className={`menu-icon icon-invoice ${pathname.includes('invoice') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">Invoices</div>
        </Menu.Item>
        {user?.role?.toLowerCase() !== UserTypes.CUSTOMER.toLowerCase() && <Menu.Item className={`${pathname.includes('customer') ? "active" : ""}`} key={AppRoutes.CUSTOMERS}>
          <i className={`menu-icon icon-customer ${pathname.includes('customer') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">Customers</div>
        </Menu.Item>}
        <Menu.Item className={`${pathname.includes('repair') ? "active" : ""}`} key={AppRoutes.REPAIR_LIST}>
          <i className={`menu-icon icon-repair-list ${pathname.includes('repair') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">Repair List</div>
        </Menu.Item>
        {user?.role?.toLowerCase() !== UserTypes.CUSTOMER.toLowerCase() && <Menu.Item className={`${pathname.includes('report') ? "active" : ""}`} key={AppRoutes.REPORTS}>
          <i className={`menu-icon icon-report-placeholder ${pathname.includes('report') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">Reports</div>
        </Menu.Item>}
        {user?.role?.toLowerCase() !== UserTypes.CUSTOMER.toLowerCase() && <Menu.Item className={`${pathname.includes('user-management') ? "active" : ""}`} key={AppRoutes.USER_MANAGEMENT}>
          <i className={`menu-icon icon-user-management ${pathname.includes('user-management') ? "active-icon" : ""}`} />
          <div className="app-sidebar__menu-title">User Management</div>
        </Menu.Item>}
        <Menu.Item className="app-sidebar__profile" key="profile">
          <Popover placement="top"
            title={null}
            content={profileContent}
            trigger="click"
            className='app-sidebar__profile-popover'
          >
            <div className='app-sidebar__profile-img'>
              <img
                src={avatarPlaceholder}
                alt="User Placeholder"
              />
            </div>
            <div className="app-sidebar__profile-info">
              <div className="app-sidebar__profile-submenu">
                <div className='app-sidebar__username text-capitalize'>
                  {`${user?.name || ''}`}
                </div>
              </div>
              <div className='app-sidebar__profile-role'>{user?.role || "Admin"}</div>
            </div>
          </Popover>
        </Menu.Item>
      </Menu>
      <ChangePasswordForm
        visible={passwordFormVisiblity}
        closeHandler={closePasswordForm} />
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);
