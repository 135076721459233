import React, { FC } from "react";
import Lottie from 'react-lottie';
import LoaderAnimation from "./../../../assets/lottie/MLCAN loader.json"
import "./loader.scss"

const Loader: FC = (props) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoaderAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <div className="loader__container">
        <Lottie
            options={defaultOptions}
            height={250}
            width={250}>
            {props.children}
        </Lottie>
    </div>
}

export default Loader