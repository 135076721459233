import React from "react";
import '../auth.scss';
import AuthRouter from "../authRouters";
import AppLogo from "../../../assets/MLCAN-logo.png";

const AuthWrapper = () => {
    return (
        <div className="auth-wrapper">
            <div className="auth-image">
                <img src={AppLogo} alt='' className="auth-image__logo"/>
            </div>
            <div className="auth-form">
                <AuthRouter />
            </div>
        </div>
    )
}

export default AuthWrapper;
