import { REGEX } from './../../../shared/utils/regex';
import * as Yup from "yup";

export const customerValidationSchema = Yup.object().shape({
    fullName: Yup.string()
        .required('Customer Name is required!'),
    email: Yup.string()
        .email("Email should be valid!")
        .required('Email is required!'),
    ownerName: Yup.string()
        .required('Owner Name is required!'),
    billingName: Yup.string()
        .required('Billing Name is required!'),
    hourlyRate: Yup.number()
        .typeError("Hourly Rate should be numeric!")
        .required('Hourly Rate is required!')
        .min(0, "Hourly rate should not be negative!"),
    gst: Yup.number()
        .nullable()
        .typeError("GST should be numeric!")
        .min(0, "GST should not be negative!"),
    pst: Yup.number()
        .nullable()
        .typeError("PST should be numeric!")
        .min(0, "PST should not be negative!"),
    provinceId: Yup.number()
        .required('Province is required!'),
    address: Yup.string()
        .required('Address is required!'),
    city: Yup.string()
        .required('City is required!'),
    postalCode: Yup.string()
        .min(6, 'Postal code should atleast have 6 characters!')
        .max(7, 'Postal code should not exceed 7 characters!')
        .required('Postal code is required!'),
    repairListId: Yup.number()
        .required('Repair List is required!'),
    password: Yup.string().ensure().when('id', {
        is: (customerId) => !customerId,
        then: Yup.string().required("Password is required!")
    }),
    status: Yup.string()
        .required('Status is required!'),
})
