
/* Auth Routes */
export const AUTH = "/auth"
export const REGISTER = AUTH + "/register"
export const LOGIN = AUTH + "/login"
export const FORGOT_PASSWORD = AUTH + "/forgot-password"
export const RESET_PASSWORD = AUTH + "/reset-password"

/* Dashboard Route */
export const DASHBOARD = "/dashboard"

/* Containers Route */
export const CONTAINERS = "/containers"
export const CONTAINER = "/containers/:containerId"

/* Invoices Route */
export const INVOICES = "/invoices"
export const INVOICE = "/invoices/:invoiceId"

/* customers Route */
export const CUSTOMERS = "/customers"
export const CUSTOMER = "/customers/:customerId"

/* Repair list Route */
export const REPAIR_LIST = "/repair-list"

/* Reports Route */
export const REPORTS = "/reports"

/* User management Route */
export const USER_MANAGEMENT = "/user-management"


