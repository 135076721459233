import React from "react";
import UIModal from "../UIModal";
import "./approveQuotesModal.scss"

interface ApproveQuotesModalProps {
    visible: boolean
    onOk: () => void
    onCancel: () => void
    loading: boolean
}

const ApproveQuotesModal = (props: ApproveQuotesModalProps) => {

    const {
        visible,
        onOk,
        onCancel,
        loading,
    } = props

    return (
        <UIModal
            visible={visible}
            okText="Confirm"
            closable={false}
            onCancel={onCancel}
            onOk={onOk}
            loading={loading}
            className="approve-quotes-modal"
        >
            <h2 className="title">Are you sure to approve all the quotes?</h2>
            <span className="subtitle">Selected quotes will be moved to quote approved status</span>
        </UIModal>
    )
}

export default ApproveQuotesModal
