import { Col, Row, Tabs } from "antd";
import React, { FC } from "react";
import { Customer } from "../../../models/customer.model";
import { Repair } from "../../../models/repair.model";
import UIModal from "../../../shared/components/UIModal";
import { CustomerBillingTypes } from "../../Customers/CustomerForm/customer.definition";
import "./repairDetails.scss"

const { TabPane } = Tabs;

interface RepairDetailsProps {
    visible: boolean
    closeHandler: () => void
    repair: Repair
    billingType?: string
}

const RepairDetails: FC<RepairDetailsProps> = (props) => {

    const {
        visible,
        repair,
        billingType,
        closeHandler,
    } = props

    return <UIModal
        visible={visible}
        onCancel={() => closeHandler()}
        footer={false}
        title={repair?.repairUid || repair?.containerRepairArea?.name}
        className="repair-details-modal"
    >
        <Tabs>
            <TabPane
                tab="Repair Details"
                key="1">
                <div className="repair-details__container">
                    <Row className="repair-details__cover" wrap={true}>
                        <Col span={10} className="repair-details--title">Repair ID</Col>
                        <Col span={14} className="repair-details--value">{repair?.repairUid}</Col>
                        <Col span={10} className="repair-details--title">Container Repair Area</Col>
                        <Col span={14} className="repair-details--value">{repair?.containerRepairArea?.name}</Col>
                        <Col span={10} className="repair-details--title">Container Damaged Area</Col>
                        <Col span={14} className="repair-details--value">{repair?.containerDamagedArea?.name}</Col>
                        <Col span={10} className="repair-details--title">Repair Type</Col>
                        <Col span={14} className="repair-details--value">{repair?.repairType?.name}</Col>
                        <Col span={10} className="repair-details--title">Length</Col>
                        <Col span={14} className="repair-details--value">{repair?.lengthMeta?.name}</Col>
                        <Col span={10} className="repair-details--title">Width</Col>
                        <Col span={14} className="repair-details--value">{repair?.widthMeta?.name}</Col>
                        <Col span={10} className="repair-details--title">Unit</Col>
                        <Col span={14} className="repair-details--value">{repair?.unit?.name}</Col>
                    </Row>
                </div>
            </TabPane>
            {(!billingType || billingType !== CustomerBillingTypes[0].value) && <TabPane
                tab="Common Details"
                key="2">
                <div className="repair-details__container">
                    {repair.isNonMearskNotApplicable && <p className="repair-details--heading na">Common is not applicable</p>}
                    <p className="repair-details--heading">Common Cost Details</p>
                    <Row className="repair-details__cover" wrap={true}>
                        <Col span={10} className="repair-details--title">Hours</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.nonMearskHours : '-'}</Col>
                        <Col span={10} className="repair-details--title">Material Cost</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.nonMearskMaterialCostDollars : '-'}</Col>
                    </Row>
                    <p className="repair-details--heading">Common Customer Related Details</p>
                    <Row className="repair-details__cover" wrap={true}>
                        <Col span={10} className="repair-details--title">COMP</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.comp?.name : '-'}</Col>
                        <Col span={10} className="repair-details--title">DAM</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.dam?.name : '-'}</Col>
                        <Col span={10} className="repair-details--title">REP</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.rep?.name : '-'}</Col>
                        <Col span={10} className="repair-details--title">Component</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.component?.name : '-'}</Col>
                        <Col span={10} className="repair-details--title">Event</Col>
                        <Col span={14} className="repair-details--value">{!repair.isNonMearskNotApplicable ? repair?.event?.name : '-'}</Col>
                    </Row>
                </div>
            </TabPane>}
            {(!billingType || billingType === CustomerBillingTypes[0].value) && <TabPane
                tab="Merc+ Details"
                key="3">
                <div className="repair-details__container">
                    {repair.isMearskNotApplicable && <p className="repair-details--heading na">Maersk is not applicable</p>}
                    <p className="repair-details--heading">Merc+ Cost Details</p>
                    <Row className="repair-details__cover" wrap={true}>
                        <Col span={10} className="repair-details--title">Unit Mat. Cost</Col>
                        <Col span={14} className="repair-details--value">{!repair.isMearskNotApplicable ? repair?.mearskUnitMaterialCostDollar : '-'}</Col>
                        <Col span={10} className="repair-details--title">Hours Per Unit</Col>
                        <Col span={14} className="repair-details--value">{!repair.isMearskNotApplicable ? repair?.mesrskHoursPerUnit : '-'}</Col>
                        <Col span={10} className="repair-details--title">Units</Col>
                        <Col span={14} className="repair-details--value">{!repair.isMearskNotApplicable ? repair?.mearskUnits : '-'}</Col>
                    </Row>
                    <p className="repair-details--heading">Merc+ Customer Related Details</p>
                    <Row className="repair-details__cover" wrap={true}>
                        <Col span={10} className="repair-details--title">Repair Mode</Col>
                        <Col span={14} className="repair-details--value">{!repair.isMearskNotApplicable ? repair?.repairMode?.name : '-'}</Col>
                        <Col span={10} className="repair-details--title">Mode Number</Col>
                        <Col span={14} className="repair-details--value">{!repair.isMearskNotApplicable ? repair?.modeNumber?.name : '-'}</Col>
                        <Col span={10} className="repair-details--title">Repair Code</Col>
                        <Col span={14} className="repair-details--value">{!repair.isMearskNotApplicable ? repair?.repairCode : '-'}</Col>
                    </Row>
                </div>
            </TabPane>}
        </Tabs>
    </UIModal>
}

export default RepairDetails