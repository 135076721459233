import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { UserTypes } from "../../../enums/userTypes.enum";
import { ActivityItems } from "../../../models/activity.model";
import InvoiceService from "../../../services/InvoiceService/invoice.service";
import AppHeader from "../../../shared/components/AppHeader";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import CreateInvoiceButton from "../../../shared/components/CreateInvoiceButton";
import DeleteConfirmation from "../../../shared/components/DeleteConfirmation";
import Loader from "../../../shared/components/Loader";
import TableComponent from "../../../shared/components/TableComponent";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import "./invoiceDetails.scss"


const columns = [
    {
        title: "Location",
        key: "location",
        dataIndex: "location"
    },
    {
        title: "Damaged Area",
        key: "damagedArea",
        dataIndex: ["damagedArea", "name"]
    },
    {
        title: "Repair Type",
        key: "repairType",
        dataIndex: ["repairType", "name"]
    },
    {
        title: "Quantity",
        dataIndex: ["unit", "name"],
        key: "unit",
        render: (text: string, record: ActivityItems) => <span>{`${record.length?.name} ${text} x ${record.width?.name} ${text}`}</span>
    },
    {
        title: "Hours",
        dataIndex: "hours",
        key: "hours"
    },
    {
        title: "Labour",
        key: "labourCostDollars",
        dataIndex: "labourCostDollars",
        render: (text: number, record: ActivityItems) => <span>{`${Number(text).toFixed(2)}`}</span>
    },
    {
        title: "Material",
        key: "materialCostDollars",
        dataIndex: "materialCostDollars",
        render: (text: number, record: ActivityItems) => <span>{`${Number(text).toFixed(2)}`}</span>
    },
    {
        title: "Total",
        key: "totalCostDollars",
        dataIndex: "totalCostDollars",
        render: (text: number, record: ActivityItems) => <span>{`${Number(text).toFixed(2)}`}</span>
    },
]

interface InvoiceDetailsProps extends AuthReducerProps { }

const InvoiceDetails = (props: InvoiceDetailsProps) => {

    const {
        user,
    } = props

    const [deleteVisible, setDeleteVisible] = useState<boolean>(false)

    const {
        loading,
        mailInvoice,
        fetchInvoice,
        printInvoice,
        invoiceActivity,
        markInvoiceVoid,
        invoicesSubmitting,
        invoicesSecondarySubmitting,
    } = InvoiceService()

    const { invoiceId } = useParams<{ invoiceId: string }>()

    useEffect(() => {
        fetchInvoice(invoiceId)
    }, [])

    const deleteHandler = async () => {
        const response = await markInvoiceVoid([+invoiceId])
        closeDeleteHandler(response)
    }

    const mailClientOpenHandler = () => {
        // window.open("mailto:")
        mailInvoice(+invoiceId)
    }

    const closeDeleteHandler = (success?: boolean) => {
        setDeleteVisible(false)
        if (success)
            fetchInvoice(invoiceId)
    }
    const openDeleteHandler = () => setDeleteVisible(true)

    const containerNumberFormatting = (value: string) => {
        let formattedValue = value.replaceAll(" ", "").replaceAll("-", "")
        return formattedValue.toUpperCase()
    }

    const tableFooter = <Row className="invoice-details__list--footer">
        <Col>
            <div className="row">
                <p className="title">Total Labour Hours</p>
                <p className="value">{invoiceActivity?.totalLabourHours || ''}</p>
            </div>
            <div className="row">
                <p className="title">Parts Cost</p>
                <p className="value">{invoiceActivity?.partsCost || ''}</p>
            </div>
            <div className="row">
                <p className="title">Total Labour Cost</p>
                <p className="value">{invoiceActivity?.totalLabourCost || ''}</p>
            </div>
            <div className="row">
                <p className="title">Subtotal Repair Cost</p>
                <p className="value">{invoiceActivity?.subtotalRepairCost || ''}</p>
            </div>
            <div className="row  text-bold">
                <p className="title">Invoice Total</p>
                <p className="value">$ {invoiceActivity?.invoiceTotal || ''}</p>
            </div>
        </Col>
    </Row>

    if (loading)
        return <Loader />

    return <div className="invoice-details__container">
        <div className="invoice-details__header">
            <AppHeader
                goBackText="Invoices"
                title={<p className="invoice-details--title">{invoiceActivity?.invoiceNumber || ''}
                    {invoiceActivity?.status === 'void' && <p className="status">Void</p>} </p>}
            />
            <div className="invoice-details__header__actions">
                <ButtonComponent
                    iconClass="icon-print"
                    text="Print"
                    loading={invoicesSecondarySubmitting}
                    onClick={() => printInvoice(invoiceId)}
                />
                <CreateInvoiceButton
                    iconClass="icon-mail green-icon"
                    primaryBtnText="Mail"
                    buttonType="default"
                    primaryBtnLoading={invoicesSubmitting}
                    primaryBtnOnClick={mailClientOpenHandler}
                // dropdownButtons={[{
                //     text: "Mail with images",
                //     onClick: () => { }
                // }]}
                />
                {invoiceActivity?.status === "invoiced" && user?.role?.toLowerCase() === UserTypes.ADMIN && <ButtonComponent
                    className="secondary"
                    text="Mark Invoice Void"
                    onClick={openDeleteHandler}
                />}
            </div>
        </div>
        <Row className="invoice-details__body">
            <Col span="10">
                <p className="text-grey">Bill To:</p>
                <p className="text-semibold">{invoiceActivity?.customer?.fullName || ''}</p>
                <p className="desc">{invoiceActivity?.customer?.address || ''}</p>
            </Col>
            <Col>
                <p className="row">
                    <p className="title">Invoice Date</p>
                    <p className="value">{invoiceActivity?.invoiceDate || ''}</p>
                </p>
                <p className="row">
                    <p className="title">Invoice Number</p>
                    <p className="value">{invoiceActivity?.invoiceNumber || ''}</p>
                </p>
                <p className="row">
                    <p className="title">Owner</p>
                    <p className="value text-capitalize">{invoiceActivity?.activities && invoiceActivity?.activities[0]?.containerOwnerName || ''}</p>
                </p>
                <p className="row">
                    <p className="title">Currency</p>
                    <p className="value">{invoiceActivity?.customer?.hourlyRateCurrency || ''}</p>
                </p>
            </Col>
            <Col>
                <p className="row">
                    <p className="title">Container No.</p>
                    <p className="value">{invoiceActivity?.activities && invoiceActivity?.activities[0]?.containerNumber ? containerNumberFormatting(invoiceActivity?.activities[0]?.containerNumber) : ''}</p>
                </p>
                <p className="row">
                    <p className="title">Activity</p>
                    <p className="value text-capitalize">{invoiceActivity?.activities && invoiceActivity?.activities[0]?.activityUid || ''}</p>
                </p>
                <p className="row">
                    <p className="title">Date</p>
                    <p className="value">{invoiceActivity?.activities && invoiceActivity?.activities[0]?.activityDate ? moment(invoiceActivity?.activities[0]?.activityDate).format('DD MMM, YYYY') : ''}</p>
                </p>
            </Col>
        </Row>
        <div className="invoice-details__list">
            <TableComponent
                data={invoiceActivity?.activityItems || []}
                columns={columns}
                loading={false}
                pagination={false}
                footer={tableFooter}
            />
        </div>
        <DeleteConfirmation
            visible={deleteVisible}
            title="Are you sure to mark the invoice void?"
            name={""}
            deleteHandler={deleteHandler}
            closeHandler={closeDeleteHandler}
            buttonLoading={invoicesSubmitting}
        />
    </div>
}

export default AuthContainer(InvoiceDetails)