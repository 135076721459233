import { serializable, alias, primitive, object } from 'serializr';

class MetaData {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

export class Customer {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('full_name', primitive()))
    fullName?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('owner_name', primitive()))
    ownerName?: string;

    @serializable(alias('billing_name', primitive()))
    billingName?: string;

    @serializable(alias('hourly_rate', primitive()))
    hourlyRate?: number;
    @serializable(alias('approval_type', primitive()))
    approvalType?: string = "common_approval_type";

    @serializable(alias('billing_type', primitive()))
    billingType?: string = "common";

    @serializable(alias('hourly_rate_dollars', primitive()))
    hourlyRateDollars?: string;

    @serializable(alias('hourly_rate_cents', primitive()))
    hourlyRateCents?: number;

    @serializable(alias('hourly_rate_currency', primitive()))
    hourlyRateCurrency?: string;

    @serializable(alias('gst', primitive()))
    gst?: number;

    @serializable(alias('pst', primitive()))
    pst?: number;

    @serializable(alias('city_id', primitive()))
    cityId?: number;

    @serializable(alias('province_id', primitive()))
    provinceId?: number;

    @serializable(alias('city', primitive()))
    city?: string;

    @serializable(alias('province', object(MetaData)))
    province?: MetaData;

    @serializable(alias('address', primitive()))
    address?: string;

    @serializable(alias('zipcode', primitive()))
    postalCode?: string;

    @serializable(alias('repair_list_id', primitive()))
    repairListId?: number = 0;

    @serializable(alias('customer_repair_list', primitive()))
    repairList?: string;

    @serializable(alias('password', primitive()))
    password?: string;

    @serializable(alias('is_active', primitive()))
    status?: boolean = false;

}
