import React from "react";
import "./customers.scss"
import CustomerList from "./CustomerList";

const Customers = () => {

    return (
        <CustomerList />
    )
}

export default Customers