import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "../../views/Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import isAuthenticated from "../../shared/components/HOC/requireAuth";

const authRouter = () => {
	const routes: RouterProps[] = [
		{ exact: true, path: AppRoutes.LOGIN, component: LoginForm },
		{ exact: true, path: AppRoutes.FORGOT_PASSWORD, component: ForgotPasswordForm },
		{ exact: true, path: AppRoutes.RESET_PASSWORD, component: ResetPasswordForm },
	];

	return (
		<Switch>
			{routes.map(({ component, ...routerProps }, i: number) => (
				<Route {...routerProps} component={component as FC} key={i} />
			))}
			<Redirect exact strict from={AppRoutes.AUTH} to={AppRoutes.LOGIN} />
		</Switch>
	);
};

export default authRouter;
