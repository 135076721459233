import * as Yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const firstPaneValidationSchema = Yup.object().shape({
    repairUid: Yup.string()
        .max(4, 'Repair Id should have only 4 digits')
        .required('Repair Id is required!'),
    containerRepairAreaId: Yup.string()
        .required('Container Repair Area is required!'),
    containerDamagedAreaId: Yup.string()
        .required('Container Damaged Area is required!'),
    repairTypeId: Yup.string()
        .required('Repair Type is required!'),
    len: Yup.string()
        .required('Length is required!'),
    width: Yup.string()
        .required('Width is required!'),
    unitId: Yup.string()
        .required('Unit is required!'),
})

export const secondPaneValidationSchema = Yup.object().shape({
    isNonMearskNotApplicable: Yup.boolean().nullable(),
    nonMearskHours: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Hours is required!'),
        }),
    nonMearskMaterialCost: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Material Cost is required!'),
        }),
    // nonMearskDescription: Yup.string().nullable()
    //     .when("isNonMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('Description is required!'),
    //     }),
    compId: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('COMP is required!'),
        }),
    damId: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('DAM is required!'),
        }),
    repId: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('REP is required!'),
        }),
    componentId: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Component is required!'),
        }),
    eventId: Yup.string().nullable()
        .when("isNonMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Event is required!'),
        }),
    // location: Yup.string().nullable()
    //     .when("isNonMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field
    //             .required('Location is required!')
    //             .matches(REGEX.CONTAINER_LOCATION, 'Location should be valid!'),
    //     }),
    // nonMearskIdSource: Yup.string().nullable()
    //     .when("isNonMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('ID Source is required!'),
    //     }),

    // containerDamagedAreaId: Yup.string().nullable()
    //     .required('Container Damaged Area is required!'),
    // repairType: Yup.string()
    //     .required('Repair Type is required!'),
})

export const thirdPaneValidationSchema = Yup.object().shape({
    isMearskNotApplicable: Yup.boolean().nullable(),
    mearskUnitMaterialCost: Yup.string().nullable()
        .when("isMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Unit Material Cost is required!'),
        }),
    // mearskMaxMaterialCost: Yup.string().nullable()
    //     .when("isMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('Maximum Material Cost is required!'),
    //     }),
    // mesrskMaxPieces: Yup.string().nullable()
    //     .when("isMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('Maximum Pieces is required!'),
    //     }),
    mearskUnits: Yup.string().nullable()
        .when("isMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Units is required!'),
        }),
    repairModeId: Yup.string().nullable()
        .when("isMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Repair Mode is required!'),
        }),
    modeNumberId: Yup.string().nullable()
        .when("isMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Mode Number is required!'),
        }),
    repairCode: Yup.string().nullable()
        .when("isMearskNotApplicable", {
            is: true,
            otherwise: (field: any) => field.required('Repair Code is required!'),
        }),
    // combined: Yup.string().nullable()
    //     .when("isMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('Combined is required!'),
    //     }),
    // mearskDescription: Yup.string().nullable()
    //     .when("isMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('Description is required!'),
    //     }),
    // mearskIdSource: Yup.string().nullable()
    //     .when("isMearskNotApplicable", {
    //         is: true,
    //         otherwise: (field: any) => field.required('ID Source is required!'),
    //     }),
})
