import * as Yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const userFormValidationSchema = Yup.object().shape({
    role: Yup.string()
        .required('User Type is required!'),
    firstName: Yup.string()
        .required('First name is required!'),
    lastName: Yup.string()
        .required('Last name is required!'),
    mobile: Yup
        .string()
        .min(8, "Mobile number should be valid")
        .max(15, "Mobile number should be valid")
        .required("Mobile number is required"),
    email: Yup.string()
        .required('Email is required!'),
    password: Yup.string().ensure().when('id', {
        is: (customerId) => !customerId,
        then: Yup.string().required("Password is required!")
    }),
})