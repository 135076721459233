import { Row, Col, Dropdown, Menu } from "antd";
import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { RepairVerison } from "../../../models/repair.model";
import RepairService from "../../../services/RepairService/repair.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import TableSearchFilterComponent from "../../../shared/components/TableSearchFilterComponent";
import { Name as FilterNameType } from "../../LocalStorage";
import ConfirmationModal from "../ConfirmationModal";
import "./repairListTableActions.scss"
import DefaultIcon from "./../../../assets/default\ version.svg"
import moment from "moment";

interface CustomerRepairListProps {
    filters: any[]
    name: FilterNameType
    versionList: RepairVerison[]
    activeVersion?: RepairVerison,
    confirmationModalLoading: boolean
    versionSelectHandler: (version: RepairVerison) => void,
    createNewVersionHandler: () => void
    exportHandler: (versionId?: number) => void
    bulkUploadClickHandler: () => void
    setVersionAsDefaulHandler: (callBack: () => void) => void
    filtersApplyHandler: (formikValues: FormikValues) => void
    filterValues: any
    exportLoading:boolean
}

const RepairListTableActions = (props: CustomerRepairListProps) => {

    const {
        filters = [],
        versionList,
        activeVersion,
        createNewVersionHandler,
        versionSelectHandler,
        confirmationModalLoading,
        exportHandler,
        bulkUploadClickHandler,
        filtersApplyHandler,
        setVersionAsDefaulHandler,
        name,
        filterValues,
        exportLoading,
    } = props

    const [defalutVisible, setDefalutVisible] = useState<boolean>(false)

    const versionDropDownGenerator = () => {
        return (
            <Menu className="version-menu" mode="vertical">
                <Menu.Item className="new-version" key="0" onClick={createNewVersionHandler}>
                    + New Version
                </Menu.Item>
                {versionList?.map(version => (
                    <Menu.Item
                        key={version?.id}
                        className={`${version.isActive && "default"} ${version.id === activeVersion?.id && 'selected'}`}
                        onClick={() => versionSelectHandler(version)}>
                        <p>{version?.name}</p>
                        {version.createdAt && <span className="subtitle">{moment(version?.createdAt).format("MMM DD, YYYY hh:mm:ss a")}</span>}
                        {version.isActive && <i className="icon-quote-accepted" />}
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const openSetAsDefaultConfirmation = () => setDefalutVisible(true)

    const closeSetAsDefaultConfirmation = () => setDefalutVisible(false)


    return (
        <Row className="repair-list__table-header" justify="space-between" wrap={false}>
            <Col>
                <Row wrap={false} gutter={10}>
                    <Col>
                        <TableSearchFilterComponent
                            filterHandler={filtersApplyHandler}
                            placeholder="Search by repair id"
                            filters={filters}
                            name={name}
                            changeTriggerItem={activeVersion}
                            values={filterValues}
                        />
                    </Col>
                    <Col>
                        <Row gutter={10} wrap={false}>
                            <Col>
                                <ButtonComponent
                                    className="search-bar--trigger"
                                    iconClass="icon-export"
                                    text="Export"
                                    loading={exportLoading}
                                    onClick={() => exportHandler(activeVersion?.id)}
                                />
                            </Col>
                            <Col className="version-form__field">
                                <Dropdown
                                    className="version-drop-down"
                                    overlay={versionDropDownGenerator}
                                    trigger={['click']}>
                                    <ButtonComponent
                                        iconClass="icon-version"
                                        text={<span>
                                            {!versionList?.length ? 'Version' : activeVersion?.name || 'Add Version'}
                                            <i className="icon-dropdown"></i>
                                        </span>}
                                    />
                                </Dropdown>
                            </Col>
                            {activeVersion && !activeVersion?.isActive && <Col>
                                <ButtonComponent
                                    type="text"
                                    className="text-btn mini"
                                    text="Set as default"
                                    onClick={openSetAsDefaultConfirmation}
                                />
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col>
                <ButtonComponent
                    type="primary"
                    text="Bulk Upload"
                    onClick={bulkUploadClickHandler}
                />
            </Col>

            <ConfirmationModal
                visible={defalutVisible}
                icon={DefaultIcon}
                title={`Are you sure want to make  as default`}
                name={activeVersion?.name}
                proceedHandler={() => setVersionAsDefaulHandler(closeSetAsDefaultConfirmation)}
                closeHandler={closeSetAsDefaultConfirmation}
                buttonLoading={confirmationModalLoading}
            />
        </Row>

    )
}

export default RepairListTableActions