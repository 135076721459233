import React, { ReactNode, useState } from "react";
import { DatePicker } from "antd";
import { Field, ErrorMessage } from "formik";
import Error from "../Error";
import moment, { Moment } from 'moment'
import "./datePickerComponent.scss"

interface DatePickerProps {
    name: string;
    title?: string;
    setFieldValue?: Function;
    setFieldTouched?: Function;
    onChange: (value: Moment | null | any, dateString: string | string[]) => void;
    onSelect?: (value: any) => void;
    onBlur?: (value: any) => void;
    onKeyDown?: (value: any) => void;
    placeHolder?: string;
    rangePlaceHolder?: [string, string];
    value?: Moment | string;
    rangeValue?: [Moment, Moment];
    showArrow?: boolean;
    disabled?: boolean;
    range?: boolean;
    dropdownMatchSelectWidth?: boolean;
    defaultValue?: string | number;
    className?: string
    prefix?: ReactNode
    picker?: "time" | "date" | "week" | "month" | "quarter" | "year",
    disableDates?: "future" | "past" | undefined
}

const { RangePicker } = DatePicker;

function DatePickerComponent(props: DatePickerProps) {
    const {
        name,
        title,
        setFieldValue,
        picker = "date",
        placeHolder,
        onChange,
        value,
        onSelect,
        onBlur,
        disabled,
        defaultValue,
        onKeyDown,
        className,
        prefix,
        disableDates,
        range,
        rangePlaceHolder = ['', ''],
        rangeValue
    } = props;


    const disableDatesHandler = (current: Moment) => {
        switch (disableDates) {
            case "future":
                return current && current.valueOf() > Date.now();
            case "past":
                return current && current.valueOf() < Date.now();
            default:
                return false
        }
    }
    return (
        <Field name={name}>
            {({
                field,
                form: { touched, errors, setFieldTouched },
                meta,
            }: any) => {
                return (
                    <div className={`date-picker-field 
                    ${prefix && "date-picker-field-with-prefix"}
                    `}>
                        {title && (
                            <div className="date-picker-field__title">{title}</div>
                        )}
                        {prefix && <div className="prefix-element">
                            {prefix}
                        </div>}
                        {range
                            ? <RangePicker
                                suffixIcon={<i className="icon-calendar green-icon" />}
                                name={name}
                                picker={picker}
                                disabledDate={disableDatesHandler}
                                disabled={disabled}
                                placeholder={rangePlaceHolder}
                                onChange={onChange}
                                onBlur={() => setFieldTouched(name, true)}
                                value={rangeValue}
                            />
                            : <DatePicker
                                suffixIcon={<i className="icon-calendar green-icon" />}
                                name={name}
                                picker={picker}
                                disabledDate={disableDatesHandler}
                                disabled={disabled}
                                placeholder={placeHolder}
                                onChange={onChange}
                                onBlur={() => setFieldTouched(name, true)}
                                value={value ? moment(value) : null}
                            />
                        }
                        <ErrorMessage name={name}>
                            {(message: string) => (
                                // @ts-ignore
                                <Error className={`${name}__error`}
                                    message={message}
                                />
                            )}
                        </ErrorMessage>
                    </div>
                );
            }}
        </Field>
    );
}

export default DatePickerComponent;
