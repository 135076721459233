import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./ResetPasswordValidation"
import AuthService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import '../auth.scss';
import { useHistory, useLocation } from "react-router-dom";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { LOGIN } from "../../../routes/routeConstants/appRoutes";

const initialValue = {
    password: "",
    confirmPassword: ""
}

const ResetPasswordForm = () => {

    const { loading, resetPassword, fetchResetPasswordValidity } = AuthService();

    const location = useLocation();
    const search = location.search;
    const email = new URLSearchParams(search).get("email");
    const resetToken = new URLSearchParams(search).get("reset_password_token");

    useEffect(() => {
        fetchResetPasswordValidity({
            email,
            reset_password_token: resetToken
        })
    }, [])

    const onSubmit = (values: { password: string, confirmPassword: string }) => {
        const data = {
            email: email,
            new_password: values?.password,
            reset_password_token: resetToken
        }
        resetPassword(data)
    }

    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className="auth-form__header">
                        Reset Password
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">New password</div>
                        <InputField type="password"
                            name="password"
                            placeholder="Enter your new password"
                            prefix={<span className="icon-mail green-icon" />}
                        />
                    </div>
                    <div className="auth-form__field">
                        <div className="form-label">Confirm new password</div>
                        <InputField type="password"
                            name="confirmPassword"
                            placeholder="Enter your password again"
                            prefix={<span className="icon-mail green-icon" />}
                        />
                    </div>
                    <ButtonComponent htmlType="submit"
                        loading={loading}
                        className='auth-form__action'
                        type="primary"
                        text="Change password"
                    />
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(ResetPasswordForm);
