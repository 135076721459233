import { Dropdown, Button, Timeline, Col, Row } from "antd";
import menu from "antd/lib/menu";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import React, { useRef, useState } from "react";
import { ActivityStatusEnum } from "../../../../enums/activity.enum";
import { ActivityTimeline } from "../../../../models/activity.model";
import ButtonComponent from "../../ButtonComponent";
import DatePickerComponent from "../../DatePickerComponent";
import UIModal from "../../UIModal";
import "./containerActivityTimeline.scss"
interface ContainerActivityTimelineProps {
    timeline?: ActivityTimeline[]
    loading: boolean
    isRepairForm: boolean
}

const ContainerActivityTimeline = (props: ContainerActivityTimelineProps) => {
    const {
        timeline,
        loading,
        isRepairForm,
    } = props

    const timelineData = [
        {
            label: "Quote Date",
            iconClass: "icon-quote",
            date: timeline?.find(ele => ele?.historyStatus === ActivityStatusEnum.QUOTE_DRAFT)?.historyDate
        },
        // {
        //     label: "Quote Accepted",
        //     iconClass: "icon-quote-accepted",
        //     date: timeline?.find(ele => ele?.historyStatus === ActivityStatusEnum.QUOTE_ISSUED)?.historyDate
        // },
        {
            label: "Quote Approved",
            iconClass: "icon-quote-approved",
            date: timeline?.find(ele => ele?.historyStatus === ActivityStatusEnum.PENDING_CUSTOMER_APPROVAL)?.historyDate
        },
        {
            label: "Repair Date",
            iconClass: "icon-repair",
            date: timeline?.find(ele => ele?.historyStatus === ActivityStatusEnum.REPAIR_DRAFT)?.historyDate
        },
        {
            label: "Repair Approved",
            iconClass: "icon-repair-approved",
            date: timeline?.find(ele => ele?.historyStatus === ActivityStatusEnum.READY_FOR_BILLING)?.historyDate
        },
        {
            label: "Billed",
            iconClass: "icon-invoice",
            date: timeline?.find(ele => ele?.historyStatus === ActivityStatusEnum.BILLED)?.historyDate
        },
    ]

    const [timelines, setTimelines] = useState(isRepairForm ? timelineData.slice(2) : timelineData)

    const timelineTemplateGenerator = () => {
        return <Timeline>
            {timelines.map(({ label, iconClass, date }, i) => (
                <Timeline.Item
                    className={`${date ? 'active' : timelines[i - 1 < 0 ? 0 : i - 1]?.date ? 'pending' : ''}`}
                    dot={<i className={iconClass} />}>
                    <Row justify="space-between">
                        <Col className="status-name">
                            {label}
                        </Col>
                        <Col>{date}</Col>
                    </Row>
                </Timeline.Item>
            ))}
        </Timeline>
    }

    return (
        <div>
            <Dropdown overlay={() => timelineTemplateGenerator()}
                placement="bottomCenter"
                className="container-activity-timeline__container"
                overlayClassName="container-activity-timeline"
                trigger={["click"]}
            >
                <ButtonComponent
                    disabled={loading}
                    className="search-bar--trigger"
                    iconClass="icon-calendar"
                    text="View Timeline"
                    onClick={() => { }}
                />
            </Dropdown>
        </div>
    )
}

export default ContainerActivityTimeline