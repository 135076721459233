import { ReportModel } from './../../models/report.model';
import { ActivityItems } from '../../models/activity.model';
import { Dispatch, useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { AttachmentModel, MetaModel } from "../../models/meta.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { generatePath } from "react-router";

const ReportService = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const downloadReports = async (report: ReportModel) => {
        setLoading(true)
        try {
            const API_URL = ApiRoutes.REPORTS
            const payload = { ...serialize(ReportModel, report) }
            const response = await axiosInstance.post(API_URL, payload, {
                responseType: 'blob'
            })
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `Report-${report.containerNumber}-${report.fromDate}-${report.toDate}` + '.csv'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }


    return {
        loading,
        downloadReports,
    }
}

export default ReportService;