import { Row, Col } from "antd";
import { FormikValues } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import { ActivityEnum } from "../../../enums/activity.enum";
import { UserTypes } from "../../../enums/userTypes.enum";
import ContainerService from "../../../services/ContainerService/container.service";
import ContainerTableActions from "../../../shared/components/ContainerTableActions";
import TableComponent from "../../../shared/components/TableComponent";
import useFilters from "../../../shared/hooks/useFilters";
import { ContainerActivitiesInterface } from "../AllContainers";
import { containerFilters } from "../container.definitions";

const columns = [
    {
        title: "Container Number",
        dataIndex: "containerNumber",
        key: "containerNumber",
        width: 100,
    },
    {
        title: "Yard",
        dataIndex: "yardName",
        key: "yardName",
        width: 100,
        sorter: true,
        render: (text: string) => <span className="text-capitalize">{text}</span>,
    },
    {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        width: 100,
        sorter: true,
    },
    {
        title: "Owner Name",
        dataIndex: "containerOwnerName",
        key: "containerOwnerName",
        width: 100,
    },
    {
        title: "Activity",
        dataIndex: "activityType",
        key: "activityType",
        width: 100,
        sorter: true,
        render: (text: string) => <span className="text-capitalize">{text}</span>,
    },
    {
        title: "Activity ID",
        dataIndex: "activityUid",
        key: "activityUid",
        width: 100,
        sorter: true,
    },
    {
        title: "Activity Date",
        dataIndex: "activityDate",
        key: "activityDate",
        width: 100,
        sorter: true,
        render: (text: string) => <span>{text ? moment(text).format("DD MMM, YYYY") : ""}</span>,
    },
    {
        title: "Status",
        dataIndex: "activityStatus",
        key: "activityStatus",
        width: 100,
        sorter: true,
        render: (text: string) => <p className={`status ${text}`}>{text?.replaceAll('_', ' ')}</p>,
    },
]

const sortFields: any = {
    "customer": "customer_name",
    "yardName": "yard_name",
    "containerOwnerName": "owner_name",
    "activityType": "activity_type_sort",
    "activityUid": "activity_uid",
    "activityDate": "created_at",
    "activityStatus": "activity_status_sort",
}

const DraftContainers = (props: ContainerActivitiesInterface) => {


    const {
        rowClickHandler,
        formVisible,
        isAdmin,
    } = props

    const {
        activities,
        loading,
        activitiesMeta,
        fetchActivites,
    } = ContainerService()

    const {
        getParams,
        setParams,
    } = useFilters()

    useEffect(() => {
        if (!formVisible)
            fetchDraftActivities(getParams(containerFilters))
    }, [formVisible])


    const filtersApplyHandler = (values: FormikValues) => {
        setParams(values)
        fetchDraftActivities(values)
    }

    const fetchDraftActivities = (options?: FormikValues) => {
        fetchActivites({
            status: ActivityEnum.DRAFT,
            ...options
        }, isAdmin ? UserTypes.ADMIN : UserTypes.CUSTOMER)
    }
    const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any; }) => {
        const sortOrders = [undefined, 'ascend']
        const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field[0] : undefined
        const params: any = {}
        params[sortFields[field]] = sortOrders.indexOf(sorter.order)
        params.page = pagination.current
        const repairListFilters = getParams(containerFilters)
        const updatedFilters = { ...repairListFilters, page: params.page }
        setParams(updatedFilters)
        fetchDraftActivities({ ...params, ...updatedFilters })
    };


    return <Row gutter={[0, 20]}>
        <Col span={24}>
            <ContainerTableActions
                isAdmin={isAdmin}
                values={getParams(containerFilters)}
                filtersApplyHandler={filtersApplyHandler} />
        </Col>
        <Col span={24}>
            <TableComponent
                className="cursor-pointer"
                loading={loading}
                columns={columns}
                scroll={{ x: 800 }}
                data={activities}
                onChange={handleTableChange}
                pagination={{
                    pageSize: 20,
                    current: activitiesMeta.current || 1,
                    total: activitiesMeta.totalCount || 0,
                    showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                }}
                handleRowClick={rowClickHandler}
            />
        </Col>
    </Row>
}
export default DraftContainers