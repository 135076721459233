import { Col, Dropdown, Menu, Row } from 'antd';
import { FormikValues } from 'formik';
import React, { FC, useEffect, useState } from 'react'
import { MetaModel } from '../../models/meta.model';
import { Repair, RepairVerison } from '../../models/repair.model';
import { ApiRoutes } from '../../routes/routeConstants/apiRoutes';
import MetaService from '../../services/MetaService/meta.service';
import RepairService from '../../services/RepairService/repair.service';
import AppHeader from '../../shared/components/AppHeader';
import ButtonComponent from '../../shared/components/ButtonComponent';
import DeleteConfirmation from '../../shared/components/DeleteConfirmation';
import isAuthenticated from '../../shared/components/HOC/requireAuth';
import TableComponent from '../../shared/components/TableComponent';
import TableSearchFilterComponent from '../../shared/components/TableSearchFilterComponent';
import RepairDetails from './RepairDetails';
import RepairForm from './RepairForm';
import RepairImportForm from './RepairImportForm';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import DefaultIcon from "./../../assets/default\ version.svg"
import moment from 'moment';
import useFilters from '../../shared/hooks/useFilters';
import "./repairList.scss"
import AuthContainer from '../../store/container/AuthContainer';
import { AuthReducerProps } from '../../store/reducers/authReducer';
import { UserTypes } from '../../enums/userTypes.enum';
import CustomerService from '../../services/CustomerService/customer.service';
import { CustomerBillingTypes } from '../Customers/CustomerForm/customer.definition';

interface RepairListProps extends AuthReducerProps {

}

const sortFields: any = {
    "repairType": "repair_type",
    "repairUid": "uid",
    "containerRepairArea": "container_repair_area",
    "containerDamagedArea": "container_damaged_area"
}

const commonColumnKeys = ["nonMearskHours", "nonMearskMaterialCostDollars"]
const merskColumnKeys = ["mesrskHoursPerUnit", "mearskUnitMaterialCostDollar"]

const RepairList: FC<RepairListProps> = (props) => {
    const { user } = props;

    const {
        loading,
        repairList,
        repairListMeta,
        versionList: adminVersions,
        createNewVersion,
        fetchRepairVersions,
        fetchAdminRepairList,
        downloadRepairList,
        deleteRepairItem,
        submitting,
        setVersionAsDefault,
    } = RepairService()

    const {
        loading: customerLoading,
        customerRepairItemsMeta,
        fetchCustomerRepairListItems,
        fetchCustomerRepairList,
        customerVersions,
        customerRepairItems,
    } = CustomerService()

    const {
        fetchMeta,
    } = MetaService()

    const {
        getParams,
        setParams,
    } = useFilters()

    const [repairAreas, setRepairAreas] = useState<MetaModel[]>([])
    const [damagedAreas, setDamagedAreas] = useState<MetaModel[]>([])
    const [repairTypes, setRepairTypes] = useState<MetaModel[]>([])
    const [importVisible, setImportVisible] = useState<boolean>(false)
    const [formVisible, setFormVisible] = useState<boolean>(false)
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false)
    const [defalutVisible, setDefalutVisible] = useState<boolean>(false)
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false)
    const [selectedVersion, setSelectedVersion] = useState<RepairVerison>()
    const [selectedRepairItem, setSelectedRepairItem] = useState<Repair>()
    const [versionList, setVersionList] = useState<RepairVerison[]>([])
    const [selectedColumns, setSelectedColumns] = useState<any[]>([])

    useEffect(() => {
        fetchVersions()
        fetchMeta(ApiRoutes.REPAIR_AREAS, setRepairAreas, "container_repair_areas")
        fetchMeta(ApiRoutes.DAMAGED_AREAS, setDamagedAreas, "container_damaged_areas")
        fetchMeta(ApiRoutes.REPAIR_TYPES, setRepairTypes, "repair_types")
    }, [])

    useEffect(() => {
        if (user?.billingType)
            setSelectedColumns(columns.filter(column => {
                let restrictedColumnKeys = []

                if (user?.billingType === CustomerBillingTypes[0].value)
                    restrictedColumnKeys = commonColumnKeys
                else
                    restrictedColumnKeys = merskColumnKeys
                return !restrictedColumnKeys.includes(column.key)
            }))
        else
            setSelectedColumns(columns)
    }, [user])

    useEffect(() => {
        if (selectedVersion?.id)
            fetchRepairListItems(String(selectedVersion?.id))
    }, [selectedVersion])

    useEffect(() => {
        if (!user?.role) return
        getActiveVersion()
        if (user?.role?.toLowerCase() === UserTypes.ADMIN)
            setVersionList(adminVersions || [])
        else if (user?.id)
            setVersionList(customerVersions || [])
    }, [customerVersions, adminVersions])

    const fetchVersions = () => {
        if (!user?.role) return
        if (user?.role?.toLowerCase() === UserTypes.ADMIN)
            fetchRepairVersions()
        else if (user?.id)
            fetchCustomerRepairList(String(user?.id))
    }


    const availableFilters = [
        {
            title: "Damaged Area",
            name: 'container_damaged_area_id',
            options: damagedAreas
        },
        {
            title: "Repair Area",
            name: 'container_repair_area_id',
            options: repairAreas
        },
        {
            title: "Type",
            name: 'repair_type_id',
            options: repairTypes
        },
    ]
    

    const getRepairListFilterParams = () => {
        const filterNames = [
            'search_text',
            'page',
            ...availableFilters.map(filter => filter.name),
            ...Object.values(sortFields) as string[],
        ]
        return getParams(filterNames)
    }

    const fetchRepairListItems = (versionId: string, params?: any) => {
        if (!user?.role) return
        if (user?.role?.toLowerCase() === UserTypes.ADMIN) {
            // if (!adminVersions.length) { return }
            // const activeVersion = adminVersions.find(version => version.isActive)
            // setSelectedVersion(activeVersion)
            fetchAdminRepairList(versionId, params || getRepairListFilterParams())

        } else {
            // if (!customerVersions.length) { return }
            // const activeVersion = customerVersions.find(version => version.isActive)
            // setSelectedVersion(activeVersion)
            fetchCustomerRepairListItems(versionId, params || getRepairListFilterParams())
        }
    }

    const getActiveVersion = () => {
        if (!user?.role) return
        if (user?.role?.toLowerCase() === UserTypes.ADMIN) {
            if (!adminVersions.length) { return }
            const activeVersion = adminVersions.find(version => version.isActive)
            setSelectedVersion(activeVersion)
        } else {
            if (!customerVersions.length) { return }
            const activeVersion = customerVersions.find(version => version.isActive)
            setSelectedVersion(activeVersion)
        }
    }

    const createNewVersionHandler = () => {
        createNewVersion()
    }

    const versionChangeHandler = (version: RepairVerison) => {
        setSelectedVersion(version)
    }

    const bulkUploadClickHandler = () => {
        setImportVisible(true)
    }

    const editModeHandler = (record: any) => {
        setSelectedRepairItem(record)
        setFormVisible(true)
    }
    const deleteModeHandler = (record: any) => {
        setSelectedRepairItem(record)
        setDeleteVisible(true)
    }

    const deleteHandler = async () => {
        try {
            await deleteRepairItem(selectedRepairItem?.id || '')
            fetchRepairListItems(String(selectedVersion?.id), getRepairListFilterParams())
            setSelectedRepairItem(undefined)
            setDeleteVisible(false)
        } catch (error) {

        }
    }
    const deleteCloseHandler = () => {
        setDeleteVisible(false)
        setSelectedRepairItem(undefined)
    }

    const columns = [
        {
            title: 'Repair ID',
            dataIndex: 'repairUid',
            key: 'repairUid',
            width: 50,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Repair Area',
            dataIndex: ["containerRepairArea", 'name'],
            key: "containerRepairArea.name",
            width: user?.billingType ? 150 : 100,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Damaged Area',
            dataIndex: ["containerDamagedArea", 'name'],
            key: "containerDamagedArea.name",
            width: user?.billingType ? 150 : 100,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: ["repairType", 'name'],
            key: "repairType.name",
            width: user?.billingType ? 150 : 100,
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Common hours',
            dataIndex: 'nonMearskHours',
            key: 'nonMearskHours',
            width: 50,
        },
        {
            title: 'Common mat. cost',
            dataIndex: 'nonMearskMaterialCostDollars',
            key: 'nonMearskMaterialCostDollars',
            width: 50,
        },
        {
            title: 'Merc+ hours/unit',
            dataIndex: 'mesrskHoursPerUnit',
            key: 'mesrskHoursPerUnit',
            width: 50,
        },
        {
            title: 'Merc+ mat. cost/unit',
            dataIndex: 'mearskUnitMaterialCostDollar',
            key: 'mearskUnitMaterialCostDollar',
            width: 50,
        },
        {
            title: "Actions",
            dataIndex: 'actions',
            key: 'actions',
            width: 50,
            render: (text: string, record: any) => {
                return user?.role?.toLowerCase() !== UserTypes.CUSTOMER.toLowerCase()
                    ? <div className="table-actions">
                        <i className="icon-edit" onClick={(e) => {
                            e.stopPropagation();
                            editModeHandler(record)
                        }} />
                        <i className="icon-delete" onClick={(e) => {
                            e.stopPropagation()
                            deleteModeHandler(record)
                        }} />
                    </div>
                    : null
            }

        },
    ]

    const versionDropDownGenerator = () => {
        return (
            <Menu className="version-menu" mode="vertical">
                <Menu.Item className="new-version" key="0" onClick={createNewVersionHandler}>
                    + New Version
                </Menu.Item>
                {versionList?.map(version => (
                    <Menu.Item
                        className={`${version.isActive && "default"} ${selectedVersion?.id === version.id && "selected"}`}
                        key={version?.id}
                        onClick={() => versionChangeHandler(version)}
                    >
                        <p>{version?.name}</p>
                        {version.createdAt && <span> {moment(version.createdAt).format("MMM DD, YYYY hh:mm:ss a")}</span>}

                        {version.isActive && <i className="icon-quote-accepted" />}
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const exportHandler = () => {
        downloadRepairList(selectedVersion?.id || "")
    }

    const detailsModalHandler = (record: Repair) => {
        setSelectedRepairItem(record)
        setDetailsVisible(true)
    }

    const detailsModalCloseHandler = () => {
        setSelectedRepairItem(undefined)
        setDetailsVisible(false)
    }

    const filtersApplyHandler = (values: FormikValues) => {
        setParams(values)
        fetchRepairListItems(String(selectedVersion?.id), values)
    }

    const handleTableChange = (pagination: any, filters: any, sorter: { field: any; order: any; }) => {
        const sortOrders = [undefined, 'ascend']
        const field = typeof sorter.field === 'string' ? sorter.field : sorter.field ? sorter.field[0] : undefined
        const params: any = {}
        params[sortFields[field]] = sortOrders.indexOf(sorter.order)
        params.page = pagination.current
        const repairListFilters = getRepairListFilterParams()
        const updatedFilters = { ...repairListFilters, page: params.page }
        setParams(updatedFilters)
        fetchRepairListItems(String(selectedVersion?.id), { ...params, ...updatedFilters })
    };

    const setVersionAsDefaulHandler = async () => {
        const data = await setVersionAsDefault(selectedVersion?.id + '')
        if (!data) return
        fetchRepairVersions()
        setSelectedVersion(undefined)
        closeSetAsDefaultConfirmation()
    }

    const openSetAsDefaultConfirmation = () => setDefalutVisible(true)

    const closeSetAsDefaultConfirmation = () => setDefalutVisible(false)

    return (
        <div className="repair-list__container">
            <AppHeader
                title="Repair List"
                addButton={user?.role?.toLowerCase() === UserTypes.ADMIN.toLowerCase()}
                addButtonClickHandler={() => setFormVisible(true)}
            />
            <Row className="repair-list__table-header" justify="space-between" wrap={false}>
                <Col>
                    <Row wrap={false} gutter={10}>
                        <Col>
                            <TableSearchFilterComponent
                                name="repair-list-filters"
                                filterHandler={filtersApplyHandler}
                                placeholder="Search by repair id"
                                filters={availableFilters}
                                changeTriggerItem={selectedVersion}
                                values={getRepairListFilterParams()}
                            />
                        </Col>
                        <Col>
                            {user?.role?.toLowerCase() !== UserTypes.CUSTOMER.toLowerCase() && <Row gutter={10} wrap={false}>
                                <Col>
                                    <ButtonComponent
                                        className="search-bar--trigger"
                                        iconClass="icon-export"
                                        text="Export"
                                        loading={submitting}
                                        onClick={exportHandler}
                                    />
                                </Col>
                                <Col className="version-form__field">
                                    <Dropdown
                                        overlay={versionDropDownGenerator}
                                        trigger={['click']}>
                                        <ButtonComponent
                                            iconClass="icon-version"
                                            text={<span>
                                                {!versionList?.length ? 'Version' : selectedVersion?.name || 'Add Version'}
                                                <i className="icon-dropdown"></i>
                                            </span>}
                                        />
                                    </Dropdown>
                                </Col>
                                {selectedVersion && !selectedVersion?.isActive && <Col>
                                    <ButtonComponent
                                        type="text"
                                        className="text-btn mini"
                                        text="Set as default"
                                        onClick={openSetAsDefaultConfirmation}
                                    />
                                </Col>}
                            </Row>}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {user?.role?.toLowerCase() !== UserTypes.CUSTOMER.toLowerCase() && <ButtonComponent
                        type="primary"
                        text="Bulk Upload"
                        onClick={bulkUploadClickHandler}
                    />}
                </Col>
            </Row>
            <div className="repair-list__table">
                <TableComponent
                    className="cursor-pointer"
                    handleRowClick={detailsModalHandler}
                    loading={loading || customerLoading}
                    columns={selectedColumns}
                    data={user?.role?.toLowerCase() === UserTypes.ADMIN ? repairList : customerRepairItems}
                    onChange={handleTableChange}
                    pagination={{
                        pageSize: 20,
                        current: user?.role?.toLowerCase() === UserTypes.ADMIN ? repairListMeta.current : customerRepairItemsMeta.current || 1,
                        total: user?.role?.toLowerCase() === UserTypes.ADMIN ? repairListMeta.totalCount : customerRepairItemsMeta.totalCount || 0,
                        showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
                    }}
                />
            </div>
            <RepairImportForm
                visible={importVisible}
                versionId={String(selectedVersion?.id) || ""}
                closeHandler={(response?: true) => {
                    if (response)
                        fetchRepairListItems(String(selectedVersion?.id), getRepairListFilterParams())
                    setImportVisible(false)
                }} />
            <RepairForm
                visible={formVisible}
                data={selectedRepairItem}
                versionId={String(selectedVersion?.id) || ""}
                closeHandler={(response?: true) => {
                    if (response) fetchRepairListItems(String(selectedVersion?.id), getRepairListFilterParams())
                    setSelectedRepairItem(undefined)
                    setFormVisible(false)
                }} />
            <DeleteConfirmation
                visible={deleteVisible}
                title="Are you sure want to delete the repair"
                name={String(selectedRepairItem?.repairUid)}
                deleteHandler={deleteHandler}
                closeHandler={deleteCloseHandler}
                buttonLoading={submitting}
            />
            <ConfirmationModal
                visible={defalutVisible}
                icon={DefaultIcon}
                title={`Are you sure want to make  as default`}
                name={selectedVersion?.name}
                proceedHandler={setVersionAsDefaulHandler}
                closeHandler={closeSetAsDefaultConfirmation}
                buttonLoading={loading}
            />
            <RepairDetails
                billingType={user?.billingType}
                visible={detailsVisible}
                repair={selectedRepairItem || {}}
                closeHandler={detailsModalCloseHandler}
            />
        </div>
    )
}

export default AuthContainer(RepairList);