import { Modal } from "antd";
import React, { FC, ReactElement } from "react";
import "./uiModal.scss"

interface UIModalProps {
    visible: boolean
    title?: string
    subtitle?: string | ReactElement
    className?: string
    okText?: string
    cancelText?: string
    footer?: boolean
    loading?: boolean
    closable?: boolean
    onOk?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
    onCancel?: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined
}

const UIModal: FC<UIModalProps> = (props) => {
    const {
        visible,
        subtitle,
        children,
        className,
        footer,
        title,
        onOk,
        onCancel,
        okText,
        cancelText,
        loading,
        closable,
    } = props

    return <Modal
        className={`ui-modal ${className}`}
        title={title}
        visible={visible}
        footer={footer}
        onOk={onOk}
        closable={closable}
        onCancel={onCancel}
        okText={okText}
        cancelText={cancelText}
        destroyOnClose={true}
        okButtonProps={{
            loading
        }}
    >
        {subtitle && <div className="ui-modal--subtitle">{subtitle}</div>}
        {children}
    </Modal>
}

export default UIModal