import { Activity, ActivityItems } from './../../models/activity.model';
import { SET_ACTIVITY, SET_CONTAINER, SET_ACTIVITY_ITEMS } from './../definitions/containerConstants';
import { Container } from './../../models/container.model';
import { ActionProps } from "../../shared/types/action.type";

export const setContainer = (container: Container | undefined): ActionProps => ({
    type: SET_CONTAINER,
    payload: {
        container
    }
})

export const setActivity = (activity: Activity | undefined): ActionProps => ({
    type: SET_ACTIVITY,
    payload: {
        activity
    }
})

export const setActivityItems = (activityItems: ActivityItems[]): ActionProps => ({
    type: SET_ACTIVITY_ITEMS,
    payload: {
        activityItems
    }
})
