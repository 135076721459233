import { Row, Col } from 'antd'
import { FormikValues } from 'formik'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import ContainerService from '../../../../services/ContainerService/container.service'
import Loader from '../../../../shared/components/Loader'
import TableSearchFilterComponent from '../../../../shared/components/TableSearchFilterComponent'
import useFilters from '../../../../shared/hooks/useFilters'
import { convertToTitleCase } from '../../../../shared/utils/convertToTitleCase'
import "./containerLogs.scss"

interface ContainerLogsProps {
    containerId: string
}

const ContainerLogs: FC<ContainerLogsProps> = (props) => {

    const {
        containerId,
    } = props

    const {
        getParams,
        setParams,
    } = useFilters()

    const {
        loading,
        containerLogs,
        fetchContainerLogs,
    } = ContainerService()


    useEffect(() => {
        fetchContainerLogs(containerId)
    }, [])

    const getCustomerListFilterParams = () => {
        const filterNames = [
            'search_text',
        ]
        return getParams(filterNames)
    }

    const searchHandler = (values: FormikValues) => {
        setParams(values)
        // fetchCustomers({ ...values })
    }

    if (loading)
        return <Loader />

    return (
        <div className='container-logs'>
            <TableSearchFilterComponent
                placeholder={"Search container by number"}
                filterHandler={searchHandler}
                name="customer-list-filters"
                values={getCustomerListFilterParams()}
            />
            <Row className="container-logs__list">
                {containerLogs.map(log => <Col className="container-logs__list-item" span={24}>
                    <span className='date'>{moment(log.createdAt).format(`DD, MMM YYYY`)} at {moment(log.createdAt).format(`hh:mm A`)}</span>{" "}
                    {log.invoiceNumber && <span className='invoice'><b>{log.invoiceNumber}</b> for</span>}{" "}
                    <span className='type'>{convertToTitleCase(log.activityType || '')} Form - {log.activityUid}</span>{" "}
                    <span>{log.eventLogged?.replaceAll("_", " ")}</span>{" "}
                    by <span className='user'>{log.loggedBy?.role} - {log.loggedBy?.name}</span>
                </Col>)}
            </Row>
        </div>
    )
}

export default ContainerLogs
