import { ActivityItems } from './../../models/activity.model';
import { Container } from './../../models/container.model';
import { CreateReducer } from "../../shared/utils/createReducer";
import { User } from "../../models/user.model";
import { ActionProps } from "../../shared/types/action.type";
import { Activity } from '../../models/activity.model';
import { SET_ACTIVITY, SET_ACTIVITY_ITEMS, SET_CONTAINER } from '../definitions/containerConstants';

export interface ContainerState {
    container: Container,
    activity: Activity,
    activityItems: ActivityItems[],
}

export interface ContainerReducerProps extends ContainerState {
    setContainer: (container: Container) => ActionProps;
    setActivity: (activity: Activity) => ActionProps;
    setActivityItems: (activityItems: ActivityItems[]) => ActionProps;
}

const initState: ContainerState = {
    container: new Container(),
    activity: new Activity(),
    activityItems: [],
};

const authReducer = CreateReducer(initState, {
    [SET_CONTAINER](state: ContainerState, action: ActionProps): ContainerState {
        const { container } = action?.payload;
        return {
            ...state,
            container,
        };
    },
    [SET_ACTIVITY](state: ContainerState, action: ActionProps): ContainerState {
        const { activity } = action?.payload;
        return {
            ...state,
            activity,
        };
    },
    [SET_ACTIVITY_ITEMS](state: ContainerState, action: ActionProps): ContainerState {
        const { activityItems } = action?.payload;
        return {
            ...state,
            activityItems,
        };
    },
});

export default authReducer;



