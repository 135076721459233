
export const containerFilters = [
    'search_text',
    'page',
    'date',
    'activity_type',
    'activity_status',
    'yard_id',
    'customer_id',
]
