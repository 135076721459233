import React, { FC } from 'react';
import "./appHeader.scss";
import SearchComponent from "../SearchComponent";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Col, Row } from 'antd';

interface AppHeaderProps {
    goBackText?: string;
    iconClassName?: string;
    title: string | JSX.Element;
    isSearchEnabled?: boolean;
    addButton?: boolean;
    editButton?: boolean;
    addButtonClickHandler?: () => void;
    editButtonClickHandler?: () => void;
    handleSearch?: Function;
    className?: string
}

const AppHeader: FC<AppHeaderProps> = (props) => {
    const {
        iconClassName,
        title,
        className = '',
        isSearchEnabled,
        addButton = false,
        editButton = false,
        addButtonClickHandler = () => { },
        editButtonClickHandler = () => { },
        handleSearch,
        goBackText,
    } = props;

    const history = useHistory();

    return (
        <div className={`app-header ${className}`}>
            <Row className="app-header__left">
                {goBackText &&
                    <Col span={24} className="go-back" onClick={() => history.goBack()}>
                        <ArrowLeftOutlined /> {goBackText}
                    </Col>}
                <div className="app-header__title">{title}</div>
                {addButton && <div className="icon-add app-header__icon" onClick={addButtonClickHandler} />}
                {editButton && <div className="icon-edit app-header__icon" onClick={editButtonClickHandler} />}
            </Row>
            <div className="app-header__right">
                {isSearchEnabled && <div className="app-header__search">
                    <SearchComponent onSearch={handleSearch ? handleSearch : () => { }} />
                </div>}
            </div>
        </div>
    )
}

export default AppHeader;