import { UserTypes } from './../enums/userTypes.enum';
import { serializable, alias, primitive } from 'serializr';

export class Profile {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('password', primitive()))
    password?: string;

    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias('role', primitive()))
    role?: UserTypes;

    @serializable(alias('is_active', primitive()))
    status?: boolean = true;

    // @serializable(alias('admin', primitive()))
    // admin?: boolean = true;

    @serializable(alias('display_picture_url', primitive()))
    profilePic?: string;

    @serializable(alias('phone_number', primitive()))
    mobile?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string = "1";
}
