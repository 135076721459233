
export enum ContainerAttachmentEnum {
    LEFTSIDE = "left_side_photo",
    RIGHTSIDE = "right_side_photo",
    DOOR = "door_photo",
    FRONTSIDE = "front_side_photo",
    INTERIOR = "interior_photo",
    UNDERSIDE = "underside_photo",
    ROOF = "roof_photo",
    CSCPLATENUMBER = "csc_plate_number"
}

export enum ActivityAttachmentEnum {
    DAMAGE_AREA = "damaged_area_image",
    REPAIR_AREA = "repaired_area_image",
}
