import { User } from './../../models/user.model';
export type Name = "user" |
    "access-token" |
    "customer-list-filters" |
    "customer-repair-list-filters" |
    "invoice-list-filters" |
    "repair-list-filters" |
    "containers-list-filters";
type Values = string | boolean | number | User;

const sensitive: Name[] = ["user", "access-token"];
export default class LocalStorage {
    // static getItem(name: "access-token"): string;
    // static getItem(name: "user"): string;
    static getItem(name: Name) {
        const value = localStorage.getItem(name);
        if (value) {
            return JSON.parse(value);
        }
        return undefined
    }

    // static setItem(name: 'access-token', value: string): void;

    // static setItem(
    //     name: "user",
    //     value: string
    // ): void;

    static setItem(name: Name, value: Values) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static removeItem(name: Name) {
        localStorage.removeItem(name);
    }

    static clear() {
        localStorage.clear();
    }

    static clearSenstive() {
        sensitive.forEach((key) => {
            this.removeItem(key);
        });
    }
}
